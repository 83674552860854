<div #fileViewer class="fileViewer z-2 flex-column" [class.show]="this.fileViewerService.hasFile()" swipe
     (left)="this.previousFile(true)" (right)="this.nextFile(true)" (down)="this.closeFile(true)">
  <div class="background"></div>
  <div class="arrows"
       *ngIf="this.screenSizeService.isBigScreen() && this.chatService.open_message && this.chatService.open_message.files.length > 1">
    <div class="left" (click)="this.previousFile()">
      <i class="bi bi-arrow-left"></i>
    </div>
    <div class="right" (click)="this.nextFile()">
      <i class="bi bi-arrow-right"></i>
    </div>
  </div>
  <ng-container *ngIf="this.isVisible && this.fileViewerService.getFile() as file">
    <a *ngIf="this.chatService.open_message" download="{{file.name}}" href="{{ file.data! | checkXSS }}">
      <i class="bi bi-box-arrow-down"></i>
    </a>
    <i *ngIf="!this.fileViewerService.controls" class="bi bi-x" (click)="this.closeFile()"></i>
    <ng-container *ngIf="FileUtils.image_types.includes(file.type)">
      <div class="image">
        <img src="{{ file.data! | checkXSS }}" alt="Image" (touchstart)="this.onImageTouch($event)"
             (touchmove)="this.onImageMove($event)" (touchend)="this.onImageEnd()">
      </div>
    </ng-container>
    <ng-container *ngIf="FileUtils.video_types.includes(file.type)">
      <div class="video">
        <custom-media-element #mediaElement [src]="file.data! | checkXSS" [mimeType]="file.type" [duration]="file.duration ?? 0"></custom-media-element>
      </div>
    </ng-container>
    <ng-container *ngIf="FileUtils.audio_types.includes(file.type)">
      <div class="audio">
        <custom-media-element #mediaElement [src]="file.data! | checkXSS" [mimeType]="file.type" [duration]="file.duration ?? 0"></custom-media-element>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="this.fileViewerService.controls">
    <div class="controls">
      <i class="bi bi-check" (click)="this.fileViewerService.accepted.next(true)"></i>
      <i class="bi bi-x" (click)="this.fileViewerService.accepted.next(false)"></i>
    </div>
  </ng-container>
</div>
