<div *ngIf="this.isOpen" class="camera-container"
     (touchstart)="handleTouchStart($event)"
     (touchmove)="handleTouchMove($event)"
     (touchend)="handleTouchEnd($event)">
  <span *ngIf="this.recording" class="time">{{this.recordingTime}}</span>
  <i *ngIf="this.mode !== 'qr'" (click)="this.close()" class="bi bi-x"></i>
  <i *ngIf="this.flashlightSupported && !this.flashlightEnabled" (click)="this.toggleFlashlight()" class="bi bi-lightning"></i>
  <i *ngIf="this.flashlightSupported && this.flashlightEnabled" (click)="this.toggleFlashlight()" class="bi bi-lightning-fill"></i>
  <video #video [autoplay]="true" [muted]="true" playsinline></video>
  <!-- The canvas is used for capturing photos or processing QR codes; it stays hidden -->
  <canvas #canvas style="display: none;"></canvas>

  <!-- Zoom control slider -->
  <div class="zoom-control" *ngIf="this.zoomSupported">
    <input type="range"
           [min]="this.minZoom"
           [max]="this.maxZoom"
           [step]="0.1"
           [value]="this.currentZoom"
           (input)="applyZoom($event)">
  </div>

  <div class="controls">
    <i *ngIf="!this.recording" (click)="this.switchCamera()" class="bi bi-camera"></i>
    <i *ngIf="this.mode !== 'qr'" (click)="this.capture()" class="bi bi-circle" [class.recording]="this.recording"></i>
  </div>
</div>
