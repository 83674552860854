import * as JsStore from "jsstore";
import {IDataBase, IDbInfo} from "jsstore";
import {CustomIJoinQuery, CustomISelectQuery, CustomISelectTopQuery} from "./CustomISelectQuery";

declare var require: any;

class CustomConnection extends JsStore.Connection {
  override async select<T>(query: CustomISelectQuery): Promise<any[]> {
    return await super.select(query);
  }

  async selectTop<T>(query: CustomISelectTopQuery): Promise<any[]> {
    const results = await super.select({
      from: query.from,
      order: query.order,
      limit: query.top
    }) as any[];

    if (typeof query.where === "undefined") {
      return results;
    }

    return results.filter(entry => {
      for (const [key, value] of Object.entries(query.where!)) {
        if (typeof entry[key] === "undefined" || entry[key] !== value) {
          return false;
        }
      }

      return true;
    });
  }
}

const blocked_users = {
  name: "blocked_users",
  columns: {
    entry_id: {primaryKey: true, autoIncrement: false, dataType: "string", enableSearch: false},
    block_id: {notNull: true, dataType: "string", enableSearch: true},
  }
}

const members = {
  name: "members",
  columns: {
    entry_id: {primaryKey: true, autoIncrement: false, dataType: "number", enableSearch: true},
    user_id: {notNull: true, dataType: "string", enableSearch: true},
    chat_id: {notNull: true, dataType: "string", enableSearch: true},
    owner_id: {notNull: true, dataType: "string", enableSearch: true},
    timestamp: {dataType: "number", enableSearch: true},
  }
}

const users = {
  name: "users",
  columns: {
    user_id: {primaryKey: true, autoIncrement: false, dataType: "string", enableSearch: true},
    username: {notNull: true, dataType: "string", enableSearch: false},
    picture: {dataType: "string", enableSearch: false},
    public_key: {dataType: "string", enableSearch: false},
    sign_public_key: {notNull: true, dataType: "string", enableSearch: false},
    trusted: {notNull: true, dataType: "string", enableSearch: true, default: "false"},
  }
}

const chats = {
  name: "chats",
  columns: {
    chat_id: {primaryKey: true, autoIncrement: false, dataType: "string", enableSearch: true},
    creator_id: {dataType: "string", enableSearch: false},
    name: {dataType: "string", enableSearch: false},
    description: {dataType: "string", enableSearch: false},
    picture: {dataType: "string", enableSearch: false},
    settings: {dataType: "string", enableSearch: false},
    is_group: {notNull: true, dataType: "string", enableSearch: false},
    is_suspended: {notNull: true, dataType: "string", enableSearch: false},
    is_archived: {notNull: true, dataType: "string", enableSearch: false, default: "false"},
    is_qr_chat: {notNull: true, dataType: "string", enableSearch: false, default: "false"},
    max_previous: {notNull: true, dataType: "string", enableSearch: false, default: "false"}
  }
}

const messages = {
  name: "messages",
  columns: {
    message_id: {primaryKey: true, autoIncrement: false, dataType: "string", enableSearch: true},
    chat_id: {notNull: true, dataType: "string", enableSearch: true},
    sender_id: {notNull: true, dataType: "string", enableSearch: true, default: ""},
    data: {notNull: true, dataType: "string", enableSearch: false},
    files: {notNull: true, dataTypo: "string", enableSearch: false},
    signature: {dataType: "string", enableSearch: false},
    timestamp: {notNull: true, dataType: "number", enableSearch: true},
    open_timestamp: {dataType: "number", enableSearch: false},
    timer: {notNull: true, dataType: "number", enableSearch: true},
    status: {notNull: true, dataType: "number", enableSearch: true},
    seen: {notNull: true, dataType: "string", enableSearch: true, default: "false"} // Status for Message Badges
  }
}

const secret_keys = {
  name: "secret_keys",
  columns: {
    secret_id: {primaryKey: true, autoIncrement: true, dataType: "number", enableSearch: true},
    secret_key: {notNull: true, dataType: "string", enableSearch: false},
    chat_id: {notNull: true, dataType: "string", enableSearch: true},
    user_id: {notNull: true, dataType: "string", enableSearch: true},
  }
}

function convertBooleansToStrings(obj: any) {
  if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (typeof value === "boolean") {
        obj[key] = value.toString();
      }
      else if (typeof value === "object" && value !== null) {
        convertBooleansToStrings(value);
      }
    });
  }
  return obj;
}

function convertStringsToBooleans(obj: any) {
  if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];

      if (Array.isArray(value)) {
        return;
      }

      if (typeof value === "string" && (value === "true" || value === "false")) {
        obj[key] = value === "true";
      }
      else if (typeof value === "object" && value !== null) {
        convertStringsToBooleans(value);
      }
    });
  }
  return obj;
}

function removeUnselectedFields(obj: any, fields: string[]) {
  if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (fields.includes(key)) {
        delete obj[key];
      }
      else if (typeof value === "object" && value !== null) {
        removeUnselectedFields(value, fields);
      }
    });
  }
  return obj;
}

class AppDB {
  private dbInfo: IDbInfo = {
    name: "whisper",
    version: 29
  };

  connection = new CustomConnection(
    new Worker(require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js").default)
  );

  constructor() {
    // turn on jsstore log status - help you to debug
    // turn off it in production or when you don't need
    this.connection.logStatus = false;
  }

  async init() {
    this.initFunctions();
    await this.initJsStore();
  }

  private initFunctions() {
    const originalUpdate = this.connection.update.bind(this.connection);
    this.connection.update = (query) => {
      query.set = convertBooleansToStrings(query.set);
      query.where = convertBooleansToStrings(query.where);

      return originalUpdate(query);
    };

    const originalInsert = this.connection.insert.bind(this.connection);
    this.connection.insert = (query) => {
      query.values = convertBooleansToStrings(structuredClone(query.values));

      this.getDatabase().tables.forEach(table => {
        if (table.name === query.into) {
          query.values = query.values.map(value => this.filterDataForTable(value, table.columns));
        }
      });

      return originalInsert(query);
    };

    const originalSelect = this.connection.select.bind(this.connection);
    this.connection.select = async (query) => {
      query.where = convertBooleansToStrings(query.where);

      if (query.join) {
        query.join = Array.isArray(query.join) ? query.join : [query.join];
        for (const join of query.join) {
          if (join.joinUnder === undefined) continue;

          const joinTableName = join.with;
          const joinTable = this.getDatabase().tables.find(table => table.name === joinTableName)!;

          join.as = join.as || {};
          for (const column in joinTable.columns) {
            join.as[column] = joinTableName + "." + column;
          }
        }
      }

      let result = await originalSelect(query) as any;

      if (query.fields) {
        result = removeUnselectedFields(result, query.fields);
      }

      result = convertStringsToBooleans(result);

      if (query.join) {
          for (const join of query.join! as CustomIJoinQuery[]) {
            if (join.joinUnder === undefined) continue;

            result = result.map((item: any) => {
              const nestedData: Record<string, any> = {};

              for (const key in item) {
                const joinPrefix = join.with + ".";
                if (!key.startsWith(joinPrefix)) continue;

                if (item[key] !== undefined) {
                  nestedData[key.replace(joinPrefix, "")] = item[key];
                }
                delete item[key];
              }

              item[join.joinUnder!] = nestedData;

              return item;
          });
        }
      }
      return result;
    };
  }

  private async initJsStore() {
    let last_database = localStorage.getItem("database") as any;
    const database = this.getDatabase();

    if (last_database) {
      last_database = JSON.parse(last_database);

      if (last_database.version !== database.version) {
        setTimeout(async () => {
          await this.connection.openDb(last_database.name, last_database.version);

          const messages = await this.connection.select({
            from: "messages"
          }).catch(() => []);

          const members = await this.connection.select({
            from: "members"
          }).catch(() => []);

          const chats = await this.connection.select({
            from: "chats"
          }).catch(() => []);

          const users = await this.connection.select({
            from: "users"
          }).catch(() => []);

          const blocked_users = await this.connection.select({
            from: "blocked_users"
          }).catch(() => []);

          const secret_keys = await this.connection.select({
            from: "secret_keys"
          }).catch(() => []);

          await this.connection.dropDb();

          await this.connection.initDb(database).catch(() => {
            this.connection.openDb(database.name, database.version);
          });

          setTimeout(async () => {
            await this.connection.insert({
              into: "chats",
              values: chats
            }).catch(e => {});

            await this.connection.insert({
              into: "users",
              values: users
            }).catch(e => {});

            await this.connection.insert({
              into: "members",
              values: members
            }).catch(e => {});

            await this.connection.insert({
              into: "secret_keys",
              values: secret_keys
            }).catch(e => {});

            await this.connection.insert({
              into: "messages",
              values: messages
            }).catch(e => {});

            await this.connection.insert({
              into: "blocked_users",
              values: blocked_users
            }).catch(e => {});
          }, 1000);
        }, 2000);
      } else {
        await this.connection.initDb(database).catch(() => {
          this.connection.openDb(database.name, database.version);
        });
      }
    } else {
      await this.connection.initDb(database).catch(() => {
        this.connection.openDb(database.name, database.version);
      });
    }

    localStorage.setItem("database", JSON.stringify(database));
  }

  private getDatabase() {
    const dataBase: IDataBase = {
      name: this.dbInfo.name + "_" + this.dbInfo.version,
      tables: [blocked_users, members, users, chats, messages, secret_keys],
      version: this.dbInfo.version
    };
    return dataBase;
  }

  private filterDataForTable(data: Record<string, any>, columns: Record<string, any>): Record<string, any> {
    let filteredData: Record<string, any> = {};
    for (const key in columns) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (typeof value === "object") {
          filteredData[key] = JSON.stringify(value);
        } else {
          filteredData[key] = data[key];
        }
      }
    }
    return filteredData;
  }
}

export const db = new AppDB();
