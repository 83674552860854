import {NgModule} from "@angular/core";

import {BrowserModule} from "@angular/platform-browser";
import {ReactiveFormsModule} from "@angular/forms";
import {QRCodeModule} from "angularx-qrcode";

import {AppRoutingModule} from "./app-routing.module";

import {AppComponent} from "./app.component";
import {HomeComponent} from "./home/home.component";
import {LoginComponent} from "./auth/login/login.component";
import {RegisterComponent} from "./auth/register/register.component";
import {DecryptComponent} from "./auth/decrypt/decrypt.component";
import {AccountComponent} from "./auth/account/account.component";
import {ServiceWorkerModule} from "@angular/service-worker";
import {AuthComponent} from "./auth/auth.component";
import {ChatComponent} from "./chat/chat.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {StatusIconPipe} from "../assets/js/pipe/status_icon";
import {NgOptimizedImage} from "@angular/common";
import {ContextmenuComponent} from "./template/contextmenu/contextmenu.component";
import {MessageComponent} from "./template/message/message.component";
import {FormatTimePipe} from "../assets/js/pipe/format_time";
import {PersonalizeComponent} from "./personalize/personalize.component";
import {SwipeDirective} from "../assets/js/swipe";
import {FileViewerComponent} from "./template/file-viewer/file-viewer.component";
import {ProfilePictureComponent} from "./template/profile-picture/profile-picture.component";
import {SearchComponent} from "./search/search.component";
import {XSSCheckPipe} from "../assets/js/pipe/xss_check";
import {BypassSecurityPipe} from "../assets/js/pipe/bypass_security";
import {provideHttpClient} from "@angular/common/http";
import {InfoComponent} from "./info/info.component";
import {ArchiveComponent} from "./archive/archive.component";
import {CameraComponent} from "./template/camera/camera.component";
import {CustomMediaElementComponent} from "./template/custom-media-element/custom-media-element.component";
import {RootComponent} from "./root/root.component";

@NgModule({
  declarations: [
    RootComponent,
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    DecryptComponent,
    AccountComponent,
    AuthComponent,
    ChatComponent,
    StatusIconPipe,
    FormatTimePipe,
    XSSCheckPipe,
    BypassSecurityPipe,
    ContextmenuComponent,
    FileViewerComponent,
    ProfilePictureComponent,
    MessageComponent,
    CameraComponent,
    PersonalizeComponent,
    SwipeDirective,
    SearchComponent,
    InfoComponent,
    ArchiveComponent,
    CustomMediaElementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register("custom-sw.js", {
      enabled: true,
      registrationStrategy: "registerImmediately",
    }),
    NgOptimizedImage,
    QRCodeModule
  ],
  providers: [provideHttpClient()],
  exports: [
    ProfilePictureComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
