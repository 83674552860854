<div #sidebar class="search-sidebar" [class.show]="this.open_state">
  <i class="bi bi-x close" (click)="this.close()"></i>
  <i class="bi select" [class.bi-square]="!this.selecting" [class.bi-check-square]="this.selecting" (click)="this.selecting = !this.selecting"></i>

  <div class="py-5 px-4 mt-3 content w-100 m-auto">
    <form class="d-flex flex-column w-100" (ngSubmit)="this.searchAction()" [formGroup]="searchFormGroup">
      <h3>Benutzer suchen</h3>
      <div class="mt-1 d-flex">
        <input #searchInput (input)="this.filterUsers()" tabindex="1" autocomplete="username" class="form-control" formControlName="username"
               id="username" placeholder="Kevin" type="text"/>
        <i class="bi bi-search ms-3 p-2" (click)="this.searchAction()"></i>
      </div>
    </form>
    <span *ngIf="Tools.checkFormControl(searchFormGroup.controls.username, 'minlength')" class="error d-block"> muss mindestens {{ searchFormGroup.controls.username.getError('minlength')['requiredLength'] }}
      Zeichen lang sein</span>
    <span *ngIf="Tools.checkFormControl(searchFormGroup.controls.username, 'maxlength')" class="error d-block"> darf maximal {{ searchFormGroup.controls.username.getError('maxlength')['requiredLength'] }}
      Zeichen lang sein</span>

    <span class="mt-3 d-block">Sie können mehrere Chats mit dem gleichen Benutzer erstellen und diese für verschiedene Themen, Zwecke etc. verwenden.</span>
    <div class="mt-4" *ngIf="this.users.length">
      <h3>Bekannte Benutzer</h3>
      <div class="users">
        <ng-container *ngIf="!this.selecting">
          @for (user of this.users; track user; let index = $index) {
            <div (click)="this.createChat([user])" class="list-group-item user-select-none d-flex justify-content-start align-items-center rounded-0 border-start-0 border-end-0 py-3">
              <div class="w-65-px ratio ratio-1x1 rounded-circle overflow-hidden">
                <app-profile-picture [user]="user"></app-profile-picture>
              </div>
              <div class="ms-4 me-4">
                <div class="username fs-7">
                  {{ user.username }}
                </div>
              </div>
            </div>
          }
        </ng-container>
        <ng-container *ngIf="this.selecting">
          @for (user of this.users; track user; let index = $index) {
            <div (click)="this.toggleUser(user)" class="position-relative list-group-item user-select-none d-flex justify-content-start align-items-center rounded-0 border-start-0 border-end-0 py-3">
              <i class="bi select" [class.bi-square]="!this.users_selected.includes(user)" [class.bi-check-square]="this.users_selected.includes(user)"></i>
              <div class="w-65-px ratio ratio-1x1 rounded-circle overflow-hidden">
                <app-profile-picture [user]="user"></app-profile-picture>
              </div>
              <div class="ms-4 me-4">
                <div class="username fs-7">
                  {{ user.username }}
                </div>
              </div>
            </div>
          }
          <div class="group-info">
            <input #groupName tabindex="1" autocomplete="groupname" class="form-control"
                   id="groupname" placeholder="Gruppenname" type="text"/>
            <button class="btn btn-primary w-100 mt-2" type="submit" (click)="this.createChat(this.users_selected)">Erstellen</button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
