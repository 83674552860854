import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from "./home/home.component";

import {LoginComponent} from "./auth/login/login.component";
import {RegisterComponent} from "./auth/register/register.component";
import {DecryptComponent} from "./auth/decrypt/decrypt.component";
import {AccountComponent} from "./auth/account/account.component";

import {AppGuard, DecryptGuard, LoginGuard} from "../assets/js/guards";
import {AuthComponent} from "./auth/auth.component";
import {RootComponent} from "./root/root.component";

const routes: Routes = [
  {component: RootComponent, path: "", canActivate: [AppGuard, DecryptGuard]},
  {component: HomeComponent, path: "home", canActivate: [LoginGuard, DecryptGuard]},
  {
    component: AuthComponent, path: "auth", children: [
      {component: LoginComponent, path: "login", canActivate: [AppGuard, DecryptGuard]},
      {component: RegisterComponent, path: "register", canActivate: [AppGuard, DecryptGuard]},
      {component: DecryptComponent, path: "decrypt", canActivate: [LoginGuard, AppGuard]},
      {component: AccountComponent, path: "account", canActivate: [LoginGuard, DecryptGuard]}
    ]
  },
  {path: "**", redirectTo: "home"},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
