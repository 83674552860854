<div class="media-player">
  <!-- The native media element: choose video or audio based on MIME type -->
  <ng-container *ngIf="this.isVideo; else audioTemplate">
    <video #mediaElement [src]="this.src" class="video-content" (loadeddata)="this.chatService.current_scroll()" playsinline></video>
  </ng-container>
  <ng-template #audioTemplate>
    <audio #mediaElement [src]="this.src" style="display: none;" (loadeddata)="this.chatService.current_scroll()"></audio>
  </ng-template>

  <!-- Custom controls -->
  <div class="controls">
    <button class="play-pause" (click)="this.playPause()">
      {{ this.playing ? "❚❚" : "▶" }}
    </button>
    <div class="progress-container" (click)="this.seek($event)">
      <div class="progress" [style.width.%]="(this.currentTime / this.totalTime) * 100"></div>
    </div>
    <span class="time">
      {{ this.formatTime(this.currentTime) }} / {{ this.formatTime(this.totalTime) }}
    </span>
    <input
      type="range"
      class="volume-slider"
      min="0"
      max="1"
      step="0.01"
      [value]="this.volume"
      (input)="this.onVolumeChange($event)"
    />
    <!-- Full screen button appears only for video elements -->
    <button *ngIf="this.isVideo" class="fullscreen-button" (click)="this.toggleFullScreen()">
      ⛶
    </button>
  </div>
</div>
