<nav class="navbar navbar-expand-lg z-1 w-100 top-0">
  <div class="container-fluid">
    <a tabindex="-1" class="navbar-brand" routerLink="">{{Tools.pageName}}</a>
    <button #toggler aria-controls="navbarBody" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler shadow-none" data-bs-target="#navbarBody" data-bs-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarBody" (click)="onNavClick($event)">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!--<li class="nav-item" *ngIf="admin">
          <a class="nav-link" routerLink="admin">Admin Center</a>
        </li>-->
      </ul>
      <ul class="navbar-nav ml-auto">
        <li *ngIf="authService.isLoggedIn else loggedOut" class="nav-item dropdown">
          <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">
            {{authService.getUsername()}}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a (click)="this.authService.logout()" class="dropdown-item">Abmelden</a></li>
          </ul>
        </li>
        <ng-template #loggedOut>
          <li class="nav-item">
            <a tabindex="0" class="nav-link" routerLink="/auth/login">Anmelden</a>
          </li>
          <li class="nav-item">
            <a tabindex="0" class="nav-link" routerLink="/auth/register">Registrieren</a>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</nav>

<div class="container text-center ps-5 pe-5 pt-4 pb-4 overflow-auto">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <h1 class="display-4 fw-bold text-nowrap">Willkommen bei <span class="text-primary">tunnl</span></h1>
      <p class="lead">tunnl ist eine einfache und wunderschöne App, um all deine Gedanken mit der Welt zu teilen.</p>
    </div>
  </div>

  <div class="row justify-content-center my-4">
    <div class="col-12 col-md-4 mb-3 mb-md-0">
      <img src="assets/img/chat.png" alt="Teilen Sie Ihre Gedanken" class="img-fluid rounded shadow">
    </div>
    <div class="col-12 col-md-4 mb-3 mb-md-0">
      <img src="assets/img/group.png" alt="Gemeinschaft erleben" class="img-fluid rounded shadow">
    </div>
    <div class="col-12 col-md-4">
      <img src="assets/img/group-settings.png" alt="Einstellungen anpassen" class="img-fluid rounded shadow">
    </div>
  </div>

  <div class="row justify-content-center my-4">
    <div class="col-md-8">
      <p class="fs-5">Egal ob spontane Ideen, tiefgründige Gedanken oder kreative Inspirationen – mit tunnl kannst du sie einfach und stilvoll veröffentlichen.</p>
      <p class="fs-5">Werde jetzt Teil unserer Community und starte deine Reise.</p>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-4">
      <a routerLink="/auth/register" class="btn btn-primary btn-lg w-100">Jetzt registrieren</a>
    </div>
    <div class="col-md-4">
      <a routerLink="/auth/login" class="btn btn-secondary btn-lg w-100 mt-4-md">Oder anmelden</a>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
