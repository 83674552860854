import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Swal from "sweetalert2";
import {AuthService} from "../../assets/js/service/auth";
import {ChatService} from "../../assets/js/service/chat";
import {ScreenSizeService} from "../../assets/js/service/screen";
import {CryptUtils} from "../../assets/js/crypt_utils";

import {User} from "../../assets/js/model/User";
import {Member} from "../../assets/js/model/Member";

import {db} from "../../db/db";
import {MenuItem} from "../../assets/js/interface/menu_item";
import {ChatComponent} from "../chat/chat.component";
import {Subscription} from 'rxjs';
import {Message, MessageStatus} from "../../assets/js/model/Message";
import {HomeService} from "../../assets/js/service/home";
import {ContextmenuService} from "../../assets/js/service/contextmenu";
import {NetworkService} from "../../assets/js/service/network";
import {Tools} from "../../assets/js/tools";
import {FileInfo} from "../../assets/js/model/FileInfo";
import {ChatSettings} from "../../assets/js/model/ChatSettings";
import {DBHandlerService} from "../../assets/js/service/db_handler";
import {VisibilityChangeService} from "../../assets/js/service/visibility";
import {Chat} from "../../assets/js/model/Chat";
import {ComponentService} from "../../assets/js/service/component";
import {CameraComponent} from "../template/camera/camera.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  private broadcast = new BroadcastChannel("service-worker");

  private secretInterval: any;
  protected base64JSON: string = "";

  private subscriptions: Subscription[] = [];


  @ViewChild(ChatComponent)
  chatComponent!: ChatComponent;

  @ViewChild("camera")
  private camera!: CameraComponent;

  @ViewChild("chatList")
  private chatList!: ElementRef;

  contextMenuItems: MenuItem[] = [
    {
      label: "Vertrauen", action: this.trustUser.bind(this), condition: (user: User, chat: Chat) => {
        return !chat.creator_id && !user.trusted
      }
    },
    {
      label: "Nicht vertrauen", action: this.untrustUser.bind(this), condition: (user: User, chat: Chat) => {
        return !chat.creator_id && typeof user.trusted !== "undefined" && user.trusted
      }
    },
    {label: "Umbenennen", action: this.renameChat.bind(this)},
    {label: "Archivieren", action: (chat_id) => this.chatService.archiveChat(chat_id)},
    {
      label: "Löschen", action: (chat_id) => this.chatService.deleteChat(chat_id), condition: (user: User, chat: Chat) => {
        return !chat.creator_id || chat.creator_id === this.authService.getUserId()
      }
    },
    {
      label: "Verlassen", action: (chat_id) => this.chatService.leaveChat(chat_id), condition: (user: User, chat: Chat) => {
        return !!chat.creator_id && chat.creator_id !== this.authService.getUserId()
      }
    }
  ];

  protected loading: boolean = false;

  constructor(private componentService: ComponentService, private visibilityService: VisibilityChangeService, private cdr: ChangeDetectorRef, private dbHandlerService: DBHandlerService, private networkService: NetworkService, protected authService: AuthService, protected homeService: HomeService, protected chatService: ChatService, protected screenService: ScreenSizeService, private contextmenuService: ContextmenuService) {
    this.componentService.addComponent(this);
  }

  async untrustUser(chat_id: string) {
    const member = await this.homeService.getMember(chat_id);
    const members = await this.homeService.getMembers(member.user_id!);

    for (const member of members) {
      member.user!.trusted = false;
    }

    await db.connection.update({
      in: "users",
      set: {
        trusted: false
      },
      where: {
        user_id: member.user_id!
      }
    }).catch(e => {
    });
  }

  async trustUser(chat_id: string) {
    const member = await this.homeService.getMember(chat_id);
    const members = await this.homeService.getMembers(member.user_id!);

    for (const member of members) {
      member.user!.trusted = true;
    }

    await db.connection.update({
      in: "users",
      set: {
        trusted: true
      },
      where: {
        user_id: member.user_id!
      }
    }).catch(e => {
    });

    const image = this.authService.getImage();

    if (!image.size) {
      return;
    }

    const picture = await this.homeService.updatePicture(image, chat_id);
    await this.networkService.request("PUT", "/pictures", JSON.stringify({images: [picture]}));
  }

  renameChat(chat_id: string) {
    Swal.fire({
      title: "Geben Sie den neuen Namen ein",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Ändern",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async (name) => {
        db.connection.update({
          in: "chats",
          set: {
            name: name,
          },
          where: {
            chat_id: chat_id!,
          }
        }).then(() => {
          const index = this.homeService.members.findIndex(member => member.chat?.chat_id === chat_id);
          this.homeService.members[index].chat!.name = name;
        }).catch(() => {
          Swal.fire({
            title: "Fehler",
            text: "Beim Ändern des Namens ist ein Fehler aufgetreten",
            icon: "error"
          });
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  private async generateSecretKey() {
    const secret_key = await CryptUtils.generateSecretKey();

    const data = {
      secret_key: await CryptUtils.exportSecretKey(secret_key),
      user_secret_key: {
        key: await CryptUtils.encryptSecretKeySymmetric(secret_key, this.authService.getSecretKey() as CryptoKey),
        type: "aes",
        user_id: this.authService.getUserId()
      }
    };

    this.base64JSON = JSON.stringify(data);
  }

  secretKeyGenerator() {
    this.generateSecretKey();

    this.secretInterval = setInterval(() => {
      this.generateSecretKey();
    }, 15000);
  }

  protected closeQR(): void {
    clearInterval(this.secretInterval);

    this.camera.close();
    this.base64JSON = "";
  }

  async handleClick(chatId: string, index: number) {
    if (this.contextmenuService.element?.visible) {
      return;
    }

    const member = this.homeService.sorted_members[index];
    member.unseen_messages = 0;

    if ("serviceWorker" in navigator) {
      this.broadcast.postMessage({
        command: "resetBadge"
      });
    }

    this.chatComponent.loadChat(chatId);
  }

  async loadPictures(pictures: any[] = []): Promise<void> {
    if (!pictures.length) {
      return;
    }

    const encryptedPictures = [];
    for (const pictureInfo of pictures) {
      let encrypted = pictureInfo.picture;
      let decrypted = null;

      const member = await this.homeService.getMember(pictureInfo.chat_id);
      if (pictureInfo.type === "RSA") {
        const secret_key = await this.chatService.getSecretKey(pictureInfo.chat_id);

        decrypted = await CryptUtils.decryptData(encrypted, secret_key);
        encrypted = await CryptUtils.encryptData(decrypted, this.authService.getSecretKey()!);

        encryptedPictures.push({
          chat_id: pictureInfo.chat_id,
          user_id: member.user_id,
          for_id: this.authService.getUserId(),
          type: "AES",
          picture: encrypted
        });
      } else {
        decrypted = await CryptUtils.decryptData(encrypted, this.authService.getSecretKey()!);
      }

      const members = await this.homeService.getMembers(member.user_id!);
      for (const member of members) {
        member.user!.picture = JSON.parse(decrypted) as FileInfo;
      }

      this.dbHandlerService.addAction(async () => {
        await db.connection.update({
          in: "users",
          set: {
            picture: encrypted
          },
          where: {
            user_id: member.user_id!
          }
        }).catch(e => {
        });
      });
    }

    if (encryptedPictures.length) {
      await this.networkService.request("PUT", "/pictures", JSON.stringify({images: encryptedPictures}));
    }

    localStorage.setItem("pictureTimestamp", Tools.current_time.toString());
  }

  async update() {
    await this.loadMessageBadges();

    this.chatList.nativeElement.scrollTop = 0;
    this.homeService.lastScrollPosition = 0;
  }

  async refresh() {
    if (Tools.updating) {
      return;
    }

    Tools.updating = true

    await this.loadChats();
    await this.loadMessageBadges();

    try {
      const unsent_messages = await db.connection.selectTop({
        from: "messages",
        where: {
          sender_id: this.authService.getUserId(),
          status: MessageStatus.ERROR
        },
        top: 30
      }) as Message[];

      const messages = await db.connection.select({
        from: "messages",
        where: {
          sender_id: {
            "!=": this.authService.getUserId()
          }
        },
        order: {
          by: "timestamp",
          type: "desc"
        },
        limit: 1
      });

      const message_timestamp = (messages.length) ? messages[0].timestamp : 0;
      const picture_timestamp = Number(localStorage.getItem("pictureTimestamp")) ?? 0;

      const response = await this.networkService.request("POST", "/batch/chats", JSON.stringify({
        messages: unsent_messages,
        message_timestamp: message_timestamp,
        picture_timestamp: picture_timestamp
      }));

      if (response.data) {
        for (const member of response.data.members as Member[]) {
          await this.processMember(member);
        }

        const messages = response.data.messages as Message[];

        await this.chatService.handleMessages(messages);
        this.chatService.addMessages(messages);

        this.cdr.detectChanges();

        await this.chatService.sentMessages(unsent_messages);

        await this.loadPictures(response.data.pictures);
      }
    } catch (e) {
      console.log(e);
    }

    Tools.updating = false;
  }

  private async processMember(member: Member): Promise<void> {
    const index = this.homeService.members.findIndex(m => m.chat!.chat_id === member.chat!.chat_id);
    if (index === -1) {
      await this.homeService.newMember(member);
    } else {
      this.homeService.members[index].chat!.settings = member.chat!.settings;

      this.chatService.setSettings(member.chat!.settings, member.chat_id);

      if (!member.chat!.is_group && this.homeService.members[index].user!.sign_public_key !== member.user!.sign_public_key) {
        const message = {
          message_id: Tools.generateUUID(),
          chat_id: member.chat!.chat_id,
          sender_id: "",
          data: "Der Sicherheitsschlüssel von " + member.user!.username + " hat sich geändert",
          files: "",
          timestamp: Tools.current_time,
          timer: Number(member.chat!.settings.timer),
          status: MessageStatus.RECEIVED,
        } as Message;

        this.dbHandlerService.addAction(async () => {
          await db.connection.insert({
            into: "messages",
            values: [message],
          }).catch(e => {
          });
        });

        this.homeService.members[index].user!.sign_public_key = member.user!.sign_public_key;
      }
    }
  }

  ngOnInit() {
    if (!this.authService.isDecrypted) {
      return;
    }

    this.refresh();

    if ("serviceWorker" in navigator) {
      this.broadcast.postMessage({
        command: "resetBadge"
      });
    }

    this.visibilityService.addHandler("home", () => {
      if (Tools.isVisible()) {
        this.refresh();
      }
    }, true);
  }

  ngAfterViewInit() {
    this.chatList.nativeElement.addEventListener("scroll", this.scrollChangeHandler);

    this.subscriptions.push(
      this.camera.qrCodeEvent.subscribe(async data => {
        if (data) {
          this.closeQR();

          const qrData = JSON.parse(data);
          const secret_key = await CryptUtils.importSecretKey(qrData.secret_key);

          const creator_secret_key = {
            user_id: this.authService.getUserId(),
            key: await CryptUtils.encryptSecretKeySymmetric(secret_key, this.authService.getSecretKey() as CryptoKey),
            type: "aes"
          };

          const response = await this.chatService.createChat([qrData.user_secret_key], creator_secret_key);
          if (response.data) {
            await this.chatComponent.loadChat(response.data.chat!.chat_id);
            this.camera.qrCodeEvent.next(null);
          }
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private scrollChangeHandler = () => {
    if (!this.loading) {
      this.homeService.lastScrollPosition = this.chatList.nativeElement.scrollTop;
    }
  }

  async loadMessageBadges() {
    const messages = await db.connection.select({
      from: "messages",
      where: {
        seen: false,
      },
      order: {
        by: "timestamp",
        type: "desc"
      }
    }).catch(() => []) as Message[];

    for (const member of this.homeService.members) {
      member.unseen_messages = messages.filter(message => message.chat_id === member.chat_id).length;
    }

    this.cdr.detectChanges();
  }

  private async loadChats() {
    if (this.homeService.members.length) {
      return;
    }

    this.homeService.members = await db.connection.select({
      from: "members",
      where: {
        owner_id: this.authService.getUserId(),
      },
      join: [{
        with: "chats",
        on: "members.chat_id = chats.chat_id",
        joinUnder: "chat",
      },
        {
          with: "users",
          on: "members.user_id = users.user_id",
          joinUnder: "user",
        }]
    }).then(async members => {
      for (const member of members) {
        member.chat!.settings = JSON.parse(member.chat!.settings as string) as ChatSettings;

        if (member.chat!.is_group || !member.user!.picture || typeof member.user!.picture !== "string") {
          continue;
        }

        const image_data = await CryptUtils.decryptData(member.user!.picture, this.authService.getSecretKey()!);
        member.user!.picture = JSON.parse(image_data) as FileInfo;
      }

      return members;
    }).catch(() => []);

    this.chatList.nativeElement.scrollTop = this.homeService.lastScrollPosition;
  }

  protected readonly Tools = Tools;
}
