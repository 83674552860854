import {User} from "./model/User";
import {FileInfo} from "./model/FileInfo";
import {UserSettings} from "./model/UserSettings";

export class CryptUtils {
  static async decryptImage(user: User, secretKey: CryptoKey) {
    if (!user.picture || typeof user.picture !== "string") {
      return;
    }

    const image_data = await this.decryptData(user.picture as string, secretKey);
    if (!image_data.length) {
      return;
    }

    try {
      user.picture = JSON.parse(image_data) as FileInfo;
    } catch (e) {}
  }

  /**
   * Berechnet den SHA-512-Hashwert von symmetrischen Schlüssel.
   * @param {CryptoKey} secretKey Der Schlüssel welcher gehasht werden soll.
   * @return {Promise<string>} Der Hashwert des Schlüssels als Base64-kodierte Zeichenfolge.
   */
  static async hashSecretKey(secretKey: CryptoKey): Promise<string> {
    const key = await crypto.subtle.exportKey("raw", secretKey);
    const hash = await crypto.subtle.digest("SHA-512", key);

    return this.arrayToBase64(hash);
  }

  /**
   * Importiert den öffentlichen Schlüssel im base64-Format und gibt das resultierende CryptoKey-Objekt zurück.
   * @param {string} base64Key Der öffentliche Schlüssel im base64-Format
   * @return {Promise<CryptoKey>} Ein CryptoKey-Objekt, das den öffentlichen Schlüssel repräsentiert
   */
  static async getPublicKey(base64Key: string): Promise<CryptoKey> {
    const publicKey = this.base64ToArray(base64Key);

    return await crypto.subtle.importKey("spki", publicKey, {name: "RSA-OAEP", hash: "SHA-512"}, false, ["encrypt"]);
  }

  static async importSignPublicKey(base64Key: string) {
    try {
      const publicKey = this.base64ToArray(base64Key);

      return await crypto.subtle.importKey(
        "spki",
        publicKey,
        {
          name: "RSASSA-PKCS1-v1_5",
          hash: "SHA-512",
        }, true, ["verify"]
      );
    } catch (e) {
      return {} as CryptoKey;
    }
  }

  static async decryptSignPrivateKey(base64Key: string, secretKey: CryptoKey): Promise<CryptoKey> {
    const encrypted = this.base64ToArray(base64Key);
    const split = this.splitArrayBuffer(encrypted, 16);

    const decrypted = await crypto.subtle.decrypt({
      name: "AES-GCM",
      iv: split[0],
      length: 256,
      tagLength: 128
    }, secretKey, split[1]);

    return await crypto.subtle.importKey("pkcs8", decrypted, {
      name: "RSASSA-PKCS1-v1_5",
      hash: "SHA-512"
    }, true, ["sign"])
  }

  /**
   * Entschlüsselt den privaten Schlüssel im base64-Format mit dem gegebenen geheimen Schlüssel und gibt das resultierende CryptoKey-Objekt zurück.
   * @param {string} base64Key Der zu entschlüsselnde private Schlüssel im base64-Format
   * @param {CryptoKey} secretKey Der geheime Schlüssel, mit dem der private Schlüssel entschlüsselt werden soll
   * @return {Promise<CryptoKey>} Ein CryptoKey-Objekt, das den entschlüsselten privaten Schlüssel repräsentiert
   */
  static async decryptPrivateKey(base64Key: string, secretKey: CryptoKey): Promise<CryptoKey> {
    const encrypted = this.base64ToArray(base64Key);
    const split = this.splitArrayBuffer(encrypted, 16);

    const decrypted = await crypto.subtle.decrypt({
      name: "AES-GCM",
      iv: split[0],
      length: 256,
      tagLength: 128
    }, secretKey, split[1]);

    return await crypto.subtle.importKey("pkcs8", decrypted, {name: "RSA-OAEP", hash: "SHA-512"}, true, ["decrypt"]);
  }

  /**
   * Verschlüsselt den privaten Schlüssel mit dem gegebenen geheimen Schlüssel und gibt das Ergebnis als base64-kodierten String zurück.
   * @param {CryptoKey} privateKey Der zu verschlüsselnde private Schlüssel
   * @param {CryptoKey} secretKey Der geheime Schlüssel, mit dem der private Schlüssel verschlüsselt werden soll
   * @return {Promise<string>} Ein base64-kodierter String, der den verschlüsselten privaten Schlüssel repräsentiert
   */
  static async encryptPrivateKey(privateKey: CryptoKey, secretKey: CryptoKey): Promise<string> {
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const key = await crypto.subtle.exportKey("pkcs8", privateKey);

    const encrypted = await crypto.subtle.encrypt({
      name: "AES-GCM",
      iv: iv,
      length: 256,
      tagLength: 128
    }, secretKey, key) as Uint8Array;

    return this.arrayToBase64(this.concatenateArrayBuffers(iv, encrypted));
  }

  static async encryptSecretKeySymmetric(secretKey: CryptoKey, secretKey2: CryptoKey): Promise<string> {
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const key = await crypto.subtle.exportKey("raw", secretKey);

    const encrypted = await crypto.subtle.encrypt({
      name: "AES-GCM",
      iv: iv,
      length: 256,
      tagLength: 128
    }, secretKey2, key) as Uint8Array;

    return this.arrayToBase64(this.concatenateArrayBuffers(iv, encrypted));
  }

  static async decryptSecretKeySymmetric(base64Key: string, secretKey: CryptoKey): Promise<CryptoKey | null> {
    try {
      const encrypted = this.base64ToArray(base64Key);
      const split = this.splitArrayBuffer(encrypted, 16);

      const decrypted = await crypto.subtle.decrypt({
        name: "AES-GCM",
        iv: split[0],
        length: 256,
        tagLength: 128
      }, secretKey, split[1]);

      return await crypto.subtle.importKey("raw", decrypted, {
        name: "AES-GCM",
        length: 256
      }, true, ["encrypt", "decrypt"]);
    } catch (error) {
      return null;
    }
  }

  /**
   * Verschlüsselt den geheimen Schlüssel mit dem gegebenen öffentlichen Schlüssel und gibt das Ergebnis als base64-kodierten String zurück.
   * @param {CryptoKey} secretKey Der zu verschlüsselnde geheime Schlüssel
   * @param {CryptoKey} publicKey Der öffentliche Schlüssel, mit dem der geheime Schlüssel verschlüsselt werden soll
   * @return {Promise<string>} Ein base64-kodierter String, der den verschlüsselten geheimen Schlüssel repräsentiert
   */
  static async encryptSecretKey(secretKey: CryptoKey, publicKey: CryptoKey): Promise<string> {
    const key = await crypto.subtle.exportKey("raw", secretKey);
    const encrypted = await crypto.subtle.encrypt({name: "RSA-OAEP"}, publicKey, key);

    return this.arrayToBase64(encrypted);
  }

  static async exportSecretKey(secretKey: CryptoKey): Promise<string> {
    const key = await crypto.subtle.exportKey("raw", secretKey);

    return this.arrayToBase64(key);
  }

  static async importSecretKey(base64Key: string): Promise<CryptoKey> {
    const key = this.base64ToArray(base64Key);

    return await crypto.subtle.importKey("raw", key, {
      name: "AES-GCM",
      length: 256
    }, true, ["encrypt", "decrypt"]);
  }

  /**
   * Entschlüsselt den gegebenen Base64-kodierten Schlüssel mit dem gegebenen privaten Schlüssel.
   * Gibt den entschlüsselten Schlüssel als CryptoKey-Objekt zurück.
   * @param {string} base64Key Der Base64-kodierte Schlüssel, der entschlüsselt werden soll.
   * @param {CryptoKey} privateKey Der private Schlüssel, der zum Entschlüsseln des Schlüssels verwendet werden soll.
   * @return {Promise<CryptoKey>} Das entschlüsselte CryptoKey-Objekt.
   */
  static async decryptSecretKey(base64Key: string, privateKey: CryptoKey): Promise<CryptoKey | null> {
    try {
      const encrypted = this.base64ToArray(base64Key);
      const decrypted = await crypto.subtle.decrypt({name: "RSA-OAEP"}, privateKey, encrypted);
      return await crypto.subtle.importKey("raw", decrypted, {
        name: "AES-GCM",
        length: 256
      }, true, ["encrypt", "decrypt"]);
    } catch (error) {
      return null;
    }
  }

  static async signData(data: string, privateKey: CryptoKey): Promise<string> {
    const encoded = this.base64ToArray(data);
    const signature = await crypto.subtle.sign({name: "RSASSA-PKCS1-v1_5"}, privateKey, encoded);

    return this.arrayToBase64(signature);
  }

  static async verifySign(data: string, signature: string, publicKey: CryptoKey): Promise<boolean> {
    try {
      const encodedSignature = this.base64ToArray(signature);
      const encodedData = this.base64ToArray(data);

      return await crypto.subtle.verify({name: "RSASSA-PKCS1-v1_5"}, publicKey, encodedSignature, encodedData);
    } catch (e) {
      return false;
    }
  }

  static async areKeysAPair(privateKey: CryptoKey, publicKey: CryptoKey) {
    try {
      // Sign the test data with the private key
      const signature = await this.signData("test", privateKey);

      // Verify the signature with the public key and return
      return await this.verifySign("test", signature, publicKey);
    } catch (e) {
      return false;
    }
  }

  /**
   * Entschlüsselt die gegebenen Base64-kodierten Daten mit dem gegebenen Schlüssel.
   * Gibt die entschlüsselten Daten als Zeichenfolge zurück.
   * @param {string} base64Data Die Base64-kodierten Daten, die entschlüsselt werden sollen.
   * @param {CryptoKey} secretKey Der Schlüssel, der zum Entschlüsseln der Daten verwendet werden soll.
   * @return {Promise<string>} Die entschlüsselten Daten als Zeichenfolge.
   */
  static async decryptData(base64Data: string, secretKey: CryptoKey): Promise<string> {
    try {
      if (!base64Data) {
        return base64Data;
      }

      const encrypted = this.base64ToArray(base64Data);
      const split = this.splitArrayBuffer(encrypted, 16);

      const decrypted = await crypto.subtle.decrypt({
        name: "AES-GCM",
        iv: split[0],
        length: 256,
        tagLength: 128
      }, secretKey, split[1]);

      return new TextDecoder().decode(decrypted);
    } catch (e) {
      return "";
    }
  }

  /**
   * Verschlüsselt die gegebenen Daten mit dem gegebenen Schlüssel.
   * Gibt die verschlüsselten Daten als Base64-kodierte Zeichenfolge zurück.
   * @param {string} data Die zu verschlüsselnden Daten als Zeichenfolge.
   * @param {CryptoKey} secretKey Der Schlüssel, der zum Verschlüsseln der Daten verwendet werden soll.
   * @return {Promise<string>} Die verschlüsselten Daten als Base64-kodierte Zeichenfolge.
   */
  static async encryptData(data: string, secretKey: CryptoKey): Promise<string> {
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const encoded = new TextEncoder().encode(data);

    const encrypted = await crypto.subtle.encrypt({
      name: "AES-GCM",
      iv: iv,
      length: 256,
      tagLength: 128
    }, secretKey, encoded);

    return this.arrayToBase64(this.concatenateArrayBuffers(iv, encrypted));
  }

  /**
   * Konvertiert eine Base64-kodierte Zeichenfolge in eine Uint8Array-Instanz.
   * @param {string} base64 Die Base64-kodierte Zeichenfolge.
   * @return {Uint8Array} Das Uint8Array-Objekt, das aus der Base64-kodierten Zeichenfolge erstellt wurde.
   */
  static base64ToArray(base64: string): Uint8Array {
    const binary = atob(base64);
    const len = binary.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binary.charCodeAt(i);
    }

    return bytes;
  }

  /**
   * Konvertiert ein ArrayBuffer-Objekt in eine Base64-kodierte Zeichenfolge.
   * @param {ArrayBuffer} array Der ArrayBuffer, der in eine Base64-kodierte Zeichenfolge konvertiert werden soll.
   * @return {string} Die Base64-kodierte Zeichenfolge.
   */
  static arrayToBase64(array: ArrayBuffer): string {
    const bytes = new Uint8Array(array);
    let binary = '';

    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary);
  }

  /**
   * Generiert ein neues Schlüsselpaar mit den folgenden Eigenschaften:
   * Algorithmus: RSA-OAEP
   * Hash-Algorithmus: SHA-512
   * Modulus-Länge: 2048
   * Public Exponent: 0x10001
   * @return {Promise<CryptoKeyPair>} Das generierte Schlüsselpaar als CryptoKeyPair-Objekt.
   */
  static async generateKeyPair(): Promise<CryptoKeyPair> {
    return await crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        hash: "SHA-512",
        modulusLength: 2048,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      },
      true,
      ["encrypt", "decrypt"]
    ) as CryptoKeyPair;
  }

  /**
   * Generiert einen neuen geheimen Schlüssel mit den folgenden Eigenschaften:
   * Algorithmus: AES-GCM
   * Schlüssellänge: 256 Bit
   * @return {Promise<CryptoKey>} Der generierte geheime Schlüssel als CryptoKey-Objekt.
   */
  static async generateSecretKey(): Promise<CryptoKey> {
    return await crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256
      },
      true,
      ["encrypt", "decrypt"]
    ) as CryptoKey;
  }

  /**
   * Leitet einen geheimen Schlüssel aus dem gegebenen Passwort und Salt ab.
   * Das abgeleitete Schlüsselobjekt kann dann zum Verschlüsseln und Entschlüsseln von Daten verwendet werden.
   * @param {string} password Das Passwort, aus dem der Schlüssel abgeleitet werden soll.
   * @param {ArrayBuffer} salt Das Salt, das zur Schlüsselableitung verwendet werden soll.
   * @return {Promise<CryptoKey>} Der abgeleitete geheime Schlüssel als CryptoKey-Objekt.
   */
  static async passwordToSecretKey(password: string, salt: ArrayBuffer): Promise<CryptoKey> {
    const encoded = new TextEncoder().encode(password);

    const imported = await crypto.subtle.importKey(
      "raw",
      encoded,
      {
        name: "PBKDF2",
      },
      false,
      ["deriveKey"]
    ) as CryptoKey;

    return await crypto.subtle.deriveKey(
      {name: "PBKDF2", salt: salt, iterations: 100000, hash: "SHA-512"},
      imported,
      {name: "AES-GCM", length: 256},
      true,
      ["encrypt", "decrypt"]
    ) as CryptoKey;
  }

  /**
   * Berechnet den SHA-512-Hashwert der gegebenen Zeichenfolge.
   * @param {string} string Die Zeichenfolge, deren Hashwert berechnet werden soll.
   * @return {Promise<string>} Der Hashwert der Zeichenfolge als Base64-kodierte Zeichenfolge.
   */
  static async hashString(string: string): Promise<string> {
    const encoded = new TextEncoder().encode(string);
    const hash = await crypto.subtle.digest("SHA-512", encoded);

    return this.arrayToBase64(hash);
  }

  /**
   * Entschlüsselt den privaten und öffentlichen Schlüssel eines Benutzers mithilfe des gegebenen Passworts.
   * Gibt den Benutzer mit entschlüsselten Daten zurück.
   * @param {User} user Der Benutzer, dessen Schlüssel entschlüsselt werden sollen.
   * @param {string} password Das Passwort, das zum Entschlüsseln der Schlüssel verwendet werden soll.
   * @return {Promise<boolean>} Gibt true zurück, wenn die Entschlüsselung erfolgreich war, andernfalls false.
   */
  static async decryptUser(user: User, password: string): Promise<boolean> {
    try {
      window.atob(user.salt as string);
      user.salt = this.base64ToArray(user.salt as string);
    } catch (ignore) {
      // salt is already decoded
    }

    try {
      const secret_key = await this.passwordToSecretKey(password, user.salt as ArrayBuffer);
      const private_key = await this.decryptPrivateKey(user.private_key as string, secret_key);
      const sign_private_key = await this.decryptSignPrivateKey(user.sign_private_key as string, secret_key);
      const image = await this.decryptData(user.picture as string, secret_key);

      user.private_key = private_key;
      user.sign_private_key = sign_private_key;
      user.picture = (image && image !== "") ? JSON.parse(image) : {};

      try {
        user.settings = JSON.parse(user.settings as string) as UserSettings;
      } catch (e) {
        user.settings = {} as UserSettings;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Generiert geheime Schlüssel und verschlüsselt diese mit den öffentlichen Schlüsseln der gegebenen Benutzer.
   * Gibt ein assoziatives Array zurück, das die Benutzer-IDs als Schlüssel und die Base64-kodierten Schlüssel als Werte enthält.
   * @param {Array<User>} users Ein Array von Benutzern, deren öffentliche Schlüssel zur Verschlüsselung der geheimen Schlüssel verwendet werden sollen.
   * @return {Promise<Record<number, string>>} Ein assoziatives Array, das die Benutzer-IDs als Schlüssel und die Base64-kodierten geheimen Schlüssel als Werte enthält.
   */
  static async generateSecretKeys(users: Array<User>): Promise<Record<number, string>> {
    const secret_key = await this.generateSecretKey();
    const secret_keys = {} as Record<string, string>;

    for (let user of users) {
      const public_key = await this.getPublicKey(user.public_key as string);

      secret_keys[user.user_id] = await this.encryptSecretKey(secret_key, public_key as CryptoKey);
    }

    return secret_keys;
  }

  /**
   * Generiert ein neues Schlüsselpaar mit den folgenden Eigenschaften:
   * Algorithmus: ECDSA
   * Kurve: P-521
   * @return {Promise<CryptoKeyPair>} Das generierte Schlüsselpaar als CryptoKeyPair-Objekt.
   */
  static async generateSignKeyPair(): Promise<CryptoKeyPair> {
    return await crypto.subtle.generateKey(
      {
        name: "RSASSA-PKCS1-v1_5",
        hash: "SHA-512",
        modulusLength: 2048,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      },
      true,
      ["sign", "verify"]
    ) as CryptoKeyPair;
  }

  /**
   * Exportiert den öffentlichen Schlüssel im base64-Format und gibt diesen zurück.
   * @param {CryptoKey} publicKey Der öffentliche Schlüssel, der exportiert werden soll
   * @return {Promise<string>} Der öffentliche Schlüssel im base64-Format
   */
  static async exportPublicKey(publicKey: CryptoKey): Promise<string> {
    const exported = await crypto.subtle.exportKey("spki", publicKey);

    return this.arrayToBase64(exported);
  }

  /**
   * Fügt zwei ArrayBuffer zusammen und gibt das Ergebnis zurück.
   * @param {ArrayBuffer} buffer1 Der erste ArrayBuffer
   * @param {ArrayBuffer} buffer2 Der zweite ArrayBuffer
   * @return {ArrayBuffer} Ein neuer ArrayBuffer, der die beiden Argument-Buffer enthält
   */
  private static concatenateArrayBuffers(buffer1: ArrayBuffer, buffer2: ArrayBuffer): ArrayBuffer {
    const combined = new Uint8Array(buffer1.byteLength + buffer2.byteLength);

    combined.set(new Uint8Array(buffer1), 0);
    combined.set(new Uint8Array(buffer2), buffer1.byteLength);

    return combined.buffer;
  }

  /**
   * Teilt den ArrayBuffer am angegebenen Index und gibt die beiden resultierenden Buffer als Tuple zurück.
   * Wenn der Split-Index größer als die Größe des Buffers ist, wird eine Fehlermeldung zurückgegeben.
   * @param {ArrayBuffer} buffer Der ArrayBuffer, der geteilt werden soll
   * @param {number} splitIndex Der Index, an dem der ArrayBuffer geteilt werden soll
   * @return {[ArrayBuffer, ArrayBuffer]} Ein Tupel, das die beiden resultierenden Buffer enthält
   */
  private static splitArrayBuffer(buffer: ArrayBuffer, splitIndex: number): [ArrayBuffer, ArrayBuffer] {
    if (splitIndex > buffer.byteLength) {
      throw new Error('Split index is out of bounds');
    }

    const buffer1 = buffer.slice(0, splitIndex);
    const buffer2 = buffer.slice(splitIndex);

    return [buffer1, buffer2];
  }
}
