import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {AuthService} from "../../assets/js/service/auth";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserSettings} from "../../assets/js/model/UserSettings";
import {CryptUtils} from "../../assets/js/crypt_utils";
import {HomeService} from "../../assets/js/service/home";
import {NetworkService} from "../../assets/js/service/network";
import {FileInfo} from "../../assets/js/model/FileInfo";
import {ChatService} from "../../assets/js/service/chat";
import {FileViewerService} from "../../assets/js/service/fileviewer";
import {ImageUtils} from "../../assets/js/image_utils";
import {Tools} from "../../assets/js/tools";
import Swal from "sweetalert2";
import {CustomValidators} from "../../assets/js/validators";
import {PopstateService} from "../../assets/js/service/popstate";

@Component({
  selector: 'app-personalize',
  templateUrl: './personalize.component.html',
  styleUrl: './personalize.component.scss'
})
export class PersonalizeComponent {
  protected open_state: boolean = false;
  protected edit_mode: boolean = false;
  protected selecting: boolean = false;

  protected settings: UserSettings;
  protected image: FileInfo;

  @ViewChild("sidebar")
  private sidebar!: ElementRef;

  @ViewChild("username")
  private username!: ElementRef;

  userFormGroup = new FormGroup({
    username: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(32)
    ])
  });

  passwordFormGroup = new FormGroup({
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(14),
        CustomValidators.password({"password": true})
      ]),
      password_repeat: new FormControl("", [
        Validators.required,
      ])
    },
    {
      validators: [
        CustomValidators.match("password", "password_repeat")
      ],
      updateOn: "change"
    });

  settingsFormGroup = new FormGroup({
    click_to_open: new FormControl(),
    dark_mode: new FormControl()
  });

  constructor(private cdr: ChangeDetectorRef, private popstateService: PopstateService, protected authService: AuthService, private fileViewerService: FileViewerService, private homeService: HomeService, private chatService: ChatService, private networkService: NetworkService) {
    this.settings = this.authService.getSettings();
    this.image = this.authService.getImage();

    this.settingsFormGroup.controls.click_to_open.setValue(this.settings.click_to_open ?? false);
    this.settingsFormGroup.controls.dark_mode.setValue(this.settings.dark_mode ?? true);

    this.userFormGroup.controls.username.setValue(this.authService.getUsername());
  }

  open(): void {
    this.popstateService.addState("personalize", () => {
      if (this.selecting || this.edit_mode) {
        this.selecting = false;
        this.edit_mode = false;

        this.fileViewerService.removeFile();

        this.cdr.detectChanges();

        return false;
      } else if (this.fileViewerService.controls) {
        return false;
      }

      this.open_state = false;

      this.cdr.detectChanges();

      return true;
    });

    this.sidebar.nativeElement.scrollTop = 0;

    this.open_state = true;
  }

  protected close(): void {
    this.popstateService.removeState("personalize");

    this.open_state = false;
  }

  protected editMode(): void {
    this.username.nativeElement.removeAttribute("readonly");
    this.username.nativeElement.focus();

    this.edit_mode = true;
  }

  protected exitMode(): void {
    this.username.nativeElement.setAttribute("readonly", "true");

    this.edit_mode = false;
  }

  protected async submitSettings(): Promise<void> {
    if (this.settingsFormGroup.valid) {
      const formData = structuredClone(this.settingsFormGroup.value) as UserSettings;

      this.networkService.request("PUT", "/auth/account/settings", JSON.stringify({settings: formData})).then(response => {
        if (response.status === "success") {
          this.settings = formData;

          this.authService.setSettings(formData);
          this.homeService.updateTheme();
        }
      });
    }
  }

  protected submitUsername(): void {
    if (this.userFormGroup.valid) {
      const username = this.userFormGroup.controls.username.value!;

      this.networkService.request("PUT", "/auth/account/username", JSON.stringify({username: username})).then(response => {
        if (response.status === "success") {
          this.authService.setUsername(username);
        }
      });

      this.exitMode();
    }
  }

  protected selectImage(): void {
    this.selecting = true;

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg";
    input.onchange = () => this.selectedImage(input);

    input.click();
  }

  protected selectedImage(data: HTMLInputElement): void {
    if (data.files!.length === 1) {
      const image: File = data.files![0];

      if (image.size > 10000000) {
        Tools.showMessage("Sie können maximal ein 10 MB grosses Bild setzen", "error");
        return;
      }

      const name = image.name;

      (new ImageUtils()).resize(image, {width: 200, height: 200}).then(async (image) => {
        const imageInfo: FileInfo = {
          name: name,
          type: image.type,
          size: image.size
        }

        const reader = new FileReader();
        reader.onload = async event => {
          imageInfo.data = event.target!.result as string;

          this.sidebar.nativeElement.style.overflow = "hidden";

          this.popstateService.addState("fileviewer-personalize", () => {
            this.fileViewerService.removeFile();

            this.cdr.detectChanges();

            return true;
          });

          this.fileViewerService.controls = true;
          this.fileViewerService.setFile(imageInfo);

          let subscription;
          await new Promise<void>(resolve => {
            let first = true;

            subscription = this.fileViewerService.accepted.subscribe(async accepted => {
              if (first) {
                first = false;
                return;
              }

              if (accepted) {
                this.image = imageInfo;
                await this.submitImage(imageInfo);
              }

              this.sidebar.nativeElement.style.removeProperty("overflow");

              this.fileViewerService.controls = false;
              this.fileViewerService.removeFile();

              this.popstateService.removeState("fileviewer-personalize");

              resolve();
            });
          });

          // @ts-ignore
          subscription.unsubscribe();
        }

        reader.readAsDataURL(image);
      });
    }
  }

  private async submitImage(image: FileInfo): Promise<void> {
    const encryptedImage = await CryptUtils.encryptData(JSON.stringify(image), this.authService.getSecretKey()!);

    const response = await this.networkService.request("PUT", "/auth/account/picture", JSON.stringify({picture: encryptedImage}));
    if (response.status === "success") {
      this.authService.setImage(image);
      await this.homeService.updatePictures(image);
    }
  }

  protected async changePassword(): Promise<void> {
    if (!this.passwordFormGroup.valid) {
      return;
    }

    const salt = crypto.getRandomValues(new Uint8Array(16));
    const secret_key = await CryptUtils.passwordToSecretKey(this.passwordFormGroup.value.password!, salt);
    const private_key = await CryptUtils.encryptPrivateKey(this.authService.getPrivateKey()!, secret_key);
    const sign_private_key = await CryptUtils.encryptPrivateKey(this.authService.getSignPrivateKey()!, secret_key);
    const encoded_salt = CryptUtils.arrayToBase64(salt);

    let response;
    response = await this.networkService.request("GET", "/chats/keys");

    const secret_keys: string[] = [];
    for (const secret_key_info of response.data) {
      const chat_key = await CryptUtils.decryptSecretKeySymmetric(secret_key_info.secret_key, this.authService.getSecretKey()!);

      if (!chat_key) {
        continue;
      }

      secret_key_info.secret_key = await CryptUtils.encryptSecretKeySymmetric(chat_key, secret_key);
      secret_keys.push(secret_key_info);
    }

    response = await this.networkService.request("PUT", "/auth/account/password", JSON.stringify({
      password: await CryptUtils.hashSecretKey(secret_key),
      salt: encoded_salt,
      private_key: private_key,
      sign_private_key: sign_private_key,
      secret_keys: secret_keys
    }));

    if (response.status === "success") {
      this.clearData();
    }
  }

  protected clearData(): void {
    this.homeService.members = [];
    this.chatService.messages = {};
    this.chatService.open_message = undefined;
    this.chatService.share_data = null;

    this.authService.clearData();
  }

  protected deleteAccount(): void {
    Swal.fire({
      title: "Konto löschen",
      text: "Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Geben Sie Ihren Benutzernamen ein, um fortzufahren.",
      footer: "Es werden ausnahmslos ALLE Daten gelöscht (auf dem Server und auf dem Gerät). Diese Aktion kann nicht rückgängig gemacht werden!",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Jetzt löschen",
      cancelButtonText: "Nein",
      preConfirm: async (username) => {
        if (username !== this.authService.getUsername()) {
          Swal.showValidationMessage("Der eingegebene Benutzername stimmt nicht mit Ihrem Benutzernamen überein. Sind Sie im richtigen Konto angemeldet?");
          return;
        }

        await this.networkService.request("DELETE", "/auth/account");
        this.clearData();
      }
    });
  }

  protected readonly Tools = Tools;
}
