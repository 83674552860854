import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import {ChatService} from "../../../assets/js/service/chat";

@Component({
  selector: "custom-media-element",
  templateUrl: "./custom-media-element.component.html",
  styleUrls: ["./custom-media-element.component.scss"]
})
export class CustomMediaElementComponent implements AfterViewInit, OnDestroy {
  // Instead of a simple "video" or "audio", we now accept a full MIME type.
  // Example: "video/mp4" or "audio/mp3"
  @Input() src: string = "";
  @Input() mimeType: string = ""; // e.g., "video/mp4" or "audio/mpeg"
  // Optionally, you can supply a duration (in seconds) to override the media metadata.
  @Input() duration: number = 0;

  @ViewChild("mediaElement")
  private mediaElement!: ElementRef<HTMLMediaElement>;

  playing: boolean = false;
  protected currentTime: number = 0;
  protected totalTime: number = 0;
  protected volume: number = 1;

  private last_event: number = 0;

  private media!: HTMLMediaElement;

  constructor(private cdr: ChangeDetectorRef, protected chatService: ChatService) {}

  // A getter to determine if the media is video based on the MIME type.
  protected get isVideo(): boolean {
    return this.mimeType.toLowerCase().startsWith("video/");
  }

  ngAfterViewInit() {
    this.media = this.mediaElement.nativeElement;
    this.media.volume = this.volume;

    // When metadata is loaded, use either the provided duration or the media's duration.
    this.media.addEventListener("loadedmetadata", () => {
      this.totalTime = this.duration > 0 ? this.duration : this.media.duration;
      this.cdr.detectChanges();
    });

    this.media.addEventListener("timeupdate", () => {
      this.currentTime = this.media.currentTime;
      this.cdr.detectChanges();
    });

    this.media.addEventListener("ended", () => {
      this.playing = false;
      this.cdr.detectChanges();
    });
  }

  async playPause() {
    if (this.last_event + 500 > Date.now()) {
      return;
    }

    this.last_event = Date.now();

    if (!this.playing) {
      await this.media.play();
      this.playing = true;
    } else {
      this.media.pause();
      this.playing = false;
    }
  }

  protected onVolumeChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.volume = Number(input.value);
    this.media.volume = this.volume;
  }

  protected seek(event: MouseEvent) {
    const progressContainer = event.currentTarget as HTMLElement;
    const rect = progressContainer.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const percent = clickX / rect.width;
    this.media.currentTime = Number(percent * this.totalTime) ?? 0;
  }

  protected formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return minutes + ":" + (secs < 10 ? "0" : "") + secs;
  }

  protected toggleFullScreen() {
    // Only apply full screen for video elements.
    if (!this.isVideo) {
      return;
    }
    // If already in fullscreen, exit.
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      // Request fullscreen on the media element.
      if (this.media.requestFullscreen) {
        this.media.requestFullscreen();
      } else if ((this.media as any).webkitRequestFullscreen) {
        (this.media as any).webkitRequestFullscreen();
      } else if ((this.media as any).mozRequestFullScreen) {
        (this.media as any).mozRequestFullScreen();
      } else if ((this.media as any).msRequestFullscreen) {
        (this.media as any).msRequestFullscreen();
      }
    }
  }

  ngOnDestroy() {
    this.media.pause();
    this.src = "";
  }
}
