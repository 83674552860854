<div class="info-sidebar" [class.show]="this.open_state">
  <i class="bi bi-x close" (click)="this.close()"></i>

  <div class="py-5 px-4" *ngIf="this.member">
    <div class="chat-picture mt-3 d-flex justify-content-center">
      <app-profile-picture [member]="this.member"></app-profile-picture>
    </div>
    <div class="username d-flex flex-column w-100 mt-4">
      <form class="m-auto d-flex w-100" (ngSubmit)="this.renameChat()" [formGroup]="chatnameFormGroup">
        <input #chatname tabindex="1" autocomplete="chatname" class="form-control" formControlName="name"
               id="chatname" placeholder="Kevin" type="text" readonly/>
        <i *ngIf="!this.edit_mode" class="bi bi-pencil ms-3 p-2" (click)="this.editMode()"></i>

        <button *ngIf="this.edit_mode" class="bi bi-check ms-3 p-2" type="submit"></button>
        <button *ngIf="this.edit_mode" class="bi bi-x p-2" (click)="this.exitMode()"></button>
      </form>
      <span *ngIf="Tools.checkFormControl(chatnameFormGroup.controls.name, 'minlength')" class="error d-block"> muss mindestens {{ chatnameFormGroup.controls.name.getError('minlength')['requiredLength'] }}
        Zeichen lang sein</span>
      <span *ngIf="Tools.checkFormControl(chatnameFormGroup.controls.name, 'maxlength')" class="error d-block"> darf maximal {{ chatnameFormGroup.controls.name.getError('maxlength')['requiredLength'] }}
        Zeichen lang sein</span>
    </div>
    <hr class="mt-4"/>
    <div class="d-flex flex-column w-100 mt-3 m-auto max-width-450">
      <ng-container *ngIf="this.member.chat!.is_group && this.member.chat!.creator_id === this.authService.getUserId()">
        <h4>Einstellungen</h4>
        <div class="settings d-flex w-100 mt-2">
          <form class="d-flex flex-column w-100" (ngSubmit)="this.submitSettings()" [formGroup]="settingsFormGroup">
            <div class="d-flex">
              <label class="flex-grow-1" for="click_to_open">Klicken um Nachrichten zu entschlüsseln</label>
              <input tabindex="2" autocomplete="click_to_open" class="form-check-input" formControlName="click_to_open"
                     id="click_to_open"
                     type="checkbox" checked/>
            </div>
            <div class="d-flex mt-1">
              <label class="flex-grow-1" for="messages_from_join">Benutzer sehen Nachrichten bis Beitritt</label>
              <input tabindex="3" autocomplete="messages_from_join" class="form-check-input"
                     formControlName="messages_from_join"
                     id="messages_from_join"
                     type="checkbox" checked/>
            </div>
            <div class="d-flex mt-1 break-mobile">
              <label class="flex-grow-1 text-nowrap me-5" for="who_can_write">Wer darf schreiben</label>
              <select tabindex="4" autocomplete="who_can_write" class="form-select" formControlName="who_can_write"
                      id="who_can_write">
                <option value="all">Jeder</option>
                <option value="admins">Administratoren</option>
                <option value="creator">Ersteller</option>
              </select>
            </div>
            <button class="btn btn-primary mt-4" type="submit">Speichern</button>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="!this.member.chat!.is_group">
        <h4>Einstellungen</h4>
        <div class="settings d-flex w-100 mt-2">
          <form class="d-flex flex-column w-100" (ngSubmit)="this.submitSettings()" [formGroup]="settingsFormGroup">
            <div class="d-flex">
              <label class="flex-grow-1" for="click_to_open">Klicken um Nachrichten zu entschlüsseln</label>
              <input tabindex="2" autocomplete="click_to_open" class="form-check-input" formControlName="click_to_open"
                     id="click_to_open"
                     type="checkbox" checked/>
            </div>
            <div class="d-flex mt-1 break-mobile">
              <label class="flex-grow-1 text-nowrap me-5" for="timer">Nachrichten Ablaufzeit</label>
              <select tabindex="3" autocomplete="timer" class="form-select" formControlName="timer"
                      id="timer">
                <option value="0" [disabled]="this.chatService.settings?.timer !== 0">Keine Ablaufzeit</option>
                <option value="300">5 Minuten</option>
                <option value="600">10 Minuten</option>
                <option value="1800">30 Minuten</option>
                <option value="3600">1 Stunde</option>
                <option value="18000">5 Stunden</option>
                <option value="36000">10 Stunden</option>
                <option value="43200">12 Stunden</option>
                <option value="86400">24 Stunden</option>
              </select>
            </div>
            <div *ngIf="this.chatService.settings?.timer === 0" class="alert alert-dark mt-2"
                 role="alert">
              <b>WARNUNG!</b>
              Diese Unterhaltung hat zurzeit eine unendliche Ablaufzeit. <br/>
              Eine unendliche Ablaufzeit ist danach nicht mehr möglich!
            </div>
            <button class="btn btn-primary mt-3" type="submit">Speichern</button>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="!this.member.chat!.is_group else group">
        <button class="btn btn-secondary mt-4" (click)="this.chatService.archiveChat(this.chatService.chat_id)">
          Chat archivieren
        </button>
        <button class="btn btn-danger mt-4" (click)="this.chatService.deleteChat(this.chatService.chat_id)">
          Chat löschen
        </button>
        <button *ngIf="!this.chatService.blocked" class="btn btn-danger mt-4"
                (click)="this.chatService.blockUser(this.chatService.chat_id);">
          Benutzer blockieren
        </button>
        <button *ngIf="this.chatService.blocked" class="btn btn-secondary mt-4"
                (click)="this.chatService.unblockUser(this.chatService.chat_id);">
          Benutzer entsperren
        </button>
      </ng-container>
      <ng-template #group>
        <div class="members d-flex flex-column w-100 mt-4" *ngIf="this.users && this.users.length">
          @for (user of this.users; track user; let index = $index) {
            <div
              class="list-group-item user-select-none d-flex justify-content-start align-items-center rounded-0 border-start-0 border-end-0 py-3"
              (contextmenu)="contextMenu.openContextMenu($event, {user_id: user.user_id, chat_id: this.member.chat!.chat_id}, user.user_id, this.member.chat!)"
              (touchstart)="contextMenu.handleTouchStart($event,{user_id: user.user_id, chat_id: this.member.chat!.chat_id}, user.user_id, this.member.chat!)"
              (touchmove)="contextMenu.handleTouchMove()"
              (touchend)="contextMenu.handleTouchEnd($event)">
              <div class="w-65-px">
                <app-profile-picture [user]="user"></app-profile-picture>
              </div>
              <div class="ms-4 me-4">
                <div class="username fs-7">
                  {{ user.username }}
                </div>
              </div>
            </div>
          }
        </div>
        <ng-container *ngIf="this.is_creator">
          <button class="btn btn-primary mt-4" (click)="this.componentService.getSearch()?.open('add');">
            Benutzer hinzufügen
          </button>
        </ng-container>
        <button class="btn btn-secondary mt-4" (click)="this.chatService.archiveChat(this.chatService.chat_id)">
          Gruppe archivieren
        </button>
        <ng-container *ngIf="!this.is_creator">
          <button class="btn btn-danger mt-4" (click)="this.chatService.leaveChat(this.chatService.chat_id);">
            Gruppe verlassen
          </button>
        </ng-container>
        <ng-container *ngIf="this.is_creator">
          <button class="btn btn-danger mt-4" (click)="this.chatService.deleteChat(this.chatService.chat_id)">
            Gruppe löschen
          </button>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>


<app-contextmenu #contextMenu [menuItems]="contextMenuItems"></app-contextmenu>
