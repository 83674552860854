<div id="home-bar" class="d-flex flex-column h-100">
  <nav class="navbar navbar-expand-lg z-3 w-100 top-0">
    <div class="container-fluid">
      <a (click)="this.refresh()" class="navbar-brand" role="button">{{ this.authService.getUsername() }}</a>
      <button aria-controls="action-menu" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler shadow-none" data-bs-toggle="dropdown" id="action-menu" type="button">
        <i class="bi bi-three-dots-vertical"></i>
      </button>
      <ul aria-labelledby="action-menu" class="dropdown-menu dropdown-menu-end">
        <li><a (click)="search.open()" class="dropdown-item">Neuer Chat</a></li>
        <li *ngIf="!this.screenService.isBigScreen()"><a (click)="this.secretKeyGenerator()" class="dropdown-item">QR
          Chat</a></li>
        <!--<li><a class="dropdown-item">Neue Gruppe</a></li>-->
        <li><a class="dropdown-item" (click)="personalize.open()">Personalisieren</a></li>
        <!--<li><a class="dropdown-item">Konto</a></li>-->
      </ul>
    </div>
  </nav>

  <div class="h-100-56-px position-relative">
    <div id="chat-list" class="h-100">
      <div class="container-fluid p-0 m-0 h-100">
        <div class="list-group" #chatList>
          @for (member of this.homeService.sorted_members; track member; let index = $index) {
            <div *ngIf="!member.chat!.is_archived"
                 (click)="this.handleClick(member.chat!.chat_id, index)"
                 (contextmenu)="contextMenu.openContextMenu($event, {chat_id: member.chat!.chat_id, user_id: member.user?.user_id}, member.user!, member.chat!)"
                 (touchstart)="contextMenu.handleTouchStart($event,{chat_id: member.chat!.chat_id, user_id: member.user?.user_id}, member.user!, member.chat!)"
                 (touchmove)="contextMenu.handleTouchMove()"
                 (touchend)="contextMenu.handleTouchEnd($event)"
                 [ngClass]="{'active': member.chat!.chat_id === this.chatService.chat_id && this.screenService.isBigScreen()}"
                 [attr.data-chat_id]="member.chat!.chat_id"
                 class="list-group-item user-select-none d-flex justify-content-start align-items-center rounded-0 border-start-0 border-end-0 py-3">
              <div class="w-65-px">
                <app-profile-picture [member]="member"></app-profile-picture>
              </div>
              <div class="ms-4 me-4">
                <div class="username fs-7">
                  {{ member.chat!.name ?? member.user!.username }}
                </div>
                <div class="chat-icons">
                  <ng-container *ngIf="!member.chat!.is_group && member.user!.trusted">
                    <i class="bi bi-patch-check" title="Sie vertrauen dieser Person"></i>
                  </ng-container>
                  <ng-container *ngIf="member.chat!.is_qr_chat">
                    <i class="bi bi-qr-code-scan" title="Dieser Chat wurde mit einem QR-Code erstellt"></i>
                  </ng-container>
                  <ng-container *ngIf="member.chat!.settings.timer">
                    <i class="bi bi-clock" title="Dieser Chat hat eine Nachrichten Ablaufzeit"></i>
                  </ng-container>
                  <ng-container *ngIf="member.chat!.is_suspended">
                    <i class="bi bi-x-circle" title="Dieser Chat wurde gesperrt"></i>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="member.unseen_messages"
                   class="badge bg-primary rounded-pill ms-auto">{{ member.unseen_messages }}
              </div>
            </div>
          } @empty {
            <div *ngIf="!this.loading"
                 class="list-group-item user-select-none d-flex justify-content-center align-items-center rounded-0 border-start-0 border-end-0 py-3">
              <div class="fs-5">Keine Chats</div>
            </div>
          }
          <ng-container *ngIf="this.homeService.archive_members.length">
            <button (click)="archive.open()"
                    class="btn-archive list-group-item user-select-none d-flex justify-content-center align-items-center rounded-0 border-start-0 border-end-0 py-3 fs-5">
              Archivierte Chats
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <app-search #search></app-search>
    <app-personalize #personalize></app-personalize>
    <app-archive #archive></app-archive>
    <div id="qr-code" [class.d-none]="!this.base64JSON" [class.d-flex]="this.base64JSON"
         class="w-100 h-100 top-0 left-0 z-2 position-absolute bg-body flex-column">
      <div class="icons">
        <i class="bi bi-x close" (click)="this.closeQR()"></i>
        <i *ngIf="camera.isOpen" class="bi bi-qr-code" (click)="camera.close()"></i>
        <i *ngIf="!camera.isOpen" class="bi bi-camera-fill" (click)="camera.open('qr')"></i>
      </div>
      <qrcode class="m-auto" [class.d-none]="camera.isOpen" [allowEmptyString]="true" [qrdata]="this.base64JSON"
              [width]="325"></qrcode>
      <app-camera #camera></app-camera>
    </div>
  </div>
</div>

<app-chat></app-chat>

<app-contextmenu #contextMenu [menuItems]="contextMenuItems"></app-contextmenu>
