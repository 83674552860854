import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Tools} from "../../assets/js/tools";
import {User} from "../../assets/js/model/User";
import {HomeService} from "../../assets/js/service/home";
import {NetworkService} from "../../assets/js/service/network";
import {CryptUtils} from "../../assets/js/crypt_utils";
import {AuthService} from "../../assets/js/service/auth";
import {ChatService} from "../../assets/js/service/chat";
import {db} from "../../db/db";
import {DBHandlerService} from "../../assets/js/service/db_handler";
import {FileInfo} from "../../assets/js/model/FileInfo";
import {PopstateService} from "../../assets/js/service/popstate";
import {ComponentService} from "../../assets/js/service/component";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  @ViewChild("searchInput")
  private searchInput!: ElementRef;

  @ViewChild("groupName")
  private groupName!: ElementRef;

  @ViewChild("sidebar")
  private sidebar!: ElementRef;

  open_state: boolean = false;
  selecting: boolean = false;
  adding: boolean = false;

  users: User[] = [];
  users_save: User[] = [];

  users_selected: User[] = [];

  searchFormGroup = new FormGroup({
    username: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(32)
    ])
  });

  constructor(private componentService: ComponentService, private cdr: ChangeDetectorRef, private popstateService: PopstateService, private dbHandlerService: DBHandlerService, private chatService: ChatService, private authService: AuthService, private homeService: HomeService, private networkService: NetworkService) {
    this.componentService.addComponent(this);
  }

  async open(mode: string = ""): Promise<void> {
    this.popstateService.addState("search", () => {
      this.close();

      this.cdr.detectChanges();

      return true;
    });

    if (mode === "add") {
      this.selecting = true;
      this.adding = true;
    }

    this.sidebar.nativeElement.scrollTop = 0;
    this.searchInput.nativeElement.value = "";

    this.open_state = true;

    if (this.users.length) {
      return;
    }

    const users = await db.connection.select({
      from: "users",
      where: {
        user_id: {
          "!=": this.authService.getUserId()
        }
      }
    }) as User[];

    for (const user of users) {
      await CryptUtils.decryptImage(user, this.authService.getSecretKey()!);
    }

    this.users = users;
  }

  close(): void {
    this.popstateService.removeState("search");

    this.open_state = false;
    this.selecting = false;
    this.adding = false;

    this.users_selected = [];
  }

  toggleUser(user: User): void {
    const index = this.users_selected.findIndex(user_selected => user_selected.user_id === user.user_id);
    if (index !== -1) {
      this.users_selected.splice(index, 1);
      return;
    }

    this.users_selected.push(user);
  }

  async searchAction(): Promise<void> {
    if (!this.searchFormGroup.valid) {
      return;
    }

    const response = await this.networkService.request("GET", "/user/" + this.searchInput.nativeElement.value);
    if (response.data && response.data.length === 1) {
      const user = response.data[0] as User;

      if (user.user_id === this.authService.getUserId()) {
        return;
      }

      this.users.push(user);
      this.users_save.push(user);

      this.dbHandlerService.addAction(async () => {
        await db.connection.insert({
          into: "users",
          values: [user]
        });
      });

      if (!this.selecting) {
        this.createChat([user])
      } else {
        this.users_selected.push(user);
      }
    }
  }

  filterUsers(): void {
    const input = this.searchInput.nativeElement.value;

    if (!input.length) {
      this.users = this.users_save;

      this.users_save = [];

      return;
    }

    if (!this.users_save.length) {
      this.users_save = this.users;
    }

    this.users = this.users_save.filter(user => user.username.includes(input));
  }

  async addUsers(users: User[]) {
    const user_secret_keys = [];

    for (const user of users) {
      const user_public_key = await CryptUtils.getPublicKey(user.public_key as string);
      user_secret_keys.push({
        user_id: user.user_id,
        key: await CryptUtils.encryptSecretKey(await this.chatService.getSecretKey(this.chatService.chat_id), user_public_key),
        type: "rsa"
      });
    }

    const secret_keys = [...user_secret_keys.map(user_secret_key => {
      return {data: user_secret_key};
    })];

    await this.networkService.request("PUT", "/chat/" + this.chatService.chat_id + "/users", JSON.stringify({secret_keys: secret_keys}));

    this.componentService.getHome()?.refresh();
    this.close();
  }

  async createChat(users: User[]) {
    const secret_key = await CryptUtils.generateSecretKey();

    const user_secret_keys = [];
    for (const user of users) {
      const user_public_key = await CryptUtils.getPublicKey(user.public_key as string);
      user_secret_keys.push({
        user_id: user.user_id,
        key: await CryptUtils.encryptSecretKey(secret_key, user_public_key),
        type: "rsa"
      });
    }
    const creator_secret_key = {
      user_id: this.authService.getUserId(),
      key: await CryptUtils.encryptSecretKeySymmetric(secret_key, this.authService.getSecretKey() as CryptoKey),
      type: "aes"
    };

    let name = "";
    if (user_secret_keys.length > 1) {
      name = this.groupName.nativeElement.value;

      if (name.length < 4 || name.length > 32) {
        // TODO: Show error
        return;
      }
    }

    this.close();

    const response = await this.chatService.createChat(user_secret_keys, creator_secret_key, name);

    if (response.data) {
      this.componentService.getChat()?.loadChat(response.data.chat.chat_id);
    }
  }


  protected readonly Tools = Tools;
}
