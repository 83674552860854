<div class="info-sidebar" [class.show]="this.open_state">
  <i class="bi bi-x close" (click)="this.close()"></i>

  <div class="py-5 px-4" *ngIf="this.member">
    <div class="picture ratio ratio-1x1 rounded-circle overflow-hidden mt-3 d-flex w-100">
      <app-profile-picture [member]="this.member"></app-profile-picture>
    </div>
    <div class="username d-flex flex-column w-100 mt-4">
      <form class="m-auto d-flex w-100" (ngSubmit)="this.renameChat()" [formGroup]="chatnameFormGroup">
        <input #chatname tabindex="1" autocomplete="chatname" class="form-control" formControlName="name"
               id="chatname" placeholder="Kevin" type="text" readonly/>
        <i *ngIf="!this.edit_mode" class="bi bi-pencil ms-3 p-2" (click)="this.editMode()"></i>

        <button *ngIf="this.edit_mode" class="bi bi-check ms-3 p-2" type="submit"></button>
        <button *ngIf="this.edit_mode" class="bi bi-x p-2" (click)="this.exitMode()"></button>
      </form>
      <span *ngIf="Tools.checkFormControl(chatnameFormGroup.controls.name, 'minlength')" class="error d-block"> muss mindestens {{ chatnameFormGroup.controls.name.getError('minlength')['requiredLength'] }}
        Zeichen lang sein</span>
      <span *ngIf="Tools.checkFormControl(chatnameFormGroup.controls.name, 'maxlength')" class="error d-block"> darf maximal {{ chatnameFormGroup.controls.name.getError('maxlength')['requiredLength'] }}
        Zeichen lang sein</span>
    </div>
    <hr class="mt-4"/>
    <div class="d-flex flex-column w-100 mt-3 m-auto max-width-450">
      <ng-container *ngIf="!this.member.chat!.is_group else group">
        <button *ngIf="!this.chatService.blocked" class="btn btn-danger mt-4"
                (click)="this.chatService.blockUser(this.chatService.chatId);">Benutzer blockieren
        </button>
        <button *ngIf="this.chatService.blocked" class="btn btn-secondary mt-4"
                (click)="this.chatService.unblockUser(this.chatService.chatId);">Benutzer entsperren
        </button>

        <button class="btn btn-danger mt-4" (click)="this.chatService.deleteChat(this.chatService.chatId)">Chat
          löschen
        </button>
      </ng-container>
      <ng-template #group>
        <div class="members d-flex flex-column w-100 mt-4" *ngIf="this.users && this.users.length">
          @for (user of this.users; track user; let index = $index) {
            <div
              class="list-group-item user-select-none d-flex justify-content-start align-items-center rounded-0 border-start-0 border-end-0 py-3">
              <div class="w-65-px ratio ratio-1x1 rounded-circle overflow-hidden">
                <app-profile-picture [user]="user"></app-profile-picture>
              </div>
              <div class="ms-4 me-4">
                <div class="username fs-7">
                  {{ user.username }}
                </div>
              </div>
            </div>
          }
        </div>
        <ng-container *ngIf="this.member.chat!.creator_id !== this.authService.getUserId() else creator">
          <button class="btn btn-danger mt-4" (click)="this.chatService.leaveChat(this.chatService.chatId);">Gruppe
            verlassen
          </button>
        </ng-container>
        <ng-template #creator>
          <button class="btn btn-danger mt-4" (click)="this.chatService.deleteChat(this.chatService.chatId)">Gruppe
            löschen
          </button>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
