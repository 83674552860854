import {MessageComponent} from "../../../app/template/message/message.component";

/**
 * Status:
 * 0 - pending
 * 1 - sent
 * 2 - received
 * 3 - error
 * 4 - invalid signature
 * 5 - deleted
 * 6 - encrypted
 */
export type Message = {
    message_id: string,
    chat_id: string,
    sender_id: string,
    data: string,
    files?: any,
    signature: string,
    timestamp: number,
    open_timestamp?: number,
    timer: number,
    status: number,
    countdown?: number,
    processed?: boolean,
    seen: boolean,
    component?: MessageComponent
}

export enum MessageStatus {
  PENDING = 0,
  SENT = 1,
  RECEIVED = 2,
  ERROR = 3,
  INVALID_SIGNATURE = 4,
  DELETED = 5,
  ENCRYPTED = 6,
}
