import {Injectable} from "@angular/core";
import {Tools} from "../tools";

@Injectable({
  providedIn: "root"
})
export class PopstateService {
  private lastPop: number = Tools.current_time;

  private states: { [state: string]: () => boolean } = {};

  constructor() {
    window.addEventListener("popstate", this.popStateHandler);
  }

  private popStateHandler = (event: PopStateEvent) => {
    if (Date.now() - this.lastPop < 500) {
      return;
    }

    const states = Object.keys(this.states);

    let state = states[states.length - 1];
    if (event.state && event.state.page && typeof this.states[event.state.page] !== "undefined") {
      state = event.state.page;
    }

    event.preventDefault();

    if (this.states[state]()) {
      window.history.back();
    }

    this.removeState(state);

    this.lastPop = Date.now();
  }

  addState(state: string, callback: () => boolean): void {
    window.history.pushState({page: state}, "");
    window.history.pushState(null, "");

    this.states[state] = callback;
  }

  removeState(state: string): void {
    delete this.states[state];
  }
}
