import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../assets/js/service/auth";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserSettings} from "../../assets/js/model/UserSettings";
import {CryptUtils} from "../../assets/js/crypt_utils";
import {HomeService} from "../../assets/js/service/home";
import {NetworkService} from "../../assets/js/service/network";
import {FileInfo} from "../../assets/js/model/FileInfo";
import {ChatService} from "../../assets/js/service/chat";
import {FileViewerService} from "../../assets/js/service/fileviewer";
import {ImageUtils} from "../../assets/js/image_utils";
import {Tools} from "../../assets/js/tools";
import Swal from "sweetalert2";
import {CustomValidators} from "../../assets/js/validators";

@Component({
  selector: 'app-personalize',
  templateUrl: './personalize.component.html',
  styleUrl: './personalize.component.scss'
})
export class PersonalizeComponent implements OnInit, OnDestroy {
  protected open_state: boolean = false;
  protected edit_mode: boolean = false;
  protected selecting: boolean = false;

  protected settings: UserSettings;
  protected image: FileInfo;

  @ViewChild("sidebar")
  private sidebar!: ElementRef;

  @ViewChild("username")
  private username!: ElementRef;

  userFormGroup = new FormGroup({
    username: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(32)
    ])
  });

  passwordFormGroup = new FormGroup({
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(14),
      CustomValidators.password({"password": true})
    ]),
    password_repeat: new FormControl("", [
      Validators.required,
    ])
  });

  settingsFormGroup = new FormGroup({
    click_to_open: new FormControl(),
    dark_mode: new FormControl()
  });

  constructor(protected authService: AuthService, private fileViewerService: FileViewerService, private homeService: HomeService, private chatService: ChatService, private networkService: NetworkService) {
    this.settings = this.authService.getSettings();
    this.image = this.authService.getImage();

    this.settingsFormGroup.controls.click_to_open.setValue(this.settings.click_to_open ?? false);
    this.settingsFormGroup.controls.dark_mode.setValue(this.settings.dark_mode ?? true);

    this.userFormGroup.controls.username.setValue(this.authService.getUsername());
  }

  ngOnInit() {
    window.addEventListener("popstate", this.popStateHandler);
  }

  ngOnDestroy() {
    window.removeEventListener("popstate", this.popStateHandler);
  }

  private popStateHandler = (event: PopStateEvent) => {
    if (!event.state.page || event.state.page !== "personalize") {
      return;
    }

    if (this.selecting || this.edit_mode) {
      event.preventDefault();

      this.selecting = false;
      this.edit_mode = false;

      this.fileViewerService.removeFile(false);
    } else if (this.open_state) {
      event.preventDefault();

      this.open_state = false;
    } else if (this.fileViewerService.controls) {
      event.preventDefault();
    }

    window.history.back();
  }

  open(): void {
    this.sidebar.nativeElement.scrollTop = 0;

    window.history.pushState({page: "personalize"}, "");
    window.history.pushState(null, "");

    this.open_state = true;
  }

  protected close(): void {
    window.history.back();

    this.open_state = false;
  }

  protected editMode(): void {
    this.username.nativeElement.removeAttribute("readonly");
    this.username.nativeElement.focus();

    this.edit_mode = true;
  }

  protected exitMode(): void {
    this.username.nativeElement.setAttribute("readonly", "true");

    this.edit_mode = false;
  }

  protected async submitSettings(): Promise<void> {
    if (this.settingsFormGroup.valid) {
      const formData = structuredClone(this.settingsFormGroup.value) as UserSettings;

      const settings = await CryptUtils.encryptData(JSON.stringify(formData), this.authService.getSecretKey()!);

      this.networkService.request("PUT", "/auth/account/settings", JSON.stringify({settings: settings})).then(response => {
        if (response.status === "success") {
          this.settings = formData;

          this.authService.setSettings(formData);
          this.homeService.updateTheme();
        }
      });
    }
  }

  protected submitUsername(): void {
    if (this.userFormGroup.valid) {
      const username = this.userFormGroup.controls.username.value!;

      this.networkService.request("PUT", "/auth/account/username", JSON.stringify({username: username})).then(response => {
        if (response.status === "success") {
          this.authService.setUsername(username);
        }
      });

      this.exitMode();
    }
  }

  protected selectImage(): void {
    this.selecting = true;

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg";
    input.onchange = () => this.selectedImage(input);

    input.click();
  }

  protected selectedImage(data: HTMLInputElement): void {
    if (data.files!.length === 1) {
      const image: File = data.files![0];

      if (image.size > 10000000) {
        Tools.showMessage("Sie können maximal ein 10 MB grosses Bild setzen", "error");
        return;
      }

      const name = image.name;

      (new ImageUtils()).resize(image, {width: 200, height: 200}).then(async (image) => {
        const imageInfo: FileInfo = {
          name: name,
          type: image.type,
          size: image.size
        }

        const reader = new FileReader();
        reader.onload = async event => {
          imageInfo.data = event.target!.result as string;

          this.sidebar.nativeElement.style.overflow = "hidden";

          this.fileViewerService.controls = true;
          this.fileViewerService.setFile(imageInfo);

          let subscription;
          await new Promise<void>(resolve => {
            let first = true;

            subscription = this.fileViewerService.accepted.subscribe(async accepted => {
              if (first) {
                first = false;
                return;
              }

              if (accepted) {
                this.image = imageInfo;
                await this.submitImage(imageInfo);
              }

              this.sidebar.nativeElement.style.removeProperty("overflow");

              this.fileViewerService.controls = false;
              this.fileViewerService.removeFile();

              window.history.back();

              resolve();
            });
          });

          // @ts-ignore
          subscription.unsubscribe();
        }

        reader.readAsDataURL(image);
      });
    }
  }

  private async submitImage(image: FileInfo): Promise<void> {
    const encryptedImage = await CryptUtils.encryptData(JSON.stringify(image), this.authService.getSecretKey()!);

    const response = await this.networkService.request("PUT", "/auth/account/picture", JSON.stringify({picture: encryptedImage}));
    if (response.status === "success") {
      this.authService.setImage(image);
      await this.homeService.updatePictures(image);
    }
  }

  protected changePassword(): void {
    Tools.showMessage("Diese Funktion ist noch nicht verfügbar", "info");
  }

  protected clearData(): void {
    this.homeService.members = [];
    this.chatService.messages = {};
    this.chatService.open_message = undefined;
    this.chatService.share_data = null;

    this.authService.clearData();
  }

  protected deleteAccount(): void {
    Swal.fire({
      title: "Konto löschen",
      text: "Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Geben Sie Ihren Benutzernamen ein, um fortzufahren.",
      footer: "Es werden ausnahmslos ALLE Daten gelöscht (auf dem Server und auf dem Gerät). Diese Aktion kann nicht rückgängig gemacht werden!",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Jetzt löschen",
      cancelButtonText: "Nein",
      preConfirm: async (username) => {
        if (username !== this.authService.getUsername()) {
          Swal.showValidationMessage("Der eingegebene Benutzername stimmt nicht mit Ihrem Benutzernamen überein. Sind Sie im richtigen Konto angemeldet?");
          return;
        }

        await this.networkService.request("DELETE", "/auth/account");
        this.clearData();
      }
    });
  }

  protected readonly Tools = Tools;
}
