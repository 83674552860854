import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ChatService} from "../../../assets/js/service/chat";
import {FileInfo} from "../../../assets/js/model/FileInfo";
import {ScreenSizeService} from "../../../assets/js/service/screen";
import {FileViewerService} from "../../../assets/js/service/fileviewer";
import {FileUtils} from "../../../assets/js/file_utils";
import {ComponentService} from "../../../assets/js/service/component";
import {CustomMediaElementComponent} from "../custom-media-element/custom-media-element.component";
import {Tools} from "../../../assets/js/tools";

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrl: './file-viewer.component.scss'
})
export class FileViewerComponent implements AfterViewInit, OnDestroy {
  private initialX: number  = 0;
  private initialY: number = 0;
  private currentScale: number = 1;
  private currentTranslateX: number = 0;
  private currentTranslateY: number = 0;

  private isPinching: boolean = false;
  private initialTranslateX: number = 0;
  private initialTranslateY: number = 0;
  private initialScale: number = 1;
  private initialDistance: number = 0;
  private lastTranslateX: number = 0;
  private lastTranslateY: number = 0;


  private firstTouch: Touch | null = null;

  private doubleTapTimeout: any = null;

  private intersectionObserver?: IntersectionObserver;

  public isVisible: boolean = false;

  @ViewChild("fileViewer", {static: false})
  private elRef!: ElementRef;

  @ViewChild("mediaElement")
  private mediaElement!: CustomMediaElementComponent;

  constructor(private componentService: ComponentService, protected chatService: ChatService, protected fileViewerService: FileViewerService, protected screenSizeService: ScreenSizeService, private cdRef: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.initIntersectionObserver();
  }

  ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  // Handle double tap or double click for zooming in and out
  private handleDoubleTap(target: HTMLElement, clientX: number, clientY: number): void {
    if (this.doubleTapTimeout) {
      clearTimeout(this.doubleTapTimeout);
      this.doubleTapTimeout = null;
    }

    const container = target.parentElement;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    // Calculate tap position relative to the target
    const tapX = clientX - targetRect.left;
    const tapY = clientY - targetRect.top;

    if (this.currentScale === 1) {
      // Zoom in
      const newScale = 2;

      // Calculate translation so the tapped point is centered in the container
      const newTranslateX = containerRect.width / 2 - tapX * newScale;
      const newTranslateY = containerRect.height / 2 - tapY * newScale;

      // Get target's original size
      const targetWidth = target.offsetWidth;
      const targetHeight = target.offsetHeight;

      // Compute scaled dimensions
      const scaledWidth = targetWidth * newScale;
      const scaledHeight = targetHeight * newScale;

      // Clamp translation values
      const clampedX = Math.min(0, Math.max(containerRect.width - scaledWidth, newTranslateX));
      const clampedY = Math.min(0, Math.max(containerRect.height - scaledHeight, newTranslateY));

      // Store values and apply transformation
      this.currentScale = newScale;
      this.currentTranslateX = clampedX;
      this.currentTranslateY = clampedY;

      target.style.transform = `translate(${clampedX}px, ${clampedY}px) scale(${newScale})`;
      target.style.transformOrigin = "top left";
    } else {
      // Zoom out: reset everything
      this.currentScale = 1;
      this.currentTranslateX = 0;
      this.currentTranslateY = 0;

      target.style.transform = "translate(0px, 0px) scale(1)";
      target.style.transformOrigin = "top left";
    }
  }

  // Use evt.currentTarget so we always get the intended element.
  onImageTouch(evt: TouchEvent): void {
    evt.preventDefault();

    // Use currentTarget instead of target.
    const target = evt.currentTarget as HTMLElement;

    if (evt.touches.length === 2) {
      // Pinch-to-zoom: record baseline values.
      this.isPinching = true;
      this.initialDistance = Tools.getDistance(evt.touches[0], evt.touches[1]);
      this.initialScale = this.currentScale;
      this.initialTranslateX = this.currentTranslateX;
      this.initialTranslateY = this.currentTranslateY;

      // Clear any double tap timer.
      clearTimeout(this.doubleTapTimeout);
      this.doubleTapTimeout = null;
    } else if (evt.touches.length === 1) {
      if (this.doubleTapTimeout !== null && this.firstTouch !== null) {
        // A second tap within 300ms: trigger double tap.
        clearTimeout(this.doubleTapTimeout);
        this.doubleTapTimeout = null;
        this.handleDoubleTap(target, this.firstTouch.clientX, this.firstTouch.clientY);
      } else {
        this.firstTouch = evt.touches[0];

        // Start a 300ms timer to detect a double tap.
        this.doubleTapTimeout = setTimeout(() => {
          this.doubleTapTimeout = null;
          this.firstTouch = null;
        }, 300);

        // If we were pinching, switch back to panning.
        if (this.isPinching) {
          this.isPinching = false;
          this.lastTranslateX = this.currentTranslateX;
          this.lastTranslateY = this.currentTranslateY;
        }
        // Set up the pan baseline.
        this.initialX = this.firstTouch.clientX - this.lastTranslateX;
        this.initialY = this.firstTouch.clientY - this.lastTranslateY;
      }
    }
  }

  onImageMove(evt: TouchEvent): void {
    evt.preventDefault();

    const target = evt.target as HTMLElement;

    if (evt.touches.length === 1 && !this.isPinching) {
      // Panning (dragging)
      const newX = evt.touches[0].clientX - this.initialX;
      const newY = evt.touches[0].clientY - this.initialY;
      this.currentTranslateX = newX;
      this.currentTranslateY = newY;
    } else if (evt.touches.length === 2) {
      // Pinch-to-zoom
      const newDistance = Tools.getDistance(evt.touches[0], evt.touches[1]);
      const scaleChange = newDistance / this.initialDistance;
      let newScale = this.initialScale * scaleChange;

      // Optionally clamp the scale.
      const MIN_SCALE = 1;
      const MAX_SCALE = 4;
      newScale = Math.max(MIN_SCALE, Math.min(newScale, MAX_SCALE));

      const scaleFactor = newScale / this.initialScale;

      // Use the window's center as the pivot.
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;

      // Calculate the new translation so that the center remains fixed.
      this.currentTranslateX = (centerX - scaleFactor * (centerX - this.initialTranslateX));
      this.currentTranslateY = centerY - scaleFactor * (centerY - this.initialTranslateY);
      this.currentScale = newScale;
    }

    // Optional: clamp the translation so that the image doesn't go out of bounds.
    const imageWidth = target.offsetWidth;
    const imageHeight = target.offsetHeight;
    const parent = target.parentElement;
    if (parent) {
      const parentWidth = parent.clientWidth;
      const parentHeight = parent.clientHeight;
      const scaledWidth = imageWidth * this.currentScale;
      const scaledHeight = imageHeight * this.currentScale;

      if (scaledWidth >= parentWidth) {
        const minTranslateX = parentWidth - scaledWidth;
        const maxTranslateX = 0;
        this.currentTranslateX = Math.max(minTranslateX, Math.min(this.currentTranslateX, maxTranslateX));
      } else {
        this.currentTranslateX = (parentWidth - scaledWidth) / 2;
      }

      if (scaledHeight >= parentHeight) {
        const minTranslateY = parentHeight - scaledHeight;
        const maxTranslateY = 0;
        this.currentTranslateY = Math.max(minTranslateY, Math.min(this.currentTranslateY, maxTranslateY));
      } else {
        this.currentTranslateY = (parentHeight - scaledHeight) / 2;
      }
    }

    // Apply the transform.
    target.style.transform = `translate(${this.currentTranslateX}px, ${this.currentTranslateY}px) scale(${this.currentScale})`;
    target.style.transformOrigin = "top left";
  }

  onImageEnd(): void {
    // Save the current translation for subsequent pan gestures.
    this.lastTranslateX = this.currentTranslateX;
    this.lastTranslateY = this.currentTranslateY;
  }

  protected previousFile(event: boolean = false): void {
    if (event && this.currentScale !== 1) {
      return;
    }

    if (!this.chatService.open_message?.files || this.chatService.open_message?.files.length === 1) {
      return;
    }

    const currentIndex = this.chatService.open_message?.files.findIndex((file: FileInfo) => file === this.fileViewerService.getFile());
    this.fileViewerService.setFile(this.findPreviousFile(currentIndex, this.chatService.open_message!.files));
  }

  protected findPreviousFile(currentIndex: number, files: FileInfo[]): FileInfo {
    if (currentIndex === 0) {
      for (let i = files.length - 1; i >= 0; i--) {
        if (FileUtils.media_types.includes(files[i].type)) {
          return files[i];
        }
      }
    }

    for (let i = currentIndex - 1; i >= 0; i--) {
      if (FileUtils.media_types.includes(files[i].type)) {
        return files[i];
      }
    }

    return files[currentIndex];
  }

  protected nextFile(event: boolean = false): void {
    if (event && this.currentScale !== 1) {
      return;
    }

    if (!this.chatService.open_message?.files  || this.chatService.open_message?.files.length === 1) {
      return;
    }

    const currentIndex = this.chatService.open_message?.files.findIndex((file: FileInfo) => file === this.fileViewerService.getFile());
    this.fileViewerService.setFile(this.findNextFile(currentIndex, this.chatService.open_message!.files));
  }

  protected findNextFile(currentIndex: number, files: FileInfo[]): FileInfo {
    if (currentIndex === files.length - 1) {
      for (let i = 0; i < files.length; i++) {
        if (FileUtils.media_types.includes(files[i].type)) {
          return files[i];
        }
      }
    }

    for (let i = currentIndex + 1; i < files.length; i++) {
      if (FileUtils.media_types.includes(files[i].type)) {
        return files[i];
      }
    }

    for (let i = 0; i < files.length; i++) {
      if (FileUtils.media_types.includes(files[i].type)) {
        return files[i];
      }
    }

    return files[currentIndex];
  }

  protected async closeFile(event: boolean = false): Promise<void> {
    if (event && this.currentScale !== 1) {
      return;
    }

    if (this.mediaElement && this.mediaElement.playing) {
      await this.mediaElement.playPause();
    }

    setTimeout(() => {
      this.fileViewerService.removeFile();

      this.initialDistance = 0;
      this.initialX = 0;
      this.initialY = 0;

      this.componentService.getChat()?.cdr.detectChanges();
    }, 250);
  }

  private initIntersectionObserver(): void {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === this.elRef.nativeElement) {
          this.isVisible = entry.isIntersecting;
          this.cdRef.detectChanges();
        }
      });
    }, {threshold: [0.1]});

    if (this.elRef && this.elRef.nativeElement) {
      this.intersectionObserver.observe(this.elRef.nativeElement);
    }
  }

  protected readonly FileUtils = FileUtils;
}
