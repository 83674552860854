import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {Tools} from "../../../assets/js/tools";
import jsQR from "jsqr";

interface ExtendedMediaStreamConstraints extends MediaStreamConstraints {
  video: {
    facingMode: "environment" | "user";
    frameRate: {
      ideal: number;
    };
    zoom: boolean;
  };
}

interface ExtendedMediaTrackCapabilities extends MediaTrackCapabilities {
  torch?: boolean;
  zoom?: {
    min: number;
    max: number;
    step: number;
  };
}

interface ExtendedMediaTrackConstraintSet extends MediaTrackConstraintSet {
  torch?: boolean;
  zoom?: {
    min: number;
    max: number;
    step: number;
  };
}

@Component({
  selector: "app-camera",
  templateUrl: "./camera.component.html",
  styleUrl: "./camera.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CameraComponent implements OnInit, OnDestroy {
  @ViewChild("video", {static: false}) videoElement?: ElementRef;
  @ViewChild("canvas", {static: false}) canvasElement?: ElementRef;

  // This event emits a File (image or video) when captured.
  @Output() fileEvent = new EventEmitter<File | null>();
  // This event emits the QR code data (if mode is "qr").
  @Output() qrCodeEvent = new EventEmitter<string | null>();

  // The component is hidden until open() is called.
  isOpen: boolean = false;
  // The mode is determined when opening. Allowed values: "photo", "video", or "qr".
  mode: "photo" | "video" | "qr" = "photo";
  // Always start with the rear camera.
  cameraFacing: "environment" | "user" = "environment";

  stream: MediaStream | null = null;
  mediaRecorder?: MediaRecorder;
  recording: boolean = false;
  chunks: Blob[] = [];
  flashlightEnabled: boolean = false; // new property to track flashlight state
  flashlightSupported: boolean = false;

  private startTime: number = 0;
  recordingTime: string = "";

  qrInterval: any = null;
  timerInterval: any = null;

  zoomSupported: boolean = false;
  currentZoom: number = 1;
  minZoom: number = 1;
  maxZoom: number = 1;

  private initialPinchDistance: number | null = null;
  private initialZoom: number = 1;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    // We do not initialize the camera until open() is called.
  }

  ngOnDestroy() {
    clearInterval(this.qrInterval);
    clearInterval(this.timerInterval);

    this.stopStream();
  }

  /**
   * Call this function to open the camera component.
   * The mode determines the capture behavior.
   */
  async open(mode: "photo" | "video" | "qr") {
    this.mode = mode;
    this.isOpen = true;

    this.cdr.detectChanges();

    await this.initializeCamera();
  }

  /**
   * Initializes the camera with the current cameraFacing and mode.
   * In video mode audio is requested as well.
   */
  async initializeCamera() {
    // Stop any existing stream.
    if (this.stream) {
      this.stopStream();
    }
    const constraints: ExtendedMediaStreamConstraints = {
      video: {
        facingMode: this.cameraFacing,
        frameRate: {
          ideal: 30
        },
        zoom: true
      }
    };
    if (this.mode === "video") {
      constraints.audio = {
        echoCancellation: false,
        noiseSuppression: false,
        autoGainControl: true
      };
    }
    try {
      this.stream = await Tools.getUserMedia(constraints);
      if (this.videoElement && this.videoElement.nativeElement) {
        this.videoElement.nativeElement.srcObject = this.stream;
        this.videoElement.nativeElement.muted = true;
        this.videoElement.nativeElement.play();

        this.videoElement.nativeElement.onplay = () => {
          const videoTrack = this.stream!.getVideoTracks()[0];
          if (!videoTrack) {
            console.warn("No video track found.");
            return;
          }
          const capabilities = videoTrack.getCapabilities() as ExtendedMediaTrackCapabilities;

          this.flashlightSupported = !!capabilities.torch && this.cameraFacing === "environment";
          this.zoomSupported = !!capabilities.zoom;

          // Check and initialize zoom if supported.
          if (this.zoomSupported) {
            // Set minimum, maximum and starting zoom values.
            this.minZoom = capabilities.zoom!.min || 1;
            this.maxZoom = capabilities.zoom!.max || 1;
            this.currentZoom = this.minZoom;
          }

          this.cdr.detectChanges();

          if (this.mode !== "qr") {
            return;
          }

          this.qrInterval = setInterval(() => {
            this.captureQr();
          }, 500);
        };
      }
    } catch (err) {
      console.error("Error accessing media devices.", err);
    }
  }

  /**
   * Switches between rear and front camera.
   */
  async switchCamera() {
    this.cameraFacing = this.cameraFacing === "environment" ? "user" : "environment";
    await this.initializeCamera();
  }

  toggleFlashlight() {
    if (!this.stream) {
      console.warn("No active stream to toggle flashlight.");
      return;
    }
    const videoTrack = this.stream.getVideoTracks()[0];
    if (!videoTrack) {
      console.warn("No video track found.");
      return;
    }
    const capabilities = videoTrack.getCapabilities() as ExtendedMediaTrackCapabilities;
    if (!capabilities.torch) {
      console.warn("Flashlight not supported on this device.");
      return;
    }
    // Toggle the flashlight state.
    this.flashlightEnabled = !this.flashlightEnabled;
    videoTrack.applyConstraints({
      advanced: [{torch: this.flashlightEnabled} as ExtendedMediaTrackConstraintSet]
    }).catch(e => console.error("Error applying constraints for flashlight:", e));
  }


  /**
   * The single capture button performs different actions depending on mode.
   * For "photo" mode it takes a still picture.
   * For "video" mode it toggles start/stop recording.
   * For "qr" mode it takes a picture and attempts to decode a QR code.
   */
  capture() {
    if (this.mode === "photo") {
      this.capturePhoto();
    } else if (this.mode === "video") {
      if (!this.recording) {
        this.startRecording();
      } else {
        this.stopRecording();
      }
    }
  }

  /**
   * Captures a still image from the video stream.
   */
  async capturePhoto(): Promise<void> {
    const video: HTMLVideoElement | undefined = this.videoElement?.nativeElement;
    const canvas: HTMLCanvasElement | undefined = this.canvasElement?.nativeElement;

    if (!video) {
      console.error("No video element found.");
      return;
    }
    if (!canvas) {
      console.error("No canvas element found.");
      return;
    }

    // Set canvas dimensions to match the video.
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.error("Could not get canvas context.");
      return;
    }

    // Draw the current video frame onto the canvas.
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Wrap canvas.toBlob in a promise to use async/await.
    const blob = await new Promise<Blob | null>((resolve) => {
      canvas.toBlob(resolve, "image/jpeg", 0.9);
    });

    if (blob) {
      const file = new File([blob], "photo_" + Tools.current_time + ".jpg", {type: "image/jpeg"});
      this.fileEvent.emit(file);
      // Optionally close the camera after capturing.
      this.close();
    } else {
      console.error("Failed to capture photo blob.");
    }
  }

  /**
   * Starts video recording.
   */
  startRecording() {
    if (!this.stream) {
      return;
    }

    // Get the best available MIME type using your helper function.
    const bestMimeType = Tools.getBestVideoMimeType();
    if (!bestMimeType) {
      Tools.showMessage("Aufnahme nicht möglich: kein unterstützter MIME-Typ", "error");
      return;
    }

    try {
      this.mediaRecorder = new MediaRecorder(this.stream, {mimeType: bestMimeType});

      this.startTime = Tools.current_time;
      // Create an observable that emits every second.
      this.timerInterval = setInterval(() => {
        const totalSeconds = Tools.current_time - this.startTime;
        const seconds = totalSeconds % 60;
        const minutes = Math.floor(totalSeconds / 60) % 60;
        const hours = Math.floor(totalSeconds / 3600);

        const pad = (num: number): string => num.toString().padStart(2, "0");

        if (hours > 0) {
          this.recordingTime = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
        }
        this.recordingTime = `${pad(minutes)}:${pad(seconds)}`;

        this.cdr.detectChanges();
      }, 1000);
    } catch (e) {
      Tools.showMessage("Aufnahme nicht möglich: MediaRecorder Fehler", "error");
      return;
    }

    this.chunks = [];
    this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
      if (event.data && event.data.size > 0) {
        this.chunks.push(event.data);
      }
    };

    this.mediaRecorder.onstop = () => {
      // Create the blob using the same MIME type.
      const blob = new Blob(this.chunks, {type: bestMimeType});

      // Determine the file extension based on the MIME type.
      let extension = "webm";
      if (bestMimeType.indexOf("mp4") !== -1) {
        extension = "mp4";
      }

      const file = new File([blob], "video_" + Tools.current_time + "." + extension, {type: bestMimeType});
      this.fileEvent.emit(file);
      this.recording = false;
      this.close();
    };

    this.mediaRecorder.start();
    this.recording = true;
  }

  /**
   * Stops video recording.
   */
  stopRecording() {
    if (this.mediaRecorder && this.recording) {
      this.mediaRecorder.stop();
    }
  }

  /**
   * Captures an image and uses jsQR to attempt to decode a QR code.
   */
  captureQr() {
    const video: HTMLVideoElement = this.videoElement?.nativeElement;
    const canvas: HTMLCanvasElement = this.canvasElement?.nativeElement;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d")!;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height)!;

    if (code && code.data) {
      this.qrCodeEvent.emit(code.data);
      // Optionally close the camera after a successful QR capture:
      this.close();
    } else {
      console.log("No QR code detected.");
    }
  }

  /**
   * Closes the camera, stops any ongoing streams or recordings, and hides the component.
   */
  close() {
    clearInterval(this.timerInterval);
    clearInterval(this.qrInterval);

    if (this.recording && this.mediaRecorder) {
      this.stopRecording();
    }
    this.stopStream();
    this.isOpen = false;

    this.cdr.detectChanges();
  }

  /**
   * Stops all tracks of the current stream.
   */
  stopStream() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }

  applyZoom(event: Event) {
    const target = event.target as HTMLInputElement;
    this.updateZoom(Number(target.value));
  }

  updateZoom(zoom: number) {
    if (!this.zoomSupported) {
      console.warn("Zoom not supported by this device");
      return;
    }
    if (!this.stream) {
      console.warn("No active stream to apply zoom.");
      return;
    }
    const videoTrack = this.stream.getVideoTracks()[0];
    if (!videoTrack) {
      console.warn("No video track found.");
      return;
    }

    this.currentZoom = zoom;

    videoTrack.applyConstraints({
      advanced: [{ zoom: this.currentZoom }]
    } as ExtendedMediaTrackConstraintSet).catch(e => console.error("Error applying zoom constraints:", e));

    this.cdr.detectChanges();
  }

  handleTouchStart(event: TouchEvent): void {
    if (event.touches.length === 2) {
      this.initialPinchDistance = Tools.getDistance(event.touches[0], event.touches[1]);
      this.initialZoom = this.currentZoom;
    }
  }

  handleTouchMove(event: TouchEvent): void {
    if (event.touches.length === 2 && this.initialPinchDistance !== null) {
      const distance = Tools.getDistance(event.touches[0], event.touches[1]);
      const scale = distance / this.initialPinchDistance;
      this.updateZoom(Math.max(this.minZoom, Math.min(this.maxZoom, this.initialZoom * scale)));
      event.preventDefault(); // Prevent default scrolling behavior
    }
  }

  handleTouchEnd(event: TouchEvent): void {
    if (event.touches.length < 2) {
      this.initialPinchDistance = null;
    }
  }


}
