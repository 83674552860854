import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Tools} from "../../assets/js/tools";
import {User} from "../../assets/js/model/User";
import {HomeService} from "../../assets/js/service/home";
import {NetworkService} from "../../assets/js/service/network";
import {CryptUtils} from "../../assets/js/crypt_utils";
import {AuthService} from "../../assets/js/service/auth";
import {ChatService} from "../../assets/js/service/chat";
import {db} from "../../db/db";
import {DBHandlerService} from "../../assets/js/service/db_handler";
import {FileInfo} from "../../assets/js/model/FileInfo";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild("searchInput")
  private searchInput!: ElementRef;

  @ViewChild("groupName")
  private groupName!: ElementRef;

  @ViewChild("sidebar")
  private sidebar!: ElementRef;

  open_state: boolean = false;
  selecting: boolean = false;

  users: User[] = [];
  users_save: User[] = [];

  users_selected: User[] = [];

  searchFormGroup = new FormGroup({
    username: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(32)
    ])
  });

  constructor(private dbHandlerService: DBHandlerService, private chatService: ChatService, private authService: AuthService, private homeService: HomeService, private networkService: NetworkService) {
  }

  ngOnInit() {
    window.addEventListener("popstate", this.popStateHandler);
  }

  ngOnDestroy() {
    window.removeEventListener("popstate", this.popStateHandler);
  }

  private popStateHandler = (event: PopStateEvent) => {
    if (!event.state.page || event.state.page !== "search") {
      return;
    }

    if (this.open_state) {
      event.preventDefault();

      this.open_state = false;
    }

    window.history.back();
  }

  async open(): Promise<void> {
    this.sidebar.nativeElement.scrollTop = 0;
    this.searchInput.nativeElement.value = "";

    window.history.pushState({page: "search"}, "");
    window.history.pushState(null, "");

    this.open_state = true;

    if (this.users.length) {
      return;
    }

    const users = await db.connection.select({
      from: "users",
      where: {
        user_id: {
          "!=": this.authService.getUserId()
        }
      }
    }) as User[];

    for (const user of users) {
      if (!user.picture || typeof user.picture !== "string") {
        continue;
      }

      const image_data = await CryptUtils.decryptData(user.picture as string, this.authService.getSecretKey()!);
      user.picture = JSON.parse(image_data) as FileInfo;
    }

    this.users = users;
  }

  close(): void {
    window.history.back();

    this.open_state = false;
    this.selecting = false;
  }

  toggleUser(user: User): void {
    const index = this.users_selected.findIndex(user_selected => user_selected.user_id === user.user_id);
    if (index !== -1) {
      this.users_selected.splice(index, 1);
      return;
    }

    this.users_selected.push(user);
  }

  async searchAction(): Promise<void> {
    if (!this.searchFormGroup.valid) {
      return;
    }

    const response = await this.networkService.request("GET", "/user/" + this.searchInput.nativeElement.value);
    if (response.data && response.data.length === 1) {
      const user = response.data[0] as User;

      if (user.user_id === this.authService.getUserId()) {
        return;
      }

      this.users.push(user);
      this.users_save.push(user);

      this.dbHandlerService.addAction(async () => {
        await db.connection.insert({
          into: "users",
          values: [user]
        });
      });

      if (!this.selecting) {
        this.createChat([user])
      } else {
        this.users_selected.push(user);
      }
    }
  }

  filterUsers(): void {
    const input = this.searchInput.nativeElement.value;

    if (!input.length) {
      this.users = this.users_save;

      this.users_save = [];

      return;
    }

    if (!this.users_save.length) {
      this.users_save = this.users;
    }

    this.users = this.users_save.filter(user => user.username.includes(input));
  }

  async createChat(users: User[]) {
    const secret_key = await CryptUtils.generateSecretKey();

    const user_secret_keys = [];
    for (const user of users) {
      const user_public_key = await CryptUtils.getPublicKey(user.public_key as string);
      user_secret_keys.push({
        user_id: user.user_id,
        key: await CryptUtils.encryptSecretKey(secret_key, user_public_key),
        type: "rsa"
      });
    }
    const creator_secret_key = {
      user_id: this.authService.getUserId(),
      key: await CryptUtils.encryptSecretKeySymmetric(secret_key, this.authService.getSecretKey() as CryptoKey),
      type: "aes"
    };

    let name = "";
    if (user_secret_keys.length > 1) {
      name = this.groupName.nativeElement.value;

      if (name.length < 4) {
        // TODO: Show error
        return;
      }
    }

    this.close();

    const response = await this.chatService.createChat(user_secret_keys, creator_secret_key, name);

    if (response.data) {
      this.homeService.loadChatEvent.next(response.data.chat!.chat_id);
    }
  }


  protected readonly Tools = Tools;
}
