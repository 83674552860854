import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import WaveSurfer from "wavesurfer.js";
import {DeviceDetectorService} from "ngx-device-detector";
import {Subscription} from "rxjs";

import {db} from "../../db/db";
import {Response, Tools} from "../../assets/js/tools";

import {ChatService} from "../../assets/js/service/chat";
import {AuthService} from "../../assets/js/service/auth";
import {CryptUtils} from "../../assets/js/crypt_utils";
import {HomeService} from "../../assets/js/service/home";
import {NetworkService} from "../../assets/js/service/network";
import {DBHandlerService} from "../../assets/js/service/db_handler";
import {VisibilityChangeService} from "../../assets/js/service/visibility";
import {FileUtils} from "../../assets/js/file_utils";

import {Member} from "../../assets/js/model/Member";
import {Message, MessageStatus} from "../../assets/js/model/Message";
import {BlockedUser} from "../../assets/js/model/BlockedUser";
import {FileInfo} from "../../assets/js/model/FileInfo";
import {InfoComponent} from "../info/info.component";
import {PopstateService} from "../../assets/js/service/popstate";
import {ComponentService} from "../../assets/js/service/component";
import {CameraComponent} from "../template/camera/camera.component";
import {ContextmenuService} from "../../assets/js/service/contextmenu";
import {FileViewerService} from "../../assets/js/service/fileviewer";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];
  private broadcast = new BroadcastChannel("service-worker");

  private intervals: any = [];
  private timeouts: any = [];

  private is_top: boolean = false;
  private max_previous: boolean = false;
  private max_previous_network: boolean = false;

  private readonly scroll_threshold: number = 500;
  private readonly message_count: number = 20;

  protected selected_files: FileInfo[] = [];
  private file_size: number = 0;

  private processing: boolean = false;
  protected unloading: boolean = false;
  protected loading: boolean = false;
  protected member?: Member;

  @ViewChild("messagesContainer")
  private messagesContainer!: ElementRef;

  @ViewChild("info")
  private info!: InfoComponent;

  @ViewChild("camera")
  private camera!: CameraComponent;

  @ViewChild("fileMenu")
  private fileMenu!: ElementRef;

  @ViewChild("messageInput")
  private message_input!: ElementRef;

  @ViewChild("scrollToBottom")
  private scrollToBottom!: ElementRef;

  @ViewChild("waveform")
  waveform!: ElementRef;

  protected recording: boolean = false;

  private mediaRecorder: MediaRecorder | null = null;
  private chunks: BlobPart[] = [];
  private waveSurfer: WaveSurfer | null = null;

  constructor(private contextmenuService: ContextmenuService, private fileViewerService: FileViewerService, private componentService: ComponentService, private popstateService: PopstateService, public cdr: ChangeDetectorRef, private visibilityService: VisibilityChangeService, private dbHandlerService: DBHandlerService, private route: ActivatedRoute, private networkService: NetworkService, protected authService: AuthService, private homeService: HomeService, protected chatService: ChatService, private deviceService: DeviceDetectorService) {
    this.componentService.addComponent(this);
  }

  trackByMessageId(index: number, message: Message): string {
    return message.message_id
  }

  isSameDay(currentIndex: number): boolean {
    if (currentIndex === 0) return false;

    const currentDate = new Date(this.chatService.sorted_messages[currentIndex].timestamp * 1000);
    const previousDate = new Date(this.chatService.sorted_messages[currentIndex - 1].timestamp * 1000);

    const currentYMD = currentDate.getFullYear().toString() + currentDate.getMonth().toString() + currentDate.getDate().toString();
    const previousYMD = previousDate.getFullYear().toString() + previousDate.getMonth().toString() + previousDate.getDate().toString();

    return currentYMD === previousYMD;
  }

  protected search(): void {
    Tools.showMessage("Diese Funktion ist noch nicht verfügbar", "info");
  }

  protected closeReference(): void {
    this.chatService.reference_message = undefined;

    this.cdr.detectChanges();
  }

  protected onScroll(): void {
    const scrollBottom = this.messagesContainer.nativeElement.scrollHeight - this.messagesContainer.nativeElement.scrollTop - this.messagesContainer.nativeElement.clientHeight;
    const scrollTop = this.messagesContainer.nativeElement.scrollTop;

    this.is_top = scrollTop === 0;

    if (scrollBottom < this.scroll_threshold) {
      this.scrollToBottom.nativeElement.classList.remove("show");
    } else {
      this.scrollToBottom.nativeElement.classList.add("show");
    }

    if (this.processing || this.loading || this.unloading || this.max_previous) {
      return;
    }

    if (scrollTop <= 800) {
      this.processing = true;
      this.is_top = false;

      this.chatService.current_scroll = () => {
      };

      const first_message = this.chatService.first_message;
      this.loadPreviousMessages().then(previousMessage => {
        if (!previousMessage) {
          this.processing = false;
          this.max_previous = true;
          this.max_previous_network = true;

          this.dbHandlerService.addAction(async () => {
            await db.connection.update({
              in: "chats",
              set: {
                max_previous: true
              },
              where: {
                chat_id: this.chatService.chat_id
              }
            }).catch(e => {
            });
          });

          this.cdr.detectChanges();

          return;
        }

        if (!this.is_top) {
          this.processing = false;

          this.cdr.detectChanges();

          return;
        }

        this.scrollToMessage(first_message).then(() => {
          this.processing = false;

          this.cdr.detectChanges();
        });
      });
    }
  }

  ngAfterViewInit() {
    this.scrollToMessage(this.chatService.last_message, true);
  }

  async showReferenceMessage(messageId: string) {
    this.closeReference();

    let message = this.chatService.sorted_messages.find(message => message.message_id === messageId);
    if (!message) {
      const results = await db.connection.select({
        from: "messages",
        where: {
          message_id: messageId
        }
      }) as Message[];

      if (results.length) {
        message = results[0] as Message;
      } else {
        Tools.showMessage("Nachricht nicht lokal gefunden", "error");
        // TODO: Load from server
        return;
      }
    }

    this.chatService.reference_message = message;

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    if (!this.authService.isDecrypted) {
      return;
    }

    this.subscriptions.push(
      this.route.paramMap.subscribe(paramMap => {
        const chat_id = paramMap.get("id")!;

        if (chat_id) {
          this.loadChat(chat_id);
        }
      }),
      this.chatService.chat_id_to_load.subscribe(chat_id => {
        if (chat_id) {
          this.loadChat(chat_id);

          this.chatService.chat_id_to_load.next("");
        }
      })
    );

    this.visibilityService.addHandler("chat", () => {
      if (Tools.isVisible() && "serviceWorker" in navigator) {
        this.cdr.detectChanges();

        this.broadcast.postMessage({
          command: "hideNotification",
          chat_id: this.chatService.chat_id
        });
      }
    });
  }

  processReference(message_id: string) {
    const sup = document.createElement("sup");
    sup.classList.add("reference");
    sup.dataset["message_id"] = message_id;
    sup.contentEditable = "false";
    sup.innerText = "[" + (this.message_input.nativeElement.querySelectorAll("sup").length + 1) + "]";

    const wrapper = document.createElement("span");
    wrapper.classList.add("wrapper");
    wrapper.contentEditable = "true"; // Make the wrapper contenteditable
    wrapper.appendChild(sup);

    // Create separate span elements for zero-width spaces
    const zwnbspSpan = document.createElement("span");
    zwnbspSpan.innerText = "\uFEFF";

    const zwspSpan = document.createElement("span");
    zwspSpan.innerText = "\u200B";

    const range = window.getSelection()!.getRangeAt(0);
    const selection = window.getSelection()!;

    if (
      range.commonAncestorContainer === this.message_input.nativeElement ||
      range.commonAncestorContainer.parentNode === this.message_input.nativeElement
    ) {
      range.insertNode(zwspSpan);  // Insert zero-width space span
      range.insertNode(zwnbspSpan); // Insert zero-width no-break space span
      range.insertNode(wrapper);   // Insert the wrapper span
    } else {
      this.message_input.nativeElement.insertBefore(zwspSpan, this.message_input.nativeElement.lastChild);
      this.message_input.nativeElement.insertBefore(zwnbspSpan, zwspSpan);
      this.message_input.nativeElement.insertBefore(wrapper, zwnbspSpan);
    }

    range.setStartAfter(zwspSpan);
    range.setEndAfter(zwspSpan);
    selection.removeAllRanges();
    selection.addRange(range);

    this.adjustHeightBasedOnRows();
    this.cdr.detectChanges();
  }

  refresh(chat_id: string = this.chatService.chat_id) {
    if (!this.messagesContainer || this.chatService.chat_id !== chat_id) {
      return;
    }

    if (Tools.isVisible() && "serviceWorker" in navigator) {
      this.broadcast.postMessage({
        command: "hideNotification",
        chat_id: chat_id
      });
    }

    this.cdr.detectChanges();

    const scrollBottom = this.messagesContainer.nativeElement.scrollHeight - this.messagesContainer.nativeElement.scrollTop - this.messagesContainer.nativeElement.clientHeight;
    if (scrollBottom < this.scroll_threshold) {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.intervals.forEach((interval: any) => clearInterval(interval));
    this.timeouts.forEach((timeout: any) => clearTimeout(timeout));

    if (!this.loading && !this.unloading) {
      this.unloadChat();
    }
  }

  protected onEnter() {
    if (this.deviceService.isDesktop()) {
      this.enterMessage();
    }
  }

  protected checkScroll() {
    const scrollBottom = this.messagesContainer.nativeElement.scrollHeight - this.messagesContainer.nativeElement.scrollTop - this.messagesContainer.nativeElement.clientHeight;
    if (scrollBottom < this.scroll_threshold) {
      this.chatService.current_scroll(200);
    }
  }

  protected selectFile() {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.onchange = () => this.selectedFile(input);

    input.click();
  }

  protected removeFile(index: number) {
    this.selected_files.splice(index, 1);
  }

  protected dropFiles(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer) {
      this.selectedFile(event.dataTransfer);
    }
  }

  protected keydownEvent(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    if (target.lastChild && target.lastChild.nodeName !== "BR") {
      target.appendChild(document.createElement("br"));
    }

    if (event.key === "Enter") {
      event.preventDefault();

      const range = window.getSelection()!.getRangeAt(0);
      range.deleteContents();

      const br = document.createElement("br");
      range.insertNode(br);

      const div = document.createElement("div");
      range.insertNode(div);

      range.setStartAfter(br);
      range.setEndAfter(br);
      window.getSelection()!.removeAllRanges();
      window.getSelection()!.addRange(range);

      div.scrollIntoView();

      setTimeout(() => {
        div.remove();
      });
    }

    if (event.key === "Backspace" || event.which === 151 || event.which === 229) {
      const range = window.getSelection()!.getRangeAt(0);
      let container = range.commonAncestorContainer as HTMLElement;
      const textContent = container.textContent;

      if (container.nodeType === Node.TEXT_NODE) {
        container = container.parentElement!;
      }

      if (textContent === "\uFEFF") {
        let wrapper = null;
        const previousSibling = container.previousSibling as HTMLElement;
        const previousPreviousSibling = container.previousSibling?.previousSibling as HTMLElement;

        if (previousSibling.classList && previousSibling.classList.contains("wrapper")) {
          wrapper = previousSibling;
        } else if (previousPreviousSibling.classList && previousPreviousSibling.classList.contains("wrapper")) {
          wrapper = previousPreviousSibling;
        }

        if (wrapper) {
          console.log("Removing wrapper");
          wrapper.remove();
        }
      }
    }

    setTimeout(() => {
      this.adjustHeightBasedOnRows();

      this.cdr.detectChanges();
    });
  }

  protected async pasteEvent(event: ClipboardEvent) {
    if (!event.clipboardData) {
      return
    }

    if (event.clipboardData.files.length) {
      event.preventDefault();
      await this.selectedFile(event.clipboardData);
    }

    const clipboardText = event.clipboardData.getData("text");
    if (clipboardText.length) {
      event.preventDefault();

      const selection = window.getSelection();
      if (!selection?.rangeCount) {
        return;
      }

      const range = selection.getRangeAt(0);
      range.deleteContents();

      const textNode = document.createTextNode(clipboardText);
      range.insertNode(textNode);

      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);

      const tempSpan = document.createElement("span");
      range.insertNode(tempSpan);
      tempSpan.scrollIntoView({behavior: "instant", block: "end"});

      tempSpan.parentNode!.removeChild(tempSpan);
    }

    this.adjustHeightBasedOnRows();

    this.cdr.detectChanges();
  }

  private adjustHeightBasedOnRows(): void {
    const contentDiv = this.message_input.nativeElement;

    const lineHeight = 25; // Each row is 25px
    const minRows = 2; // Minimum number of rows
    const maxRows = 4; // Maximum number of rows

    contentDiv.style.overflowY = "hidden";
    contentDiv.style.height = `${lineHeight * minRows}px`;

    const scrollHeight = contentDiv.scrollHeight;

    let numberOfRows = Math.ceil(scrollHeight / lineHeight);

    if (numberOfRows > maxRows) {
      numberOfRows = maxRows;
      contentDiv.style.overflowY = "auto";
    } else {
      contentDiv.style.overflowY = "hidden";
    }

    const newHeight = lineHeight * numberOfRows;
    contentDiv.style.height = `${newHeight}px`;

    this.chatService.current_scroll();
  }

  private async selectedFile(data: HTMLInputElement | DataTransfer) {
    if (!data.files || !data.files.length) {
      return;
    }

    for (let i = 0; i < data.files.length; i++) {
      this.processFile(data.files[i] as File);
    }
  }

  private processFile(file: File) {
    if (this.file_size + file.size > 100000000) {
      Tools.showMessage("Sie können maximal 100 MB an Dateien pro Nachricht senden", "error");
      return;
    }

    this.file_size += file.size;

    const fileInfo: FileInfo = {
      name: file.name,
      type: file.type,
      size: file.size
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      this.fileMenu.nativeElement.classList.remove("show");

      fileInfo.data = event.target!.result as string;

      this.selected_files.push(fileInfo);
      this.scrollToMessage(this.chatService.last_message, true);
    }
    reader.readAsDataURL(file);
  }

  protected async startRecording() {
    this.waveSurfer = WaveSurfer.create({
      container: this.waveform.nativeElement,
      waveColor: "#0d6efd",
      progressColor: "#ffffff",
      height: 25
    });

    const bestMimeType = Tools.getBestAudioMimeType();
    if (!bestMimeType) {
      Tools.showMessage("Aufnahme nicht möglich: kein unterstützter MIME-Typ", "error");
      return;
    }

    const start_time = Tools.current_time;

    try {
      const stream = await Tools.getUserMedia({audio: {
          echoCancellation: false,
          noiseSuppression: false,
          autoGainControl: true
        }});

      this.mediaRecorder = new MediaRecorder(stream, { mimeType: bestMimeType });

      this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
        this.chunks.push(event.data);

        this.waveSurfer?.loadBlob(new Blob(this.chunks, {type: event.data.type}));

        this.cdr.detectChanges();
      }

      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.chunks, {type: bestMimeType});

        let extension = "webm";
        if (bestMimeType.indexOf("mp4") !== -1) {
          extension = "mp4";
        } else if (bestMimeType.indexOf("ogg") !== -1) {
          extension = "ogg";
        }

        const fileInfo = {
          name: "recording_" + Tools.current_time + "." + extension,
          type: bestMimeType,
          size: audioBlob.size,
          duration: Tools.current_time - start_time,
          voice: true
        } as FileInfo;

        const reader = new FileReader();
        reader.onloadend = (event) => {
          fileInfo.data = event.target!.result as string;

          this.selected_files.push(fileInfo);
          this.scrollToMessage(this.chatService.last_message, true);
        };

        reader.readAsDataURL(audioBlob);

        this.waveSurfer?.empty();
        this.waveSurfer?.destroy();
        this.waveSurfer = null;

        if (this.mediaRecorder) {
        this.mediaRecorder.ondataavailable = null;
        this.mediaRecorder.onstop = null;
        this.mediaRecorder = null;
      }
      this.chunks = [];

      this.cdr.detectChanges();
    }

      this.chunks = [];

      this.mediaRecorder.start(200);
      this.recording = true;

      this.cdr.detectChanges();
    } catch (error) {
      Tools.showMessage("Kein Zugriff auf das Mikrofon möglich", "error");
    }
  }

  protected stopRecording() {
    if (!this.mediaRecorder) {
      return;
    }

    this.mediaRecorder.stop();
    this.mediaRecorder.stream.getTracks().forEach(track => track.stop())

    this.recording = false;

    this.cdr.detectChanges();
  }

  protected async enterMessage() {
    const messageInput = this.message_input.nativeElement;
    const value = messageInput.innerHTML.trim();

    if (value.length || this.selected_files.length) {
      messageInput.innerHTML = "";
      messageInput.focus();

      this.adjustHeightBasedOnRows();

      let encryptedFiles = "";
      if (this.selected_files.length > 0) {
        encryptedFiles = await CryptUtils.encryptData(JSON.stringify(this.selected_files), await this.chatService.getSecretKey());

        this.selected_files = [];
        this.file_size = 0;
      }

      const data = await CryptUtils.encryptData(value, await this.chatService.getSecretKey());
      const message_id = Tools.generateUUID();
      const message = {
        message_id: message_id,
        sender_id: this.authService.getUserId()!,
        chat_id: this.chatService.chat_id,
        data: data,
        files: encryptedFiles,
        signature: await CryptUtils.signData(data, this.authService.getSignPrivateKey()!),
        timestamp: Tools.current_time,
        open_timestamp: Tools.current_time,
        timer: Number(this.chatService.settings?.timer),
        status: MessageStatus.PENDING,
        seen: true
      } as Message;

      this.chatService.sendMessage(message).then(() => {
        this.cdr.detectChanges();
        this.scrollToMessage(message, true);
      });

      this.cdr.detectChanges();
      this.scrollToMessage(message, true);

      this.chatService.unsent_messages[this.chatService.chat_id] = {};
    }
  }

  async loadChat(chat_id: string) {
    if (this.chatService.chat_id && this.chatService.chat_id !== chat_id) {
      this.unloadChat();
    }

    if (!chat_id || this.loading || this.unloading) {
      return;
    }

    this.popstateService.addState("chat", () => {
      this.unloadChat();

      this.cdr.detectChanges();

      return true;
    });

    this.loading = true;
    this.member = await this.homeService.getMember(chat_id);

    this.chatService.settings = this.member.chat!.settings;
    this.chatService.chat_id = chat_id;

    this.chatService.is_group = this.member.chat!.is_group;

    await this.chatService.loadSettings();

    this.cdr.detectChanges();

    this.subscriptions.push(
      this.camera.fileEvent.subscribe(file => {
        if (file) {
          this.processFile(file);
          this.camera.fileEvent.next(null);
        }
      })
    );

    if (!this.member.chat!.is_group) {
      const blocked_entry = ((await db.connection.select({
        from: "blocked_users",
        where: {
          block_id: this.member.user!.user_id!
        }
      }))[0] ?? {}) as BlockedUser;

      if (blocked_entry.block_id) {
        this.chatService.blocked = true;
      }
    }

    if ("serviceWorker" in navigator) {
      this.broadcast.postMessage({
        command: "hideNotification",
        chat_id: chat_id
      });
    }

    this.max_previous_network = this.member!.chat!.max_previous;

    if (!this.loading) {
      return;
    }

    const message_input = this.message_input.nativeElement;

    if (typeof this.chatService.unsent_messages[chat_id] !== "undefined") {
      message_input.innerHTML = this.chatService.unsent_messages[chat_id].message ?? "";
      this.selected_files = this.chatService.unsent_messages[chat_id].files ?? [];
    }

    if (this.chatService.share_data) {
      message_input.innerText += this.chatService.share_data.title;
      message_input.innerText += this.chatService.share_data.text;
      message_input.innerText += this.chatService.share_data.url;

      this.chatService.share_data = null;
    }

    if (!this.loading) {
      return;
    }

    this.adjustHeightBasedOnRows();

    this.loadMessages().then(async () => {
      await this.chatService.getSecretKey(chat_id);

      this.chatService.chat_id = chat_id;
      this.loading = false;

      await this.scrollToMessage(this.chatService.last_message, true);

      this.cdr.detectChanges();
    });
  }

  unloadChat() {
    this.loading = false;
    this.max_previous = false;
    this.max_previous_network = false;

    this.unloading = true;

    this.member = undefined;

    if (this.message_input) {
      this.chatService.unsent_messages[this.chatService.chat_id] = {
        message: this.message_input.nativeElement.innerHTML,
        files: this.selected_files
      };

      this.message_input.nativeElement.innerHTML = "";
    }

    this.chatService.messages[this.chatService.chat_id] = this.chatService.sorted_messages.slice(-this.message_count);
    this.chatService.reference_message = undefined;
    this.chatService.is_group = false;

    this.selected_files = [];
    this.chatService.chat_id = "";

    this.popstateService.removeState("chat");

    this.info.close();
    this.componentService.getHome()?.loadMessageBadges();

    this.unloading = false;

    this.cdr.detectChanges();
  }

  private async loadMessages() {
    if (this.chatService.message_count < this.message_count) {
      let where = {
        chat_id: this.chatService.chat_id,
      } as { chat_id: string, timestamp?: any };

      if (this.chatService.first_message) {
        where.timestamp = {
          "<": this.chatService.first_message.timestamp
        };
      }

      const messages = await db.connection.select({
        from: "messages",
        where: where,
        order: {
          by: "timestamp",
          type: "desc"
        },
        limit: this.message_count - this.chatService.message_count
      }) as Message[];

      this.chatService.addMessages(messages);
      this.scrollToMessage(this.chatService.last_message, true);
    }
  }

  private async loadPreviousMessages(): Promise<boolean> {
    let messages = await db.connection.select({
      from: "messages",
      where: {
        chat_id: this.chatService.chat_id,
        timestamp: {
          "<": this.chatService.first_message.timestamp
        }
      },
      order: {
        by: "timestamp",
        type: "desc"
      },
      limit: this.message_count
    }) as Message[];

    if (this.unloading || !this.chatService.chat_id) {
      return false;
    }

    if (!messages.length) {
      if (this.max_previous_network) {
        return false;
      }

      const response = await this.networkService.request("GET", "/chat/" + this.chatService.chat_id + "/messages/" + this.chatService.first_message.message_id + "/previous");
      return await this.processResponse(response);
    } else {
      this.chatService.addMessages(messages);
      return true;
    }
  }

  private async processResponse(response: Response): Promise<boolean> {
    if (response.data) {
      const messages = response.data as Message[];

      await this.chatService.handleMessages(messages);
      this.chatService.addMessages(messages);

      return true;
    }

    return false;
  }

  protected scrollToMessage(message: Message, end: boolean = false, timeout: number = 0): Promise<boolean> {
    this.cdr.detectChanges();

    return new Promise<boolean>(resolve => {
      if (!message || !this.messagesContainer) {
        resolve(false);

        return;
      }

      this.chatService.current_scroll = (timeoutOverride = timeout, attemptsLeft = 10) => {
        setTimeout(() => {
          if (!this.messagesContainer) {
            return;
          }

          const scrollableDivEl = this.messagesContainer.nativeElement;
          const messageElementEl = scrollableDivEl.querySelector(`[data-id="${message.message_id}"]`);

          if (!messageElementEl) {
            if (attemptsLeft > 0) {
              setTimeout(() => this.chatService.current_scroll(timeoutOverride, attemptsLeft - 1), 50);
            } else {
              resolve(false);
            }
            return;
          }

          let scrollOffset = messageElementEl.offsetTop;
          if (end) {
            scrollOffset += messageElementEl.offsetHeight;
          }
          scrollableDivEl.scrollTop = scrollOffset;

          resolve(true);
        }, timeoutOverride);
      };

      this.chatService.current_scroll();
    });
  }

  protected openFile(index: number): void {
    if (this.contextmenuService.element?.visible) {
      return;
    }

    this.popstateService.addState("fileviewer", () => {
      this.fileViewerService.removeFile();
      this.chatService.open_message = undefined;

      this.componentService.getChat()?.cdr.detectChanges();

      return true;
    });

    this.fileViewerService.setFile(this.selected_files[index]);
    this.chatService.open_message = {
      files: this.selected_files
    } as Message;
  }

  protected readonly FileUtils = FileUtils;
}
