import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {AuthService} from "../../assets/js/service/auth";
import {Tools} from "../../assets/js/tools";
import {ScreenSizeService} from "../../assets/js/service/screen";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements AfterViewInit {
  protected readonly Tools = Tools;

  @ViewChild("toggler")
  private toggler!: ElementRef;

  constructor(protected authService: AuthService, protected screenService: ScreenSizeService) {
  }

  onNavClick(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains("dropdown-toggle") &&
      !this.screenService.isBigScreen() &&
      (target.classList.contains("dropdown-item") ||
      target.classList.contains("nav-item") ||
      target.classList.contains("nav-link"))) {
      this.toggler.nativeElement.click();
    }
  }

  /**
   * Lifecycle-Methode, die nach der Initialisierung der Ansicht aufgerufen wird.
   * Fügt einen Event-Listener für die Navigations-Elemente hinzu.
   */
  ngAfterViewInit() {
    let navLinks = document.querySelectorAll(".navbar .navbar-nav [href]");
    for (let navLink of Object.values(navLinks)) {
      if (navLink.getAttribute("listener") !== "true") {
        navLink.setAttribute("listener", "true");

        navLink.addEventListener("click", (e) => {
          e.preventDefault();

          let navbarCollapse = document.querySelector(".navbar-collapse")!;
          if (navbarCollapse.classList.contains("show")) {
            let navClose = document.querySelector(".navbar-toggler")! as HTMLButtonElement;
            navClose.click();
          }
        });
      }
    }
  }
}
