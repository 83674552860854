// status-icon.pipe.ts
import {Pipe, PipeTransform} from '@angular/core';
import {FileUtils} from "../file_utils";

@Pipe({name: "checkXSS"})
export class XSSCheckPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || !FileUtils.isValidMediaType(value.slice(0, 100)) || !value.startsWith("data:")) {
      return "";
    }

    return value;
  }
}
