import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Message, MessageStatus} from "../model/Message";
import {AuthService} from "./auth";
import {Tools} from "../tools";
import {NetworkService} from "./network";
import {db} from "../../../db/db";
import {Member} from "../model/Member";
import {HomeService} from "./home";
import {CryptUtils} from "../crypt_utils";
import {DBHandlerService} from "./db_handler";
import {SecretKey} from "../model/SecretKey";
import {User} from "../model/User";
import Swal from "sweetalert2";
import {BlockedUser} from "../model/BlockedUser";

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  chatId = "";
  blocked = false;

  updateEvent = new BehaviorSubject<boolean>(false);
  unloadEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  referenceEvent = new BehaviorSubject<string>("");
  referenceClickEvent = new BehaviorSubject<string>("");

  reference_message?: Message;
  references_messages: { [message_id: string]: any[] } = {};

  open_message?: Message;
  messages: { [chat_id: string]: Message[] } = {};
  unsent_messages: { [chat_id: string]: any } = {};

  secret_keys: { [chat_id: string]: CryptoKey } = {};
  sign_public_keys: { [user_id: string]: CryptoKey } = {};

  is_group: boolean = false;

  share_data: any = null;
  current_scroll: any = () => {};

  constructor(private dbHandlerService: DBHandlerService, private authService: AuthService, private networkService: NetworkService, private homeService: HomeService) {
  }

  async getSecretKey(chat_id: string = this.chatId): Promise<CryptoKey> {
    if (typeof this.secret_keys[chat_id] !== "undefined") {
      return this.secret_keys[chat_id];
    }

    const secret_entry = await this.findSecretKey(chat_id);
    const secret_key = await CryptUtils.decryptSecretKeySymmetric(secret_entry.secret_key, this.authService.getSecretKey()!) as CryptoKey;

    this.secret_keys[chat_id] = secret_key;

    return secret_key;
  }

  async getSignPublicKey(user_id: string): Promise<CryptoKey> {
    if (typeof this.sign_public_keys[user_id] !== "undefined") {
      return this.sign_public_keys[user_id];
    }

    let users = await db.connection.select({
      from: "users",
      where: {
        user_id: user_id
      }
    });

    if (!users.length) {
      const response = await this.networkService.request("GET", "/user/info/" + user_id);
      if (response.data) {
        users = [response.data as User];
        await this.homeService.processUser(response.data);
      }
    }

    const sign_public_key = await CryptUtils.importSignPublicKey(users[0]!.sign_public_key as string);

    this.sign_public_keys[user_id] = sign_public_key;

    return sign_public_key;
  }

  private async findSecretKey(chatId: string): Promise<SecretKey> {
    let secret_entry = ((await db.connection.select({
      from: "secret_keys",
      where: {
        chat_id: chatId,
        user_id: this.authService.getUserId()
      }
    }))[0] ?? {}) as SecretKey;

    if (!secret_entry.secret_id) {
      const response = await this.networkService.request("GET", "/chat/" + chatId + "/key");
      if (response.data) {
        secret_entry = response.data as SecretKey;

        if (response.data.type === "rsa") {
          secret_entry.secret_key = await CryptUtils.encryptSecretKeySymmetric(
            await CryptUtils.decryptSecretKey(secret_entry.secret_key as string, this.authService.getPrivateKey() as CryptoKey) as CryptoKey,
            this.authService.getSecretKey() as CryptoKey
          );

          this.networkService.request("POST", "/chat/" + chatId + "/key", JSON.stringify(secret_entry));
        }

        await db.connection.count({
          from: "secret_keys",
          where: {
            secret_id: secret_entry.secret_id!,
            user_id: secret_entry.user_id
          }
        }).then(count => {
          if (count === 0) {
            this.dbHandlerService.addAction(async () => {
              await db.connection.insert({
                into: "secret_keys",
                values: [secret_entry]
              }).catch(e => {
              });
            });
          }
        });
      }
    }

    return secret_entry;
  }

  async createChat(user_secret_keys: object[], creator_secret_key: object, name: string = "", chat_id: string = "", settings: any = {timer: 0}) {
    const chat = {
      chat_id: chat_id,
      secret_keys: [
        ...user_secret_keys.map(user_secret_key => {
          return {data: user_secret_key};
        }),
        {
          data: creator_secret_key,
        }
      ],
      is_group: user_secret_keys.length > 1,
      settings: settings
    } as any;

    if (chat.is_group) {
      chat.name = name;
    }

    return this.networkService.request("POST", "/chat", JSON.stringify(chat));
  }

  leaveChat(chat_id: string) {
    Swal.fire({
      title: "Sind Sie sicher?",
      text: "Sie können den Chat selbst nicht mehr betreten",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Verlassen",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        this.networkService.request("DELETE", "/chat/" + chat_id + "/user/" + this.authService.getUserId());

        const showError = () => {
          Swal.fire({
            title: "Fehler",
            text: "Beim Verlassen des Chats ist ein Fehler aufgetreten",
            icon: "error"
          });
        }

        this.deleteChatData(chat_id, showError).then(() => {
          if (this.chatId === chat_id) {
            this.unloadEvent.next(true);
          }
        });
      }
    });
  }

  async unblockUser(chatId: string) {
    const member = await this.homeService.getMember(chatId);
    db.connection.select({
      from: "blocked_users",
      where: {
        block_id: member.user_id!
      }
    }).then((results) => {
      if (results.length) {
        const blockedUser = results[0] as BlockedUser;
        this.networkService.request("DELETE", "/user/block/" + blockedUser.block_id).then(async () => {
          this.dbHandlerService.addAction(async () => {
            await db.connection.remove({
              from: "blocked_users",
              where: {
                block_id: blockedUser.block_id
              }
            }).catch(e => {
            });
          });

          if (this.chatId === chatId) {
            this.blocked = false;
          }
        });
      }
    });

  }

  blockUser(chatId: string) {
    Swal.fire({
      title: "Sind Sie sicher?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Blockieren",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const showError = () => {
          Swal.fire({
            title: "Fehler",
            text: "Beim blockieren vom Benutzer ist ein Fehler aufgetreten",
            icon: "error"
          });
        }

        const member = await this.homeService.getMember(chatId);

        const blockedUser: BlockedUser = {block_id: member.user_id!};
        this.networkService.request("POST", "/user/block", JSON.stringify(blockedUser)).then(async response => {
          if (response.data) {
            blockedUser.entry_id = response.data.entry_id;

            this.dbHandlerService.addAction(async () => {
              await db.connection.insert({
                into: "blocked_users",
                values: [blockedUser]
              }).catch(() => showError());
            });

            if (this.chatId === chatId) {
              this.blocked = true;
            }
          } else {
            showError();
          }
        });
      }
    });
  }

  deleteChat(chat_id: string) {
    Swal.fire({
      title: "Sind Sie sicher?",
      text: "Der Chat wird unwiderruflich gelöscht",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Löschen",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        this.networkService.request("DELETE", "/chat/" + chat_id);

        const showError = () => {
          Swal.fire({
            title: "Fehler",
            text: "Beim Löschen des Chats ist ein Fehler aufgetreten",
            icon: "error"
          });
        }

        this.deleteChatData(chat_id, showError).then(() => {
          if (this.chatId === chat_id) {
            this.unloadEvent.next(true);
          }
        });
      }
    });
  }

  private async deleteChatData(chat_id: string, showError: () => void): Promise<void>
  {
    await db.connection.remove({
      from: "members",
      where: {
        chat_id: chat_id,
      }
    }).catch(() => showError());

    await db.connection.remove({
      from: "messages",
      where: {
        chat_id: chat_id,
      }
    }).catch(() => showError());

    await db.connection.remove({
      from: "secret_keys",
      where: {
        chat_id: chat_id,
      }
    }).catch(() => showError());

    await db.connection.remove({
      from: "chats",
      where: {
        chat_id: chat_id,
      }
    }).then(() => {
      const index = this.homeService.members.findIndex(member => member.chat!.chat_id === chat_id);
      this.homeService.members.splice(index, 1);
    }).catch(() => showError());
  }

  private async recreateChat(chat_id: string): Promise<void> {
    const member = await this.homeService.getMember(chat_id);

    const user_public_key = await CryptUtils.getPublicKey(member.user!.public_key as string);
    const user_secret_key = {
      user_id: member.user!.user_id,
      key: await CryptUtils.encryptSecretKey(await this.getSecretKey(chat_id), user_public_key),
      type: "rsa"
    };
    const creator_secret_key = {
      user_id: this.authService.getUserId(),
      key: await CryptUtils.encryptSecretKeySymmetric(await this.getSecretKey(chat_id), this.authService.getSecretKey() as CryptoKey),
      type: "aes"
    };

    await this.createChat([user_secret_key], creator_secret_key, member.chat!.name, this.chatId, member.chat!.settings);

  }

  async sendMessage(message: Message, retry: boolean = false): Promise<void> {
    this.addMessage(message);

    const response = await this.networkService.request("POST", "/message", JSON.stringify(message));
    this.removeMessage(message, false);

    if (response.data) {
      message.message_id = response.data.message_id;
      message.status = MessageStatus.RECEIVED;
    } else if (response.code === 404 && !retry) {
      await this.recreateChat(message.chat_id);
      await this.sendMessage(message, true);
      return;
    } else if (response.code === 0 && !Tools.isVisible()) {
      return;
    } else {
      message.status = MessageStatus.ERROR;
    }

    this.insertMessage(message);

    this.homeService.updateTimestamp(await this.homeService.getMember(message.chat_id), message.timestamp);
  }

  async sentMessages(messages: Message[]): Promise<void> {
      for (const message of messages) {
        this.removeMessage(message);
      }

      for (const message of messages) {
        message.status = MessageStatus.RECEIVED;

        this.insertMessage(message);

        this.homeService.updateTimestamp(await this.homeService.getMember(message.chat_id), message.timestamp);
      }
  }

  handleMessage(message: Message, member: Member): void {
    if (message.sender_id === this.authService.getUserId()) {
      message.status = MessageStatus.RECEIVED;
      message.open_timestamp = Tools.current_time;
      message.seen = true;
    } else if ((member.chat!.settings.click_to_open || this.authService.getSettings().click_to_open) && message.sender_id !== "system") {
      message.status = MessageStatus.ENCRYPTED;
      message.seen = false;
    } else if (message.chat_id === this.chatId) {
      message.status = MessageStatus.RECEIVED;
      message.open_timestamp = Tools.current_time;
      message.seen = true;
    } else {
      message.status = MessageStatus.RECEIVED;
      message.seen = false;
    }

    if (member && (typeof member.timestamp === "undefined" || member.timestamp < message.timestamp)) {
        this.homeService.updateTimestamp(member, message.timestamp);
    }

    this.dbHandlerService.addAction(async () => {
      await db.connection.remove({
        from: "messages",
        where: {
          message_id: message.message_id!
        }
      }).catch(e => {
      });
    });

    this.dbHandlerService.addAction(async () => {
      await db.connection.insert({
        into: "messages",
        values: [message],
      }).catch(e => {
      });
    });

    if (message.sender_id !== this.authService.getUserId()) {
      return;
    }

    this.dbHandlerService.addAction(async () => {
      await db.connection.update({
        in: "messages",
        set: {
          seen: true
        },
        where: {
          chat_id: message.chat_id,
          timestamp: {
            "<": message.timestamp
          }
        }
      }).catch(e => {
      });
    });
  }

  async handleMessages(messages: Message[]): Promise<void> {
    for (const message of messages.sort( (a, b) => a.timestamp - b.timestamp)) {
      const member = await this.homeService.getMember(message.chat_id);
      this.handleMessage(message, member);
    }
  }

  addMessage(message: Message): boolean {
    if (typeof this.messages[message.chat_id] === "undefined") {
      this.messages[message.chat_id] = [];
    }

    if (typeof this.references_messages[message.message_id!] === "undefined") {
      this.references_messages[message.message_id!] = [];
    }

    if (this.messages[message.chat_id].findIndex(m => m.message_id === message.message_id) !== -1) {
      return false;
    }

    this.messages[message.chat_id].push(Object.assign({}, message));

    if (message.chat_id === this.chatId) {
      this.updateEvent.next(true);
    }

    return true;
  }

  addMessages(messages: Message[]): void {
    messages.map(message => this.addMessage(message));
  }

  insertMessage(message: Message): void {
    this.addMessage(message);

    this.dbHandlerService.addAction(async () => {
      await db.connection.remove({
        from: "messages",
        where: {
          message_id: message.message_id!
        }
      }).catch(e => {
      });
    });

    this.dbHandlerService.addAction(async () => {
      await db.connection.insert({
        into: "messages",
        values: [message],
      }).catch(e => {
      });
    });
  }

  removeMessage(message: Message, database: boolean = true): void {
    if (database) {
      this.dbHandlerService.addAction(async () => {
        await db.connection.remove({
          from: "messages",
          where: {
            message_id: message.message_id!
          }
        }).catch(e => {
        });
      });
    }

    const index = this.messages[message.chat_id].findIndex(m => m.message_id === message.message_id);
    if (index === -1) {
      return;
    }

    this.messages[message.chat_id].splice(index, 1);
  }

  get sorted_messages(): Message[] {
    if (this.chatId === "" || !this.messages[this.chatId]) {
      return [];
    }

    return [...this.messages[this.chatId]].sort((a, b) => a.timestamp - b.timestamp).filter(m => m.status !== MessageStatus.DELETED);
  }

  get message_count(): number {
    return this.sorted_messages.length;
  }

  get first_message(): Message {
    return this.sorted_messages[0] ?? null;
  }

  get last_message(): Message {
    return this.sorted_messages[this.sorted_messages.length - 1] ?? null;
  }
}
