export class FileUtils {
  static get all_types() {
    return [...this.image_types, ...this.video_types, ...this.audio_types, ...this.document_types];
  }

  static get media_types() {
    return [...this.image_types, ...this.video_types, ...this.audio_types];
  }

  static createRegex() {
    const types = this.media_types.map(type => "data:" + type.replace("/", "\\/"));
    return new RegExp(`^(${types.join('|')}).*`);
  }

  static isValidMediaType(input: string) {
    const regex = FileUtils.createRegex();
    return regex.test(input);
  }

  // Supported image types for the image tag
  static image_types = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/svg+xml"
  ];

  // Supported video types for the video tag
  static video_types = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/mp4; codecs=avc1",
    "video/webm; codecs=vp9",
    "video/webm; codecs=vp8",
    "video/ogg; codecs=theora"
  ];

  // Supported audio types for the audio tag
  static audio_types = [
    "audio/mpeg",
    "audio/aac",
    "audio/ogg", // For Ogg Vorbis
    "audio/wav",
    "audio/webm",
    "audio/mp4",
    "audio/webm; codecs=opus",
    "audio/ogg; codecs=opus"
  ];

  // Supported document types for sharing in the chat
  static document_types = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/zip",
    "application/x-rar-compressed",
    "application/x-zip-compressed"
  ];

  static icon_list: any = {
    ...{
      "file": "assets/img/file-light.png",
      "application/pdf": "assets/img/pdf-light.png",
      "application/msword": "assets/img/word-light.png",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "assets/img/word-light.png",
      "application/vnd.ms-excel": "assets/img/excel-light.png",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "assets/img/excel-light.png",
      "application/vnd.ms-powerpoint": "assets/img/powerpoint-light.png",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": "assets/img/powerpoint-light.png",
      "application/zip": "assets/img/zip-light.png",
      "application/x-zip-compressed": "assets/img/zip-light.png",
      "application/x-rar-compressed": "assets/img/rar-light.png"
    },
    ...this.image_types.reduce((acc, type) => ({...acc, [type]: "assets/img/image-light.png"}), {}),
    ...this.video_types.reduce((acc, type) => ({...acc, [type]: "assets/img/video-light.png"}), {}),
    ...this.audio_types.reduce((acc, type) => ({...acc, [type]: "assets/img/audio-light.png"}), {}),
  };
}
