import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {HomeService} from "../../assets/js/service/home";
import {ChatService} from "../../assets/js/service/chat";
import {Member} from "../../assets/js/model/Member";
import {AuthService} from "../../assets/js/service/auth";
import {PopstateService} from "../../assets/js/service/popstate";
import {User} from "../../assets/js/model/User";
import {db} from "../../db/db";
import {Tools} from "../../assets/js/tools";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NetworkService} from "../../assets/js/service/network";
import Swal from "sweetalert2";
import {MenuItem} from "../../assets/js/interface/menu_item";
import {Chat} from "../../assets/js/model/Chat";
import {ComponentService} from "../../assets/js/service/component";
import {ChatSettings} from "../../assets/js/model/ChatSettings";

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss'
})
export class InfoComponent {
  open_state: boolean = false;
  is_creator: boolean = false;

  edit_mode: boolean = false;

  chatnameFormGroup = new FormGroup({
    name: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(32)
    ])
  });

  contextMenuItems: MenuItem[] = [
    /*{
      label: "Adminrechte vergeben",
      action: (user_id, chat_id) => this.networkService.request("PUT", "/chat/" + chat_id + "/user/" + user_id + "/admin"),
      condition: (user_id: string, chat: Chat) => {
        return !!chat.creator_id && chat.creator_id === this.authService.getUserId() && user_id !== this.authService.getUserId()
      }
    },
    {
      label: "Adminrechte entziehen",
      action: (user_id, chat_id) => this.networkService.request("DELETE", "/chat/" + chat_id + "/user/" + user_id + "/admin"),
      condition: (user_id: string, chat: Chat) => {
        return !!chat.creator_id && chat.creator_id === this.authService.getUserId() && user_id !== this.authService.getUserId()
      }
    },*/
    {
      label: "Entfernen",
      action: (user_id, chat_id) => this.removeUser(user_id),
      condition: (user_id: string, chat: Chat) => {
        return !!chat.creator_id && chat.creator_id === this.authService.getUserId() && user_id !== this.authService.getUserId()
      }
    },
    {
      label: "Verlassen",
      action: (user_id, chat_id) => this.chatService.leaveChat(chat_id),
      condition: (user_id: string, chat: Chat) => {
        return !!chat.creator_id && user_id === this.authService.getUserId()
      }
    }
  ];

  settingsFormGroup = new FormGroup({
    click_to_open: new FormControl(),
    messages_from_join: new FormControl(),
    timer: new FormControl(),
    who_can_write: new FormControl()
  });

  @ViewChild("chatname")
  private chatname!: ElementRef;

  protected member?: Member;
  protected users: User[] = [];

  constructor(protected componentService: ComponentService, private networkService: NetworkService, private popstateService: PopstateService, protected authService: AuthService, protected homeService: HomeService, protected chatService: ChatService) {
  }

  async open() {
    if (!this.chatService.chat_id) {
      return;
    }

    this.popstateService.addState("info", () => {
      this.close();

      return true;
    });

    this.member = await this.homeService.getMember(this.chatService.chat_id);

    this.settingsFormGroup.controls.timer.setValue(this.chatService.settings?.timer ?? 0);
    this.settingsFormGroup.controls.click_to_open.setValue(this.chatService.settings?.click_to_open ?? false);
    this.settingsFormGroup.controls.messages_from_join.setValue(this.chatService.settings?.messages_from_join ?? true);
    this.settingsFormGroup.controls.who_can_write.setValue(this.chatService.settings?.who_can_write ?? "all");

    if (this.member.chat!.is_group) {
      this.chatnameFormGroup.controls.name.setValue(this.member.chat!.name!);

      const messages = await db.connection.select({
        from: "messages",
        where: {
          chat_id: this.member.chat_id!,
          sender_id: {
            "!=": ""
          }
        },
        groupBy: ["sender_id"]
      });

      for (const userId of messages.map(m => m.sender_id)) {
        this.users.push(await this.homeService.getUser(userId));
      }
    } else {
      this.chatnameFormGroup.controls.name.setValue(this.member.user!.username);
    }

    this.open_state = true;
    this.is_creator = this.member.chat!.creator_id === this.authService.getUserId();

    this.componentService.getChat()?.cdr.detectChanges();
  }

  close() {
    if (!this.open_state) {
      return;
    }

    this.popstateService.removeState("info");

    this.users = [];
    this.member = undefined;

    this.open_state = false;

    this.componentService.getSearch()?.close();
    this.componentService.getChat()?.cdr.detectChanges();
  }

  submitSettings() {
    if (this.settingsFormGroup.valid) {
      const formData = structuredClone(this.settingsFormGroup.value) as ChatSettings;

      this.networkService.request("PUT", "/chat/" + this.chatService.chat_id + "/settings", JSON.stringify(formData)).then(response => {
        if (response.status === "success") {
          this.componentService.getHome()?.refresh().then(() => {
            this.componentService.getHome()?.update();
            this.componentService.getChat()?.cdr.detectChanges();
          });
        }
      });
    }
  }

  async renameChat() {
    if (this.chatnameFormGroup.valid && this.member) {
      const name = this.chatnameFormGroup.controls.name.value!;

      /*if (this.member.chat!.creator_id === this.authService.getUserId()) {
        const response = await this.networkService.request("PUT", "/chat/" + this.member.chat_id! + "/name", JSON.stringify({name: name}));
        if (response.status !== "success") {
          // TODO: Show error

          return;
        }
      }*/

      db.connection.update({
        in: "chats",
        set: {
          name: name,
        },
        where: {
          chat_id: this.member.chat_id!,
        }
      }).then(() => {
        this.member!.chat!.name = name;

        const index = this.homeService.members.findIndex(member => member.chat?.chat_id === this.member!.chat_id);
        this.homeService.members[index].chat!.name = name;
      }).catch(() => {
        Swal.fire({
          title: "Fehler",
          text: "Beim Ändern des Namens ist ein Fehler aufgetreten",
          icon: "error"
        });
      })

      this.exitMode();
    }
  }

  removeUser(user_id: string) {
    Swal.fire({
      title: "Sind Sie sicher?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Entfernen",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        this.networkService.request("DELETE", "/chat/" + this.chatService.chat_id + "/user/" + user_id);
      }
    });
  }

  editMode() {
    this.chatname.nativeElement.removeAttribute("readonly");
    this.chatname.nativeElement.focus();

    this.edit_mode = true;
  }

  exitMode() {
    this.chatname.nativeElement.setAttribute("readonly", "true");

    this.edit_mode = false;
  }

  protected readonly Tools = Tools;
  protected readonly Number = Number;
}
