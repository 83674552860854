<div class="archive-sidebar" [class.show]="this.open_state">
  <i class="bi bi-x close" (click)="this.close()"></i>

  <div id="chat-list" class="position-relative h-100">
    <div class="container-fluid p-0 m-0 h-100">
      <div class="list-group">
        @for (member of this.homeService.archive_members; track member; let index = $index) {
          <div
            (click)="this.componentService.getHome()?.handleClick(member.chat!.chat_id, index);"
            (contextmenu)="contextMenu.openContextMenu($event, {chat_id: member.chat!.chat_id, user_id: member.user?.user_id}, member.user!, member.chat!)"
            (touchstart)="contextMenu.handleTouchStart($event,{chat_id: member.chat!.chat_id, user_id: member.user?.user_id}, member.user!, member.chat!)"
            (touchmove)="contextMenu.handleTouchMove()"
            (touchend)="contextMenu.handleTouchEnd($event)"
            [ngClass]="{'active': member.chat!.chat_id === this.chatService.chat_id && this.screenService.isBigScreen()}"
            [attr.data-chat_id]="member.chat!.chat_id"
            class="list-group-item user-select-none d-flex justify-content-start align-items-center rounded-0 border-start-0 border-end-0 py-3">
            <div class="w-65-px ratio ratio-1x1 rounded-circle overflow-hidden">
              <app-profile-picture [member]="member"></app-profile-picture>
            </div>
            <div class="ms-4 me-4">
              <div class="username fs-7">
                {{ member.chat!.name ?? member.user!.username }}
              </div>
              <div class="chat-icons">
                <ng-container *ngIf="!member.chat!.is_group && member.user!.trusted">
                  <i class="bi bi-patch-check" title="Sie vertrauen dieser Person"></i>
                </ng-container>
                <ng-container *ngIf="member.chat!.is_qr_chat">
                  <i class="bi bi-qr-code-scan" title="Dieser Chat wurde mit einem QR-Code erstellt"></i>
                </ng-container>
                <ng-container *ngIf="member.chat!.settings.timer">
                  <i class="bi bi-clock" title="Dieser Chat hat eine Nachrichten Ablaufzeit"></i>
                </ng-container>
                <ng-container *ngIf="member.chat!.is_suspended">
                  <i class="bi bi-x-circle" title="Dieser Chat wurde gesperrt"></i>
                </ng-container>
              </div>
            </div>
            <div *ngIf="member.unseen_messages"
                 class="badge bg-primary rounded-pill ms-auto">{{ member.unseen_messages }}
            </div>
          </div>
        } @empty {
          <div *ngIf="!this.loading"
               class="list-group-item user-select-none d-flex justify-content-center align-items-center rounded-0 border-start-0 border-end-0 py-3">
            <div class="fs-5">Keine Chats</div>
          </div>
        }
      </div>
    </div>
  </div>
</div>

<app-contextmenu #contextMenu [menuItems]="contextMenuItems"></app-contextmenu>
