import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import WaveSurfer from "wavesurfer.js";
import {DeviceDetectorService} from "ngx-device-detector";
import {Subscription} from "rxjs";
import Swal, {SweetAlertOptions} from "sweetalert2";

import {db} from "../../db/db";
import {Response, Tools} from "../../assets/js/tools";

import {ChatService} from "../../assets/js/service/chat";
import {AuthService} from "../../assets/js/service/auth";
import {CryptUtils} from "../../assets/js/crypt_utils";
import {HomeService} from "../../assets/js/service/home";
import {FormatTimePipe} from "../../assets/js/pipe/format_time";
import {NetworkService} from "../../assets/js/service/network";
import {FileViewerService} from "../../assets/js/service/fileviewer";
import {DBHandlerService} from "../../assets/js/service/db_handler";
import {VisibilityChangeService} from "../../assets/js/service/visibility";
import {FileUtils} from "../../assets/js/file_utils";

import {Member} from "../../assets/js/model/Member";
import {Message, MessageStatus} from "../../assets/js/model/Message";
import {BlockedUser} from "../../assets/js/model/BlockedUser";
import {FileInfo} from "../../assets/js/model/FileInfo";
import {InfoComponent} from "../info/info.component";
import {PopstateService} from "../../assets/js/service/popstate";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];

  private intervals: any = [];
  private timeouts: any = [];

  private is_top: boolean = false;
  private max_previous: boolean = false;
  private max_previous_network: boolean = false;

  private readonly scroll_threshold: number = 300;
  private readonly message_count: number = 20;

  protected selected_files: FileInfo[] = [];
  private file_size: number = 0;

  private processing: boolean = false;
  protected unloading: boolean = false;
  protected loading: boolean = false;
  protected member?: Member;

  @ViewChild("messagesContainer")
  private messagesContainer!: ElementRef;

  @ViewChild("info")
  private info!: InfoComponent;

  @ViewChild("messageInput")
  private messageInput!: ElementRef;

  @ViewChild("scrollToBottom")
  private scrollToBottom!: ElementRef;

  @ViewChild("waveform")
  waveform!: ElementRef;

  protected recording: boolean = false;

  private mediaRecorder: MediaRecorder | null = null;
  private chunks: BlobPart[] = [];
  private waveSurfer: WaveSurfer | null = null;
  private audioType: string | null = null;

  constructor(private popstateService: PopstateService, private cdr: ChangeDetectorRef, private visibilityService: VisibilityChangeService, private dbHandlerService: DBHandlerService, private route: ActivatedRoute, private fileViewerService: FileViewerService, private networkService: NetworkService, protected authService: AuthService, private homeService: HomeService, protected chatService: ChatService, private deviceService: DeviceDetectorService) {
  }

  trackByMessageId(index: number, message: Message): string {
    return message.message_id!
  }

  isSameDay(currentIndex: number): boolean {
    if (currentIndex === 0) return false;

    const currentDate = new Date(this.chatService.sorted_messages[currentIndex].timestamp * 1000);
    const previousDate = new Date(this.chatService.sorted_messages[currentIndex - 1].timestamp * 1000);

    const currentYMD = currentDate.getFullYear().toString() + currentDate.getMonth().toString() + currentDate.getDay().toString();
    const previousYMD = previousDate.getFullYear().toString() + previousDate.getMonth().toString() + previousDate.getDay().toString();

    return currentYMD === previousYMD;
  }

  protected search(): void {
    Tools.showMessage("Diese Funktion ist noch nicht verfügbar", "info");
  }

  protected closeReference(): void {
    this.chatService.reference_message = undefined;

    this.cdr.detectChanges();
  }

  protected onScroll(): void {
    const scrollBottom = this.messagesContainer.nativeElement.scrollHeight - this.messagesContainer.nativeElement.scrollTop - this.messagesContainer.nativeElement.clientHeight;
    const scrollTop = this.messagesContainer.nativeElement.scrollTop;

    this.is_top = scrollTop === 0;

    if (scrollBottom < this.scroll_threshold) {
      this.scrollToBottom.nativeElement.classList.remove("show");
    } else {
      this.scrollToBottom.nativeElement.classList.add("show");
    }

    if (this.processing || this.loading || this.unloading || this.max_previous) {
      return;
    }

    if (scrollTop <= 800) {
      this.processing = true;
      this.is_top = false;

      this.chatService.current_scroll = () => {};

      const first_message = this.chatService.first_message;
      this.loadPreviousMessages().then(previousMessage => {
        if (!previousMessage) {
          this.processing = false;
          this.max_previous = true;
          this.max_previous_network = true;

          this.dbHandlerService.addAction(async () => {
            await db.connection.update({
              in: "chats",
              set: {
                max_previous: true
              },
              where: {
                chat_id: this.chatService.chatId
              }
            }).catch(e => {
            });
          });

          this.cdr.detectChanges();

          return;
        }

        if (!this.is_top) {
          this.processing = false;

          this.cdr.detectChanges();

          return;
        }

        this.scrollToMessage(first_message).then(() => {
          this.processing = false;

          this.cdr.detectChanges();
        });
      });
    }
  }

  ngAfterViewInit() {
    this.scrollToMessage(this.chatService.last_message, true);
  }

  private async showReferenceMessage(messageId: string) {
    this.closeReference();

    let message = this.chatService.sorted_messages.find(message => message.message_id === messageId);
    if (!message) {
      const results = await db.connection.select({
        from: "messages",
        where: {
          message_id: messageId
        }
      }) as Message[];

      if (results.length) {
        message = results[0] as Message;
      } else {
        Tools.showMessage("Nachricht nicht lokal gefunden", "error");
        // TODO: Load from server
        return;
      }
    }

    this.chatService.reference_message = message;

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    if (!this.authService.isDecrypted) {
      return;
    }

    this.subscriptions.push(
      this.chatService.referenceEvent.subscribe(message_id => {
        if (message_id.length) {
          const sup = document.createElement("sup");
          sup.classList.add("reference");
          sup.dataset["message_id"] = message_id;
          sup.contentEditable = "false";
          sup.innerText = "[" + (this.messageInput.nativeElement.querySelectorAll("sup").length + 1) + "]";

          const wrapper = document.createElement("span");
          wrapper.classList.add("wrapper");
          wrapper.contentEditable = "true"; // Make the wrapper contenteditable
          wrapper.appendChild(sup);

          // Create separate span elements for zero-width spaces
          const zwnbspSpan = document.createElement("span");
          zwnbspSpan.innerText = "\uFEFF";

          const zwspSpan = document.createElement("span");
          zwspSpan.innerText = "\u200B";

          const range = window.getSelection()!.getRangeAt(0);
          const selection = window.getSelection()!;

          if (
            range.commonAncestorContainer === this.messageInput.nativeElement ||
            range.commonAncestorContainer.parentNode === this.messageInput.nativeElement
          ) {
            range.insertNode(zwspSpan);  // Insert zero-width space span
            range.insertNode(zwnbspSpan); // Insert zero-width no-break space span
            range.insertNode(wrapper);   // Insert the wrapper span
          } else {
            this.messageInput.nativeElement.insertBefore(zwspSpan, this.messageInput.nativeElement.lastChild);
            this.messageInput.nativeElement.insertBefore(zwnbspSpan, zwspSpan);
            this.messageInput.nativeElement.insertBefore(wrapper, zwnbspSpan);
          }

          range.setStartAfter(zwspSpan);
          range.setEndAfter(zwspSpan);
          selection.removeAllRanges();
          selection.addRange(range);

          this.adjustHeightBasedOnRows();
          this.cdr.detectChanges();

          this.chatService.referenceEvent.next("");
        }
      }),
      this.chatService.referenceClickEvent.subscribe(messageId => {
        if (messageId) {
          this.showReferenceMessage(messageId);

          this.chatService.referenceClickEvent.next("");
        }
      }),
      this.chatService.updateEvent.subscribe(update => {
        if (update) {
          this.chatService.updateEvent.next(false);

          if (this.processing) {
            return;
          }

          this.cdr.detectChanges();

          const scrollBottom = this.messagesContainer.nativeElement.scrollHeight - this.messagesContainer.nativeElement.scrollTop - this.messagesContainer.nativeElement.clientHeight;
          if (scrollBottom < this.scroll_threshold) {
            this.scrollToMessage(this.chatService.last_message, true);
          }

          if (Tools.isVisible() && "serviceWorker" in navigator && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
              command: "hideNotification",
              chat_id: this.chatService.chatId
            });
          }
        }
      }),
      this.chatService.unloadEvent.subscribe(unload => {
        if (unload) {
          this.unloadChat();

          this.chatService.unloadEvent.next(false);
        }
      }),
      this.route.paramMap.subscribe(paramMap => {
        const chatId = paramMap.get("id")!;

        if (chatId) {
          this.loadChat(chatId);
        }
      }),
    );

    this.visibilityService.addHandler("chat", () => {
      if (Tools.isVisible() && "serviceWorker" in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          command: "hideNotification",
          chat_id: this.chatService.chatId
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.intervals.forEach((interval: any) => clearInterval(interval));
    this.timeouts.forEach((timeout: any) => clearTimeout(timeout));

    if (!this.loading && !this.unloading) {
      this.unloadChat();
    }
  }

  protected onEnter() {
    if (this.deviceService.isDesktop()) {
      this.enterMessage();
    }
  }

  protected checkScroll() {
    const scrollBottom = this.messagesContainer.nativeElement.scrollHeight - this.messagesContainer.nativeElement.scrollTop - this.messagesContainer.nativeElement.clientHeight;
    if (scrollBottom < this.scroll_threshold) {
      this.chatService.current_scroll(200);
    }
  }

  protected selectFile() {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.onchange = () => this.selectedFile(input);

    input.click();
  }

  protected removeFile(index: number) {
    this.selected_files.splice(index, 1);
  }

  protected dropFiles(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer) {
      this.selectedFile(event.dataTransfer);
    }
  }

  protected keydownEvent(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    if (target.lastChild && target.lastChild.nodeName !== "BR") {
      target.appendChild(document.createElement("br"));
    }

    if (event.key === "Enter") {
      event.preventDefault();

      const range = window.getSelection()!.getRangeAt(0);
      range.deleteContents();

      const br = document.createElement("br");
      range.insertNode(br);

      const div = document.createElement("div");
      range.insertNode(div);

      range.setStartAfter(br);
      range.setEndAfter(br);
      window.getSelection()!.removeAllRanges();
      window.getSelection()!.addRange(range);

      div.scrollIntoView();

      setTimeout(() => {
        div.remove();
      });
    }

    if (event.key === "Backspace" || event.which === 151 || event.which === 229) {
      const range = window.getSelection()!.getRangeAt(0);
      let container = range.commonAncestorContainer as HTMLElement;
      const textContent = container.textContent;

      if (container.nodeType === Node.TEXT_NODE) {
        container = container.parentElement!;
      }

      if (textContent === "\uFEFF") {
        let wrapper = null;
        const previousSibling = container.previousSibling as HTMLElement;
        const previousPreviousSibling = container.previousSibling?.previousSibling as HTMLElement;

        if (previousSibling.classList && previousSibling.classList.contains("wrapper")) {
          wrapper = previousSibling;
        } else if (previousPreviousSibling.classList && previousPreviousSibling.classList.contains("wrapper")) {
          wrapper = previousPreviousSibling;
        }

        if (wrapper) {
          console.log("Removing wrapper");
          wrapper.remove();
        }
      }
    }

    setTimeout(() => {
      this.adjustHeightBasedOnRows();

      this.cdr.detectChanges();
    });
  }

  protected async pasteEvent(event: ClipboardEvent) {
    if (!event.clipboardData) {
      return
    }

    if (event.clipboardData.files.length) {
      event.preventDefault();
      await this.selectedFile(event.clipboardData);
    }

    const clipboardText = event.clipboardData.getData("text");
    if (clipboardText.length) {
      event.preventDefault();

      const selection = window.getSelection();
      if (!selection?.rangeCount) {
        return;
      }

      const range = selection.getRangeAt(0);
      range.deleteContents();

      const textNode = document.createTextNode(clipboardText);
      range.insertNode(textNode);

      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);

      const tempSpan = document.createElement("span");
      range.insertNode(tempSpan);
      tempSpan.scrollIntoView({ behavior: "instant", block: "end" });

      tempSpan.parentNode!.removeChild(tempSpan);
    }

    this.adjustHeightBasedOnRows();

    this.cdr.detectChanges();
  }

  private adjustHeightBasedOnRows(): void {
    const contentDiv = this.messageInput.nativeElement;

    const lineHeight = 25; // Each row is 25px
    const minRows = 2; // Minimum number of rows
    const maxRows = 4; // Maximum number of rows

    contentDiv.style.overflowY = "hidden";
    contentDiv.style.height = `${lineHeight * minRows}px`;

    const scrollHeight = contentDiv.scrollHeight;

    let numberOfRows = Math.ceil(scrollHeight / lineHeight);

    if (numberOfRows > maxRows) {
      numberOfRows = maxRows;
      contentDiv.style.overflowY = "auto";
    } else {
      contentDiv.style.overflowY = "hidden";
    }

    const newHeight = lineHeight * numberOfRows;
    contentDiv.style.height = `${newHeight}px`;

    this.chatService.current_scroll();
  }

  private async selectedFile(data: HTMLInputElement | DataTransfer) {
    for (let i = 0; i < data.files!.length; i++) {
      const file: File = data.files![i];

      if (this.file_size + file.size > 100000000) {
        Tools.showMessage("Sie können maximal 100 MB an Dateien pro Nachricht senden", "error");
        break;
      }

      this.file_size += file.size;

      const fileInfo: FileInfo = {
        name: file.name,
        type: file.type,
        size: file.size
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        fileInfo.data = event.target!.result as string;

        this.selected_files.push(fileInfo);
        this.scrollToMessage(this.chatService.last_message, true);
      }
      reader.readAsDataURL(file);
    }
  }

  protected async startRecording() {
    this.waveSurfer = WaveSurfer.create({
      container: this.waveform.nativeElement,
      waveColor: "#0d6efd",
      progressColor: "#ffffff",
      height: 25
    });

    try {
      const stream = await Tools.getUserMedia({audio: true});
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = this.handleDataAvailable.bind(this);
      this.mediaRecorder.onstop = this.handleStop.bind(this);

      this.chunks = [];

      this.mediaRecorder.start(200);
      this.recording = true;

      this.cdr.detectChanges();
    } catch (error) {
      Tools.showMessage("Kein Zugriff auf das Mikrofon möglich", "error");
    }
  }

  private handleDataAvailable(event: BlobEvent): void {
    this.chunks.push(event.data);
    this.audioType = event.data.type.split(";")[0].split(".")[0];

    this.waveSurfer?.loadBlob(new Blob(this.chunks, {type: event.data.type}));

    this.cdr.detectChanges();
  }

  private handleStop(): void {
    const audioBlob = new Blob(this.chunks, {type: this.audioType!});

    const fileInfo = {
      name: "recording-" + Tools.current_time + "." + this.audioType!.split("/")[1],
      type: this.audioType!,
      size: audioBlob.size
    } as FileInfo;

    const reader = new FileReader();
    reader.onloadend = (event) => {
      fileInfo.data = event.target!.result as string;

      this.selected_files.push(fileInfo);
      this.scrollToMessage(this.chatService.last_message, true);
    };

    reader.readAsDataURL(audioBlob);

    this.waveSurfer?.empty();
    this.waveSurfer?.destroy();
    this.waveSurfer = null;

    if (this.mediaRecorder) {
      this.mediaRecorder.ondataavailable = null;
      this.mediaRecorder.onstop = null;
      this.mediaRecorder = null;
    }
    this.chunks = [];
    this.audioType = null;

    this.cdr.detectChanges();
  }

  protected stopRecording() {
    if (!this.mediaRecorder) {
      return;
    }

    this.mediaRecorder.stop();
    this.mediaRecorder.stream.getTracks().forEach(track => track.stop())

    this.recording = false;

    this.cdr.detectChanges();
  }

  protected async enterMessage() {
    const messageInput = this.messageInput.nativeElement;
    const value = messageInput.innerHTML.trim();

    if (value.length || this.selected_files.length) {
      messageInput.innerHTML = "";
      messageInput.focus();

      this.adjustHeightBasedOnRows();

      let encryptedFiles = "";
      if (this.selected_files.length > 0) {
        encryptedFiles = await CryptUtils.encryptData(JSON.stringify(this.selected_files), await this.chatService.getSecretKey());

        this.selected_files = [];
        this.file_size = 0;
      }

      const data = await CryptUtils.encryptData(value, await this.chatService.getSecretKey());
      const message_id = Tools.generateUUID();
      const message = {
        message_id: message_id,
        sender_id: this.authService.getUserId()!,
        chat_id: this.chatService.chatId,
        data: data,
        files: encryptedFiles,
        signature: await CryptUtils.signData(data, this.authService.getSignPrivateKey()!),
        timestamp: Tools.current_time,
        open_timestamp: Tools.current_time,
        timer: Number(this.member!.chat!.settings.timer),
        status: MessageStatus.PENDING,
        seen: true
      } as Message;

      this.chatService.sendMessage(message).then(() => {
        this.cdr.detectChanges();
        this.scrollToMessage(message, true);
      });

      this.cdr.detectChanges();
      this.scrollToMessage(message, true);

      this.chatService.unsent_messages[this.chatService.chatId] = {};
    }
  }

  async loadChat(chatId: string) {
    if (this.chatService.chatId && this.chatService.chatId !== chatId) {
      this.unloadChat();
    }

    if (!chatId || this.loading || this.unloading) {
      return;
    }

    this.popstateService.addState("chat", () => {
      this.unloadChat();

      this.cdr.detectChanges();

      return true;
    });

    this.loading = true;
    this.chatService.chatId = chatId;
    this.member = await this.homeService.getMember(chatId);

    this.chatService.is_group = this.member.chat!.is_group;

    this.cdr.detectChanges();

    if (!this.member.chat!.is_group) {
      const blocked_entry = ((await db.connection.select({
        from: "blocked_users",
        where: {
          block_id: this.member.user!.user_id!
        }
      }))[0] ?? {}) as BlockedUser;

      if (blocked_entry.block_id) {
        this.chatService.blocked = true;
      }
    }

    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        command: "hideNotification",
        chat_id: chatId
      });
    }

    this.max_previous_network = this.member!.chat!.max_previous;

    if (!this.loading) {
      return;
    }

    const messageInput = this.messageInput.nativeElement;

    if (typeof this.chatService.unsent_messages[chatId] !== "undefined") {
      messageInput.innerHTML = this.chatService.unsent_messages[chatId].message ?? "";
      this.selected_files = this.chatService.unsent_messages[chatId].files ?? [];
    }

    if (this.chatService.share_data) {
      messageInput.innerText += this.chatService.share_data.title;
      messageInput.innerText += this.chatService.share_data.text;
      messageInput.innerText += this.chatService.share_data.url;

      this.chatService.share_data = null;
    }

    if (!this.loading) {
      return;
    }

    this.adjustHeightBasedOnRows();

    this.loadMessages().then(async () => {
      await this.chatService.getSecretKey(chatId);

      this.chatService.chatId = chatId;
      this.loading = false;

      await this.scrollToMessage(this.chatService.last_message, true);

      this.cdr.detectChanges();
    });
  }

  unloadChat() {
    this.loading = false;
    this.max_previous = false;
    this.max_previous_network = false;

    this.unloading = true;

    this.member = undefined;

    if (this.messageInput) {
      this.chatService.unsent_messages[this.chatService.chatId] = {
        message: this.messageInput.nativeElement.innerHTML,
        files: this.selected_files
      };

      this.messageInput.nativeElement.innerHTML = "";
    }

    this.chatService.messages[this.chatService.chatId] = this.chatService.sorted_messages.slice(-this.message_count);
    this.chatService.reference_message = undefined;
    this.chatService.is_group = false;

    this.selected_files = [];

    this.chatService.chatId = "";
    this.homeService.badges.next(true);

    this.info.close();

    this.popstateService.removeState("chat");

    this.unloading = false;

    this.cdr.detectChanges();
  }

  private async loadMessages() {
    if (this.chatService.message_count < this.message_count) {
      let where = {
        chat_id: this.chatService.chatId,
      } as { chat_id: string, timestamp?: any };

      if (this.chatService.first_message) {
        where.timestamp = {
          "<": this.chatService.first_message.timestamp
        };
      }

      const messages = await db.connection.select({
        from: "messages",
        where: where,
        order: {
          by: "timestamp",
          type: "desc"
        },
        limit: this.message_count - this.chatService.message_count
      }) as Message[];

      this.chatService.addMessages(messages);
      this.scrollToMessage(this.chatService.last_message, true);
    }
  }

  private async loadPreviousMessages(): Promise<boolean> {
    let messages = await db.connection.select({
      from: "messages",
      where: {
        chat_id: this.chatService.chatId,
        timestamp: {
          "<": this.chatService.first_message.timestamp
        }
      },
      order: {
        by: "timestamp",
        type: "desc"
      },
      limit: this.message_count
    }) as Message[];

    if (this.unloading || !this.chatService.chatId) {
      return false;
    }

    if (!messages.length) {
      if (this.max_previous_network) {
        return false;
      }

      const response = await this.networkService.request("GET", "/chat/" + this.chatService.chatId + "/messages/" + this.chatService.first_message.message_id + "/previous");
      return await this.processResponse(response);
    } else {
      this.chatService.addMessages(messages);
      return true;
    }
  }

  private async processResponse(response: Response): Promise<boolean> {
    if (response.data) {
      const messages = response.data as Message[];

      await this.chatService.handleMessages(messages);
      this.chatService.addMessages(messages);

      return true;
    }

    return false;
  }

  protected leaveChat() {
    this.chatService.leaveChat(this.chatService.chatId);
  }

  protected deleteChat() {
    this.chatService.deleteChat(this.chatService.chatId);
  }

  protected addUser() {
    Tools.showMessage("Diese Funktion ist noch nicht verfügbar", "info");
  }

  protected scrollToMessage(message: Message, end: boolean = false, timeout: number = 0): Promise<boolean> {
    this.cdr.detectChanges();

    return new Promise<boolean>(resolve => {
      if (!message || !this.messagesContainer) {
        resolve(false);

        return;
      }

      this.chatService.current_scroll = (timeout_override = timeout) => {
        const scrollableDivEl = this.messagesContainer.nativeElement;

        let tries = 0;
        const message_interval = setInterval(() => {
          if (!message) {
            clearInterval(message_interval);
            resolve(false);

            return;
          }

          const messageElementEl = scrollableDivEl.querySelector(`[data-id='${message.message_id}']`);

          if (!messageElementEl) {
            if (tries < 10) {
              tries++;
            } else {
              clearInterval(message_interval);
              resolve(false);
            }

            return;
          }

          clearInterval(message_interval);
          setTimeout(() => {
            let scroll;

            if (messageElementEl) {
              scroll = messageElementEl.offsetTop;

              if (end) {
                scroll += messageElementEl.offsetHeight;
              }
            } else {
              scroll = scrollableDivEl.scrollHeight;
            }

            scrollableDivEl.scrollTop = scroll - scrollableDivEl.offsetTop;

            resolve(true);
            return;
          }, timeout_override);
        }, 50);
      }

      this.chatService.current_scroll();
    });
  }

  setMessageTime(): void {
    const options = {
      title: "Nachrichten Ablaufzeit anpassen",
      text: "Bitte beachten Sie, dass die Nachrichten Ablaufzeit auch für alle anderen Teilnehmer dieser Unterhaltung angepasst wird",
      input: "select",
      inputOptions: {
        0: "Keine Ablaufzeit",
        300: "5 Minuten",
        600: "10 Minuten",
        1800: "30 Minuten",
        3600: "1 Stunde",
        18000: "5 Stunden",
        36000: "10 Stunden",
        43200: "12 Stunden",
        86400: "24 Stunden",
      },
      inputValue: Number(this.member!.chat!.settings.timer),
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Ändern",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      didOpen: () => {
        const firstOption = document.querySelector(".swal2-select option:first-of-type")! as HTMLOptionElement;
        firstOption.setAttribute("disabled", "true");
        firstOption.value = "";
      },
      preConfirm: async (timer: number) => {
        const response = await this.networkService.request("POST", "/chat/" + this.chatService.chatId + "/settings/time", JSON.stringify({"timer": timer}));
        if (response.status === "success") {
          Swal.fire({
            title: "Erfolgreich",
            text: response.message,
            icon: "success"
          });

          this.member!.chat!.settings.timer = timer;

          this.dbHandlerService.addAction(async () => {
            await db.connection.update({
              in: "chats",
              set: {
                settings: JSON.stringify(this.member!.chat!.settings)
              },
              where: {
                chat_id: this.chatService.chatId
              }
            }).catch(e => {
            });
          });

          const message = {
            message_id: Tools.generateUUID(),
            sender_id: "system",
            chat_id: this.chatService.chatId,
            data: "Sie haben den Timer auf " + new FormatTimePipe().transform(timer) + " geändert",
            files: "",
            timestamp: Math.round(Date.now() / 1000),
            timer: Number(timer)
          } as Message;

          this.chatService.handleMessage(message, this.member!);
          this.chatService.addMessage(message);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    } as SweetAlertOptions;

    if (this.member!.chat!.settings.timer === 0) {
      options.footer = "WARNUNG: Diese Unterhaltung hat zurzeit eine unendliche Ablaufzeit. Eine unendliche Ablaufzeit ist danach nicht mehr möglich!";
    }

    Swal.fire(options);
  }

  protected readonly FileUtils = FileUtils;
}
