import {Component, ElementRef, ViewChild} from '@angular/core';
import {Tools} from "../../assets/js/tools";
import {AuthService} from "../../assets/js/service/auth";
import {ScreenSizeService} from "../../assets/js/service/screen";

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrl: './root.component.scss'
})
export class RootComponent {
  protected readonly Tools = Tools;

  @ViewChild("toggler")
  private toggler!: ElementRef;

  constructor(protected authService: AuthService, private screenService: ScreenSizeService) {
  }

  onNavClick(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains("dropdown-toggle") &&
      !this.screenService.isBigScreen() &&
      (target.classList.contains("dropdown-item") ||
        target.classList.contains("nav-item") ||
        target.classList.contains("nav-link"))) {
      this.toggler.nativeElement.click();
    }
  }
}
