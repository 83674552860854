import {Injectable} from "@angular/core";
import {FileInfo} from "../model/FileInfo";
import {BehaviorSubject} from "rxjs";
import {PopstateService} from "./popstate";

@Injectable({
  providedIn: 'root',
})
export class FileViewerService {
  protected file?: FileInfo;

  accepted = new BehaviorSubject<boolean>(false);
  controls: boolean = false;

  constructor(private popstateService: PopstateService) {
  }

  setFile(file: FileInfo) {
    this.file = file;
  }

  getFile(): FileInfo|undefined {
    return this.file;
  }

  hasFile(): boolean {
    return typeof this.file !== "undefined";
  }

  removeFile() {
    this.popstateService.removeState("fileviewer");

    this.file = undefined;
  }
}
