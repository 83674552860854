import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {HomeService} from "../../assets/js/service/home";
import {ChatService} from "../../assets/js/service/chat";
import {Member} from "../../assets/js/model/Member";
import {AuthService} from "../../assets/js/service/auth";
import {PopstateService} from "../../assets/js/service/popstate";
import {User} from "../../assets/js/model/User";
import {db} from "../../db/db";
import {CryptUtils} from "../../assets/js/crypt_utils";
import {FileInfo} from "../../assets/js/model/FileInfo";
import {Tools} from "../../assets/js/tools";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NetworkService} from "../../assets/js/service/network";
import Swal from "sweetalert2";

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss'
})
export class InfoComponent {
  open_state: boolean = false;

  edit_mode: boolean = false;

  chatnameFormGroup = new FormGroup({
    name: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(32)
    ])
  });

  @ViewChild("chatname")
  private chatname!: ElementRef;

  protected member?: Member;
  protected users: User[] = [];

  constructor(private networkService: NetworkService, private popstateService: PopstateService, private cdr: ChangeDetectorRef, protected authService: AuthService, protected homeService: HomeService, protected chatService: ChatService) {
  }

  async open() {
    if (!this.chatService.chatId) {
      return;
    }

    this.popstateService.addState("info", () => {
      this.close();

      this.cdr.detectChanges();

      return true;
    });

    this.member = await this.homeService.getMember(this.chatService.chatId);

    this.cdr.detectChanges();

    if (this.member.chat!.is_group) {
      this.chatnameFormGroup.controls.name.setValue(this.member.chat!.name!);

      const messages = await db.connection.select({
        from: "messages",
        where: {
          chat_id: this.member.chat!.chat_id
        },
        groupBy: ["sender_id"]
      });

      for (const userId of messages.map(m => m.sender_id)) {
        const user = await this.homeService.getUser(userId);
        if (user.picture && typeof user.picture === "string") {
          const image_data = await CryptUtils.decryptData(user.picture as string, this.authService.getSecretKey()!);

          if (!image_data.length) {
            continue;
          }

          user.picture = JSON.parse(image_data) as FileInfo;
        }

        this.users.push(user);
      }
    } else {
      this.chatnameFormGroup.controls.name.setValue(this.member.user!.username);
    }

    this.open_state = true;

    this.chatService.updateEvent.next(true);

    this.cdr.detectChanges();
  }

  close() {
    if (!this.open_state) {
      return;
    }

    this.popstateService.removeState("info");

    this.users = [];
    this.member = undefined;

    this.open_state = false;

    this.chatService.updateEvent.next(true);

    this.cdr.detectChanges();
  }

  async renameChat() {
    if (this.chatnameFormGroup.valid && this.member) {
      const name = this.chatnameFormGroup.controls.name.value!;

      /*if (this.member.chat!.creator_id === this.authService.getUserId()) {
        const response = await this.networkService.request("PUT", "/chat/" + this.member.chat_id! + "/name", JSON.stringify({name: name}));
        if (response.status !== "success") {
          // TODO: Show error

          return;
        }
      }*/

      db.connection.update({
        in: "chats",
        set: {
          name: name,
        },
        where: {
          chat_id: this.member.chat_id!,
        }
      }).then(() => {
        this.member!.chat!.name = name;

        const index = this.homeService.members.findIndex(member => member.chat?.chat_id === this.member!.chat_id);
        this.homeService.members[index].chat!.name = name;
      }).catch(() => {
      Swal.fire({
        title: "Fehler",
        text: "Beim Ändern des Namens ist ein Fehler aufgetreten",
        icon: "error"
      });
    })

      this.exitMode();
    }
  }

  editMode() {
    this.chatname.nativeElement.removeAttribute("readonly");
    this.chatname.nativeElement.focus();

    this.edit_mode = true;
  }

  exitMode() {
    this.chatname.nativeElement.setAttribute("readonly", "true");

    this.edit_mode = false;
  }

  protected readonly Tools = Tools;
}
